@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.ambient-detail--container {
  @extend .ce-full-width-no-margin;
  margin: 30px 0;
}

.ambient-detail--container-inner {
  @extend .ce-container-inner-width;
}

.ambient-detail--grid {

  @include from($desktop) {
    display: grid;
    grid-template-columns: $header-grid-main;
  }
  @include from($ultra-wide-screen) {
    grid-template-columns: $header-grid-main-wide;
  }
}

.ambient-detail--grid-right {
  display: grid;

  @include from($desktop) {
    padding-left: $header-grid-side-padding;
  }
}

.ambient-detail--grid-left {
  margin: 0 -#{$container-margin} 30px -#{$container-margin};

  @include from($tablet) {
    margin-left: 0;
    margin-right: 0;
  }

  @include from($desktop) {
    margin-bottom: 0;
  }
}


/************** STYLE *************/
.ambient-detail {
  //***** Overrides hover from product list *****//
  // check also:
  // _ce-product-homepage.scss
  // _p_collection-detail.scss
  .product-list--item,
  .product-list--item-content {
    margin-bottom: 0 !important;
  }
}

.ambient-detail--headline {
  position: relative;

  @include until($tablet) {
    padding-left: 30px;
    font-size: $font-size-h3;
  }

  @include from($desktop) {
    display: block;
  }
}

.ambient-detail--content {
  h4 {
    margin-top: 0;
    color: $color-text-accent;
  }

  & > p:first-child {
    margin-top: 0;
  }
}

.ambient-detail--image {
  border-radius: 4px;
}

.ambient-detail--article-divider {
  height: 5px;
  margin: 40px 0 50px 0;
  background-color: $color-background-grey-light-accent;
}

.ambient-detail--article-wrap {
  @extend .article-list-grid;
}

.ambient-detail--back-link {
  display: block;
  position: absolute;
  left: -4px;
  top: 7px;
  width: 22px;
  height: 18px;
  background: sassvg("arrow-back") no-repeat center;

  @include from($tablet) {
    display: none;
  }
}

.collection-detail--ambient {
  &-list {
    margin: 40px 0;

    &-headline {
      margin-top: 0;
      margin-bottom: 20px;
      @include from($tablet) {
        margin-bottom: 40px;
      }
    }

    @include from($desktop) {
      margin: 80px 0;
    }
  }

  &-item-img {
    border-radius: $image-border-radius;
  }

  &-item-link {
    display: inline-block;
    text-decoration: none;
    color: $color-text-base;

    &-text {
      @include linkWithArrowExtend($color-text-accent, $color-background-accent, 9px);
    }

  }

  &-item-wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    @include from(460px) {
      grid-template-columns: 1fr 1fr;
    }

    @include from($tablet) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 60px 30px;
    }
  }

  &-item-headline {
    margin: 10px 0;
  }

  &-item-text {
    color: $color-text-base;
    margin-top: 0;
    margin-bottom: 8px;
  }
}

// Same style as Collection Detail
.splide__arrow--collection-ambient-arrow {
  opacity: 0.6;
  padding: 20px;
  border: none;
  will-change: opacity;
  transition: opacity 300ms;

  &:hover {
    transition: opacity 300ms;
    cursor: pointer;
    opacity: 1;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: auto;
  }
}

.splide__arrow--collection-ambient-prev {
  background: sassvg("arrow-slider-prev", $color-background-accent) no-repeat center;
  background-color: $color-white;
}

.splide__arrow--collection-ambient-next {
  background: sassvg("arrow-slider-next", $color-background-accent) no-repeat center;
  background-color: $color-white;
}



.ambient-detail--video {
  display: grid;
  align-items: flex-end;
}

.ambient-detail--video-inner {
  position: relative;
  display: inline-block;
  width: max-content;

  a {
    display: block;
  }

  img {
    width: auto;
    border-radius: $image-border-radius;
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: sassvg('youtube', rgba(255, 255, 255, 0.8)) center no-repeat;
    background-size: 60px;
    pointer-events: none;
    transition: background-size .1s;
  }

  &:hover {
    &:before {
      background-size: 70px;
    }
  }
}



