@import "../constants/constants";
@import "../base/mixins";


/******** Content grid ********/
.text-two-cols--container {
  &-inner {
    @extend .ce-container-inner-width;
    display: grid;
    grid-row-gap: 30px;
    @include from($desktop) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
    }
  }
}

.two-columns-gray-flexible {
  @include from($desktop-large) {
    width: 2000px;
  }
}

/******* Content style *******/
.text-two-cols {
  padding-bottom: 40px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    margin-bottom: 10px;

    &:before {
      border-radius: 100%;
      background-color: $color-background-accent-secondary;
    }
  }
}

