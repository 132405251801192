@import "../constants/constants";
@import "../base/mixins";

$leftTweak: 0px;
$topTweak: 0px;

@mixin top-position($top) {
  top: $top+$topTweak;
}

@mixin left-position($left) {
  left: $left+$leftTweak;
}

.payment-ticket {
  position: relative;
  width: 700px;
  margin-bottom: 20px;
  font-size: 12px;
  color: $color-text-accent;
  font-weight: 500;

  img {
    filter: invert(70%) sepia(5%) saturate(197%) hue-rotate(191deg) brightness(85%) contrast(81%);
  }

  &-rwd {
    display: none;
  }

  span {
    position: absolute;
  }

  &-fullname {
    @include top-position(52px);
    @include left-position(47px);
    max-width: 270px;
  }

  &-purpose {
    @include top-position(119px);
    @include left-position(47px);
    max-width: 270px;
  }

  &-reciever {
    @include top-position(190px);
    @include left-position(47px);
    max-width: 270px;
  }

  &-code {
    @include top-position(63px);
    @include left-position(391px);
  }

  &-currency {
    @include top-position(63px);
    @include left-position(453px);
  }

  &-price {
    @include top-position(63px);
    @include left-position(516px);
  }

  &-account {
    @include top-position(108px);
    @include left-position(448px);
  }

  &-model {
    @include top-position(147px);
    @include left-position(397px);
  }

  &-accountnumbercall {
    @include top-position(147px);
    @include left-position(448px);
  }
}

// If buyer is company show transfer-order-slip
.payment-ticket-transfer-order {
  img {
    // Reset filter values for transfer order slip
    filter: unset !important;
    border-bottom: 1px solid $color-border-nav-grey;
  }

  .payment-ticket-account {
    top: 190px;
    left: 391px;
  }

  .payment-ticket-accountnumbercall {
    top: 232px;
  }

  .payment-ticket-model {
    top: 232px;
  }
}