@import "../constants/constants";
@import "../base/mixins";


/****** STYLE *******/
.checkout-step5--title {
  margin: 0;
  color: $color-text-accent;

  @include from($desktop-large) {
    margin: 30px 0;
    font-size: $font-size-h2;
  }
}

.checkout-step5--steps {
  position: relative;
  background: $color-white;
  border: 5px solid $color-border-nav-white;
  border-top: 5px solid $color-background-accent;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  transform: rotate(45deg);

  @include from($desktop-large) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    content: '5';
    transform: rotate(-45deg);
    justify-content: center;
    align-items: center;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    font-size: $font-size-lead;
  }
}

.checkout-step5--content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  margin-top: 30px;

  @include from($desktop-large) {
    margin-top: 50px;
  }
}

.checkout-step5--finish {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: sassvg("check", $color-white) no-repeat center, $color-background-accent;
  background-size: 60%;
}

.checkout-step5--last {
  margin: 30px 0;

  @include from($desktop-large) {
    margin-top: 70px;
  }
}

.checkout-step5--text {
  font-size: $font-size-lead;
  color: $color-text-lead;

  p:first-child {
    margin-top: 0;
  }
}

.purchase-validation-info,
.purchase-validation--title {
  margin-top: 0;
}

