@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
// News list default grid
.news-list--default {
  @extend .ce-container-width;
  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 10px auto;
  }

  .news-list--badge-wrap {
    position: sticky !important;
    top: 20px;
    height: fit-content;

    .news-list--line {
      @include verticalTextLine($color-background-vertical-line, $color-text-label)
    }
  }
}

.news-list--default-item-wrap {
  display: grid;
  grid-template-columns: 1fr;
  @include from($tablet) {
    grid-template-columns: 1fr 1fr;
  }

  grid-gap: 60px;
  @include from($desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include from($desktop-large) {
    margin-left: -10px;
  }
}

.news-list--default-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/************** STYLE *************/
.news-list--default-date {
  color: $color-text-accent;
  font-size: $font-size-x-small;
  font-family: $font-copy-bold;
}

.news-list--default-read-more {
  @include linkWithArrowExtend($color-text-accent, $color-background-accent, 9px);
}

.news-list--default-text {
  p {
    font-size: $font-size-lead;
    color: $color-text-lead;
  }
}

.news-list--default-headline {
  margin-top: 20px;
  // Small headline tweaking
  line-height: 1.3;
}

.news-list--default-headline-link {
  text-decoration: none;
  color: $color-text-base;

  &:hover {
    text-decoration: underline;
  }
}