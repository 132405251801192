@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.collection-list--container {
  @extend .ambient-list--container;
}

.collection-list--container-inner {
  @extend .ambient-list--container-inner;
}

.collection-list--grid {
  @extend .ambient-list--grid;

}

.collection-list--navigation-wrap {
  @extend .ambient-list--navigation-wrap;
}

.collection-list--item-wrap {
  @extend .ambient-list--item-wrap;
}

.collection-list--item-wrap.collection-list--item-single {
  @include from($tablet) {
    grid-template-columns: repeat( auto-fit, minmax(250px, 525px));
  }
}

/************** STYLE *************/
.collection-list--headline {
  @extend .ambient-list--headline;
}

.collection-list--item {
  @extend .ambient-list--item;
}

.collection-list--item-img {
  @extend .ambient-list--item-img;
}

.collection-list--item-headline {
  @extend .ambient-list--item-headline;
}

.collection-list--item-link {
  @extend .ambient-list--item-link;
}

/*
 *** collection navigation
 */
.collection-list--nav-trigger-mobile {
  @extend .ambient-list--nav-trigger-mobile;
  padding-right: 0;
  background: sassvg("chevron_down") no-repeat;
  background-position: 100% center;

  &.accordion-active {
    background: sassvg("chevron_up") no-repeat;
    background-position: 100% center;
  }
}

// ul
.collection-list--navigation-wrap {
  @extend .ambient-list--navigation-wrap;
}

// li
.collection-list--navigation-item {
  @extend .ambient-list--navigation-item;

  &.collection-active {
    position: relative;
    @include selectedProductNav(
            $arrow-color: $color-background-accent,
            $top: 5px
    );

    @include until($desktop) {
      padding-left: 30px !important;
    }

    .collection-list--navigation-item-icon {
      filter: $color-background-accent-svg-fill;
    }

    .collection-list--navigation-item-link {
      color: $color-text-accent;
    }
  }
}

.collection-list--navigation-item-link {
  @extend .ambient-list--navigation-item-link;
}

.collection-list--navigation-item-icon {
  @extend .ambient-list--navigation-item-icon;

  &.all-collections {
    width: 26px;
    height: 28px;
  }
}

/*
 *** Slider
 */
.collection-list--slider-item {
  @extend .ambient-list--slider-item;
}

.collection-list--slider-item-img-wrap {
  @extend .ambient-list--slider-item-img-wrap;
}

.collection-list--item-img-wrap {
  @extend .ambient-list--item-img-wrap;
}


.collection-list--slider-item-headline {
  @extend .ambient-list--slider-item-headline;
  margin: 10px 0;
}

.collection-list--item-subheadline {
  font-family: $font-copy-bold;
  margin: 10px 0;
}

.collection-list--slider-item-price-old {
  @extend .ambient-list--slider-item-price-old;
}

.collection-list--slider-item-price-new {
  @extend .ambient-list--slider-item-price-new;
}

// slider override
.collection-list--slider {
  @extend .ambient-list--slider;
}