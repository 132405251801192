@import "../constants/constants";
@import "../base/mixins";
@import "dist/icons/_sassvg.scss";

$categories-text-indent-left: 30px;
$categories-text-indent-right: 30px;


/* HOMEPAGE LOCATIONS */

.homepage-locations {
  @include from($desktop) {
    margin-bottom: 120px;
  }
}

/************* GRID ***************/
.product-list--container {
  @extend .ce-full-width-no-margin;
  margin: 10px 0;
}

.product-list--full {
  @extend .article-list-grid;
}

.product-list--container-inner {
  @extend .ce-container-inner-width;

  @include until($desktop) {
    position: relative;
  }
}

// CE grid with grey background
@include greyBackgroundOption(
        $div-container-class: "product-list--systems-teasers",
        $div-inner-class: "product-list--systems-teasers-inner"
);
//@include greyBackgroundOption(
//        $div-container-class: "product-list--tabs-container",
//        $div-inner-class: "product-list--tabs-container-inner"
//);

.product-list--systems-teasers-inner {
  padding: 30px 0 !important;
}

.product-list--trigger-wrap {
  display: flex;

  @include until($tablet) {
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }

  @include from($desktop) {
    margin-top: -30px;
  }
}

// Contains Headline and sort select

.product-list--header-top {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 30px;

  @include from($desktop) {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

// Contains filter tags
.product-list--header-bottom {
  display: none !important;

  @include from($desktop) {
    display: grid;
    grid-template-columns: 43% 57%;
    min-height: 30px;
  }
  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 33% 67%;
  }
}

// ------ Content Grid
.product-list--main {
  margin-top: 0;
  @include from($desktop) {
    margin-top: 50px;
  }
  //opacity: 0.2;

  &.loaded {
    //opacity: 1;
  }

  @include from($desktop) {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-gap: 50px;
    margin-top: 40px;
  }

  @include from($ultra-wide-screen) {
    grid-template-columns: 270px 1fr;
    grid-gap: 100px;
  }

  &.product-list--main--no-filter {
    display: block;
  }

}

// Product list grid
.product-list--item-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @include from(520px) {
    grid-template-columns: 1fr 1fr;
  }

  @include until($tablet) {
    background: #ddd;
    margin: 0 -20px 20px;
    padding: 20px;
  }

  @include from($desktop-large) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 70px;
    .product-list--main--no-filter & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 60px;
    }
  }

  @include until($desktop-large) {
    .b2b-ambients-detail--system-elements & {
      gap: 50px;
    }
  }

}

/************** STYLE *************/

.product-list {
  .product-homepage--tab {
    //background-color: $color-background-grey-light-accent;
  }

  .product-homepage--container-inner-tabs {
    //@extend .ce-container-inner-width;
    background: none;
  }

  .product-homepage--tabs {
    text-align: left !important;
  }

  .product-homepage--tab-content {
    .product-list--main {
      margin-top: 0 !important;
    }
  }

  .pick-and-prepend.show-picked-el {
    @include from($desktop) {
      margin-bottom: 8px;
    }
    @include from($desktop-large) {
      margin-bottom: 3px;
    }
    @include from($wide-screen) {
      margin-bottom: 23px;
    }
    @include from($ultra-wide-screen) {
      margin-bottom: -5px;
    }
  }

  .product-list--stickers {
    top: 20px;
    @include until($desktop) {
      top: 5px;
      right: 5px;
    }
  }
}

.product-list-action--full {
  @extend .ce-full-width-no-margin;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(240, 240, 240, 1) 45%);

  display: none;

  @include from($desktop) {
    //margin-top: 40px;
  }

  &-inner {
    @extend .ce-container-inner-width;
  }
}

.product-list--item {
  border-bottom: none;
  padding-bottom: 0 !important;

  background: url("/assets/images/product-list-dummy.jpg") no-repeat center;

  @include until($tablet) {
    padding: 5px;
    border-radius: 4px;
  }
  background-size: contain;

  @include from($desktop) {
    margin-bottom: 100px;
    // border-bottom: 1px solid $color-border-nav-grey;
    &:hover {
      z-index: 2;

      .product-list--item-inner {
        box-shadow: 0 0 0 4px rgba(230, 230, 230, 1);
        background-color: $color-white;
        border-radius: 4px;
      }

      .product-list--item-sibling-wrap {
        display: grid;
      }

      .product-list--item-content {
        margin-bottom: 20px;

        .container-main-b2b & {
          margin-bottom: 0; // RS list, ENG list, and b2b list are different.
        }
      }

      .product-list--item-image-hover {
        opacity: 1;
        transition: 300ms;
      }
    }
  }
  @include from($desktop-large) {
    margin-bottom: 0;
  }
  @include from($wide-screen) {
    // margin-bottom: 62px;
  }

  &.product-full-loaded {
    background: none;
    @include until($tablet) {
      background: #fff;
    }
  }


  &-discount-info {
    height: fit-content;

    .splide__list__single & {
      display: block;
      margin-bottom: 10px;

      @include until($desktop) {
        margin-top: -5px;
      }

      p {
        margin: 0;
      }
    }

    span {
      color: $color-text-red !important;
    }
  }


  &--loyalty-container {
    display: grid;

    &.product-list--item--with-margin-top {
      margin-top: 10px;
    }

    &.product-list--item--with-additional-margin-top {
      margin-top: 17px;

      @include from($tablet) {
        margin-top: 20px;
      }
    }
  }

  &--loyalty-price {
    font-family: MulishBold, Trebuchet, Geneva, Arial, sans-serif;
    color: $color-text-accent-secondary;
    font-size: 14px;

    .splide__list__single & {
      @include until($desktop) {
        font-size: 14px;
      }
    }

    @include from($tablet) {
      font-size: 24px;
    }

    small {
      font-size: 60%;
    }
  }

  &--loyalty-discount {
    color: $color-text-accent-secondary;

    .splide__list__single & {
      padding-right: 20px;
    }

    @include until($tablet) {
      font-size: 8px;
    }
  }

  .product-list--item--loyalty-discount {
    @include from($tablet) {
      margin-top: -4px;
    }

    .splide__list__single & {
      @include until($desktop) {
        //margin-top: 0;
      }
    }
  }

  &-loyalty-two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-column: 1/3;
    order: 3;
    margin-top: -5px;

    @include from($tablet) {
      margin-top: -10px;
    }

    @include from($desktop) {
      grid-gap: 20px;
      margin-top: -20px;

      .splide__list__single & {
        &.product-list--item--with-margin-top {
          margin-top: -15px;
        }
      }
    }

    .product-list--item--loyalty-discount {
      grid-column: 1/3;
      margin-top: -10px;

      @include from($tablet) {
        margin-top: -14px;
      }

      @include from($desktop) {
        margin-top: -24px;
      }
    }
  }

  &--loyalty-price-small {
    font-family: MulishBold, Trebuchet, Geneva, Arial, sans-serif;
    display: flex;
    align-items: flex-end;
    color: $color-text-accent-secondary;
    font-size: 14px;
    gap: 5px;
    line-height: 1;
    margin-bottom: 3.5px;

    @include from($tablet) {
      margin-bottom: 7px;
    }

    @include from($desktop) {
      font-size: 18px;
    }

    small {
      font-size: 60%;
      margin-bottom: 1px;
    }
  }

}

.product-list--item-inner {
  @include from($desktop) {
    position: absolute;
    top: -15px;
    height: auto;
    left: -20px;
    right: -20px;
    padding: 15px 20px 0 20px;
    .container-main-b2b & {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      padding: 0;
    }
  }
}

.product-list--item-sibling {
  width: 50px;
  height: 50px;

  &-wrap {
    display: none;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    min-height: 50px;
    margin-bottom: 20px;
  }
}

.product-list--item-content {
  position: relative;
  padding-bottom: 10px;
}

.product-list--item-add-container {
  @include from($tablet) {
    height: 46px; // Use to align "Popust važi" messages.
  }

  display: flex;
  align-items: center;
  //justify-content: space-between;
  margin-top: 12px;
  gap: 10px;

  @include from($mobile) {
    //justify-content: flex-start;
    gap: 20px;
  }

  @include from($desktop) {
    //justify-content: flex-start;
    gap: 30px;
  }

  @include from($desktop-large) {
    gap: 20px;
  }

  @include from($wide-screen) {
    gap: 30px;
  }

  @include until($tablet) {
    margin-top: 10px;
  }

  div {
    color: $color-background-accent;
    font-family: $font-head-bold;
    background: sassvg('delivery-icon', $color-background-accent) no-repeat left center;
    background-size: 16px;
    font-size: 6px;
    padding-left: 20px;

    @include from(380px) {
      font-size: 7px;
    }

    @include from(420px) {
      font-size: 9px;
      padding-left: 20px;
    }

    @include from(520px) {
      font-size: 12px;
      background-size: 32px;
      padding-left: 40px;
    }

    @include from($wide-screen) {
      font-size: 14px;
    }

    .not-available & {
      display: none;
    }
  }
}

.not-available.product-list--item {
  .product-list--item-add-container {
    a {
      display: none;
    }
  }

  .product-list--item-info--deliverytime {
    display: none;
  }
}

.product-list--item-info {
  //display: none;

  .ambient-detail--list & {
    margin-top: unset;
    margin-bottom: $container-margin / 2;
  }
}

.product-list--item-info--deliverytime {
  display: none;

  .small-cart-message-text & {
    display: block;
  }
}

.product-list--item-info,
.product-list--item-info--active {
  margin-top: 10px;

  .splide-homepage & {
    padding-right: 20px;
  }

  p {
    margin: 0;
  }
}

.product-list--item-info--discount {
  display: block;
  color: $color-text-product-discount;
  @include until($tablet) {
    font-size: 8px;
  }

  &--message {
    color: $color-text-product-price;
  }

  .product-list--item-info--active & {
  }


  .ambient-detail--list & {
    color: $color-text-red;
  }

  .product-list--item-info--loyalty & {
    color: $color-text-accent-secondary !important;
  }
}

.product-list--item-image-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: $imagePaddingRightIndent;

  .container-foreign & {
    // No icons, so no padding right needed
    right: 0;
  }

  opacity: 0;
  transition: 300ms;
}

.product-list-discount {
  .product-list-full--headline {
    @include headlineArticleList($color-text-red);
  }
}

.product-list-new {
  .product-list-full--headline {
    @include headlineArticleList($color-text-green);
  }
}

.product-list-title-new {
  .product-list--header-title {
    @include headlineArticleList($color-text-green);
  }
}

.product-list-title-action {
  .product-list--header-title {
    @include headlineArticleList($color-text-red);
  }
}

.product-list-title-web-discount {
  .product-list--header-title {
    @include headlineArticleList($color-text-red);
  }
}

.product-list-title-outlet {
  .product-list--header-title {
    @include headlineArticleList($color-text-red);
  }
}

.product-list--back {
  display: inline-block;
  margin-left: 30px;
  margin-right: 10px;

  @include from($desktop) {
    display: none;
  }

  &-link {
    @include linkWithArrowReverseExtend(
            $top: 15px
    );
  }
}

.product-list--breadcrumb {
  display: block;
  margin-bottom: 20px;
  @include from($desktop) {
    margin-bottom: 0;
  }
}

// ------- HEADER
.product-list--header {
  margin-bottom: 10px;
}

.product-list--header-title {
  display: flex;
  margin: 5px 0 0 0;
  font-size: $font-size-h3;

  @include from($desktop) {
    display: block;
    margin-top: 0;
    font-size: $font-size-h2;
  }
}

.product-list--header-row {
  display: flex;
  justify-content: flex-end;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.product-list--header-filter-container {
  //display: none;
  margin-bottom: 30px;

  &--search {
    margin-top: 40px;
  }

  @include until($desktop) {
    margin-bottom: 0 !important;
  }

  @include from($desktop) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    &--search {
      justify-content: space-between;
    }
  }

  &.product-list-action {
    display: block;
    margin-bottom: 40px;

    @include from($desktop) {
      display: grid;
      grid-template-columns: 1fr 215px;
      grid-gap: 100px;
      margin-bottom: 60px;
      justify-content: unset;
    }

    .product-list--header-sort-wrap {
      display: none;

      @include from($desktop) {
        display: block;
      }
    }
  }

  @include until($tablet) {
    .product-list--header-sort {
      font-size: $font-size-small;
      text-transform: uppercase;
      padding: 12px 30px 12px 15px;
    }
  }
}

.product-list--header-filter-tag {
  display: inline-block;
  padding: 2px 10px;
  color: $color-text-lead;
  font-size: $font-size-medium;
  border-radius: 2px;
  background-color: rgba(186, 186, 186, 0.17);
  cursor: pointer;

  @include from($tablet) {
    margin: 7px 14px 7px 0;
  }

  @include from($desktop) {
    margin: 5px 14px 5px 0;
  }

  &.tag-active {
    color: #F3F3F3;
    border-radius: 2px;
    background-color: $color-background-accent;
  }

  &-wrap {
    @include until($tablet) {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    // Manually disable for page with only two items
    .container-main-1500 & {
      display: none;
    }
  }

  &-flex {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include from($tablet) {
      margin-bottom: 20px;
    }

    @include from($desktop) {
      margin-bottom: 0;
    }
  }
}

.product-list--header-tag {
  display: inline-block;
  float: left;
  padding: 2px 30px 2px 10px;
  margin: 0 5px 5px 0;
  border: 1px solid $color-input-border-default;
  border-radius: 2px;
  color: $color-text-lead;
  font-size: $font-size-medium;
  background: sassvg("ic-close", $color-background-grey) no-repeat center right 10px;
  background-size: 8px;
  cursor: pointer;

  &:hover {
    background: sassvg("ic-close", $color-background-red) no-repeat center right 10px;
    background-size: 8px;
  }

  @include from($desktop) {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }

  &-wrap {
    &:after {
      display: block;
      height: 0;
      content: '';
      clear: both;
    }
  }

}

.product-list--header-filter-mobile {
  flex: 0 0 42px; // column width 42px due to parent's display flex
  width: 42px;
  height: 42px;
  margin-left: 20px;
  margin-right: -10px; // Indent because of alignment
  background: sassvg("ic-filter", $color-background-accent) no-repeat center;
  cursor: pointer;

  @include from($desktop) {
    display: none;
  }
}

.product-list--tab {
  @include until($xsmobile) {
    padding: 0 8px;
    font-size: $font-size-medium;
  }
  @include until(540px) {
    font-size: $font-size-copy;
  }

  &.active-tab {
    color: $color-text-red;
  }
}

.product-list-action {

  display: none;

  position: relative;
  //display: grid;
  grid-template-columns: 120px minmax(0, 1fr);
  grid-gap: 20px;

  @include from($xmobile) {
    //grid-gap: 20px;
  }

  @include from($mobile) {
    grid-template-columns: 150px minmax(0, 1fr);
    //grid-gap: 20px;
  }

  @include from($desktop-large) {
    grid-template-columns: 280px minmax(0, 1fr);
    grid-gap: 50px;
  }
}

$icon-pos-x: 100px;
.product-list-action--image-wrap {
  //margin-left: -$icon-pos-x;
  border-radius: 6px;
  background: url("/source/svg/markice/idealna-cena.png") no-repeat center center $color-background-yellow;
  background-size: 80%;

  @include from($desktop-large) {
    background: url("/source/svg/markice/idealna-cena.png") no-repeat center center, $color-background-yellow;
    background-size: 80%;
  }
}

.product-list-action--image {
  display: none; // Disables item due to icon change
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  width: 100px;
  color: $color-text-accent;
  font-size: 21px;
  font-family: $font-copy-semi-bold;
  line-height: $line-height-headline;

  @include from($mobile) {
    width: 150px;
    font-size: 22px;
  }


  @include from($desktop-large) {
    font-size: $font-size-h2;
  }
}

// overrides styles from homepage
.product-list-action--slider {
  .product-list--item-title {
    min-height: 42px;
  }

  .product-list--item {
    margin-bottom: 40px !important;

    @include from($desktop) {
      margin-bottom: 20px !important;
    }
  }

  .product-list--item-content {
    margin-bottom: 0 !important;
    border-bottom: none !important;
  }

  .product-list--stickers {
    top: 10px;
  }

  .product-list--item-image-wrap {
    @include until($tablet) {
      padding: 0;
      margin: -10px -10px 0 -10px;

      img {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
  }


  .splide__slide {
    @include until($desktop-large) {
      opacity: 0.5;
      transition: opacity 200ms;

      &.is-active {
        opacity: 1;
        transition: opacity 200ms;
      }

      &.is-visible {
        opacity: 1;
        transition: opacity 200ms;
      }
    }
  }

  .splide__pagination {
    bottom: 0;
    display: inline-grid;
    grid-template-columns: repeat( auto-fit, minmax(10px, 1fr) );
    //width: 100%;
    border-radius: 2px;
    overflow: hidden;

    li {
      padding: 0;
      line-height: 0;
    }
  }

  .splide__pagination__page {
    height: 4px;
    width: 100%;
    margin: 0;
    border-radius: 0;

    &.is-active {
      transform: none;
      background-color: $color-background-yellow;
      overflow: hidden;
      border-radius: 2px;
    }
  }

  .splide__arrow--prev,
  .splide__arrow--next {
    width: 30px;
    height: 24px;
    opacity: 1;
    margin-top: -70px;
  }

  .splide__arrow[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .splide__arrow--prev {
    left: 0;
    background: sassvg("ic-arrow-prev", $color-background-accent) no-repeat center;
    background-size: contain;
  }

  .splide__arrow--next {
    right: 2px;
    background: sassvg("ic-arrow-next", $color-background-accent) no-repeat center;
    background-size: contain;
  }
}

// ------ TABS
// tabs buttons
.product-list--trigger {
  display: grid;
  min-height: 42px;
  width: 50%;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  background-color: $color-background-grey-light-accent-rgba;
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  color: $color-text-grey-light;
  font-family: $font-copy-semi-bold;

  @include from($xmobile) {
    padding: 5px 40px;
    width: auto;
  }

  @include from($tablet) {
    margin-right: 10px;
    font-size: $font-size-lead;
  }

  &.active-tab {
    color: $color-text-accent;
    background-color: $color-background-grey-light-accent;
  }
}

// tabs content
//.product-list--tab {
//  display: none;
//  grid-template-columns: repeat(3, 100px);
//  grid-gap: 10px;
//
//  &.active-tab {
//    display: grid;
//  }
//
//  @include from($xsmobile) {
//    grid-template-columns: repeat(auto-fit, minmax(90px, 100px));
//  }
//
//  @include from($desktop-large) {
//    grid-template-columns: repeat(auto-fit, minmax(120px, 140px));
//    grid-gap: 20px;
//  }
//}
//
//.product-list--tab-item {
//  position: relative;
//  padding: 7px;
//  text-decoration: none;
//  color: $color-text-base;
//  font-size: $font-size-medium;
//  line-height: $line-height-reduced;
//  text-align: center;
//  border-radius: 4px;
//  background-color: $color-white;
//
//  &.active-item {
//    .product-list--tab-item-layer {
//      opacity: 1;
//      transition: opacity 200ms;
//      background: sassvg("rounded-check", $color-white) no-repeat center bottom 60% $color-background-accent-opacity;
//      background-size: 30px;
//    }
//
//    .product-list--tab-item-text {
//      color: $color-white;
//    }
//  }
//
//  &:not(.active-item):hover {
//    .product-list--tab-item-layer {
//      opacity: 1;
//      transition: opacity 200ms;
//      background: $color-background-accent-opacity;
//      background-size: 30px;
//    }
//
//    .product-list--tab-item-text {
//      color: $color-white;
//    }
//  }
//
//  &-text {
//    position: relative;
//    z-index: 2;
//  }
//
//  &-layer {
//    display: flex;
//    align-items: flex-end;
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    right: 0;
//    left: 0;
//    padding: 7px;
//    opacity: 0;
//    border-radius: 4px;
//    justify-content: center;
//    color: $color-white;
//    transition: opacity 200ms;
//  }
//
//  &-image {
//    margin-bottom: 10px;
//  }
//}

// ------ FILTERS

.product-list--filter {
  display: none;

  [data-page-uid="115"] & {

    position: fixed;
    top: 290px !important;
    left: 20px;
    right: 20px;

    @include from(360px) {
      top: 300px !important;
    }

    @include from($mobile) {
      position: absolute;
      top: -290px !important;
      left: 0;
      right: 0;
    }

    @include from($tablet) {
      top: -305px !important;
    }

    @include from($desktop) {
      display: block !important;
      position: sticky;
      top: 150px;
      overflow-y: auto;
      height: 90vh;
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: $color-scroll-rail;

        &-thumb {
          background-color: $color-scroll-handler;
          border: 2px solid $color-scroll-handler;
        }

        &-track {
          background-color: $color-scroll-rail;
        }
      }
    }
  }

  [data-page-uid="95"] & {
    position: absolute;
    top: -415px !important;

    @include from(330px) {
      top: -425px !important;
    }

    @include from(340px) {
      top: -415px !important;
    }

    @include from(360px) {
      top: -425px !important;
    }

    @include from(380px) {
      top: -440px !important;
    }

    @include from(400px) {
      top: -450px !important;
    }

    @include from(420px) {
      top: -460px !important;
    }

    @include from(440px) {
      top: -480px !important;
    }

    @include from(460px) {
      top: -490px !important;
    }

    @include from(480px) {
      top: -280px !important;
    }

    @include from(499px) {
      top: -265px !important;
    }

    @include from(550px) {
      top: -275px !important;
    }

    @include from(590px) {
      top: -290px !important;
    }

    @include from($tablet) {
      top: -305px !important;
    }

    @include from($desktop) {
      display: block !important;
      position: sticky;
      top: 150px;
      overflow-y: auto;
      height: 90vh;
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: $color-scroll-rail;

        &-thumb {
          background-color: $color-scroll-handler;
          border: 2px solid $color-scroll-handler;
        }

        &-track {
          background-color: $color-scroll-rail;
        }
      }
    }
  }

  @include until($tablet) {
    top: 0;
  }

  @include until($desktop) {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    padding: 10px;
    background: $color-background-grey-light-accent;
    z-index: 2;
    overflow: auto;
    border-radius: 2px;
    max-height: 450px;
  }

  @include from($desktop) {
    display: block !important;
    position: sticky;
    top: 150px;
    overflow-y: auto;
    height: 90vh;
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $color-scroll-rail;

      &-thumb {
        background-color: $color-scroll-handler;
        border: 2px solid $color-scroll-handler;
      }

      &-track {
        background-color: $color-scroll-rail;
      }
    }
  }

  @include from($desktop-large) {
    top: 50px;
  }

  &-close {
    width: 42px;
    height: 42px;
    margin-left: 20px;
    margin-right: -10px; // Indent because of alignment
    background: $color-background-accent sassvg('ic-close', $color-white) no-repeat center;
    border-radius: 50%;
    cursor: pointer;
  }

  &-header {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;

    @include from($desktop) {
      display: none;
    }

    &-title {
      margin-top: 5px;
      font-size: $font-size-h2-mobile;
    }
  }

  &-row {
    @include from($desktop) {
      margin-left: -30px;
      padding-left: 30px;
      border-top: 1px solid $color-background-steps-shop-tutorial;

      &.bottom-border-applied {
        border-bottom: 1px solid $color-background-steps-shop-tutorial;
      }
    }

    //.product-list--filter-inner &:nth-child(5),
    //.product-list--filter-inner &:nth-last-child(2) {
    //  @include from($desktop) {
    //    border-bottom: 1px solid $color-background-steps-shop-tutorial;
    //  }
    //}

    // override default checkbox
    .checkbox {
      &-checkmark {
        background-color: $color-background-grey-light-accent;
        border-color: $color-background-grey-light-accent;
      }

      &-container {
        padding-left: $categories-text-indent-left;
        padding-right: $categories-text-indent-right;
        margin-bottom: 0;
      }
    }

    .product-list--filter-color-wrap {
      margin-left: 15px;
      @include from($desktop-large) {
        margin-left: 0;
      }
    }
  }

  &-title {
    @include leftVerticalLine();
    margin-bottom: 15px;
    font: $font-size-h4 $font-copy-bold;
    color: $color-text-accent;

    &:before {
      top: 5px;
    }

    @include from($desktop) {
      margin-left: -15px;
    }
  }

  &-slider-container {
    position: relative;
    height: 30px;
    margin-left: 0px;
    margin-right: 25px;
    @include from($desktop-large) {
      margin-left: 0px;
    }

    &.filter-dimension {
      height: 70px;
      padding-bottom: 70px !important;
      padding-top: 20px !important;
    }
  }

  &-dimension-title {
    margin-bottom: 20px;
    font: $font-size-small-uppercase $font-copy-bold;
    color: $color-text-accent;
    text-transform: uppercase;
    position: relative;
    left: -5px;
    @include from($desktop-large) {
      left: unset;
    }
  }

  &-range {
    position: absolute;
    bottom: 0;
    font: $font-size-medium $font-copy-bold;
    color: $color-text-grey-light;
    font-weight: normal;

    &-from {
      left: 0;
    }

    &-to {
      right: 0;
    }
  }

  &-buttons-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding-left: $container-margin;
    padding-right: $container-margin;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
    background-color: $color-white;
    border-top: 1px solid $color-border-filter-range;

    @include from($desktop) {
      display: none;
    }
  }

  &-apply {
    display: inline-block;
    padding: 20px 20px;
    font: $font-size-medium $font-copy-bold;
    color: $color-white;
    background-color: $color-background-accent;
    cursor: pointer;
    border-radius: $btn-border-radius;

    @include from(341px)
  }

  &-reset {
    display: inline-block;
    padding: 20px 30px 20px 50px;
    font: $font-size-medium $font-copy-bold;
    background: sassvg("ic-reset") no-repeat center left 15px;
    cursor: pointer;
  }
}

.product-list--filter-inner {
  width: auto;

  @include from($tablet) {
    margin: 0 auto;
  }
  @include tablet() {
    max-width: $container-width-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
  }

  .show-from-desktop {
    @include until($desktop) {
      display: none !important;
    }
  }
}

.product-list--filter-accordion {
  &-inner {
    margin: 15px 0 30px 0;

    &.categories-single-category {
      margin: 10px 0 0 15px;
      font-size: 12px;
      @include from($desktop-large) {
        margin: 10px 0 0 0;
      }
    }
  }

  &-row {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .checkbox-container {
    transition: color 200ms;

    &:hover {
      transition: color 200ms;
      color: $color-text-accent;
    }
  }
}

.product-list--filter-trigger {
  transition: all 200ms;

  &:hover {
    @include from($desktop) {
      transition: all 200ms;
      filter: $color-background-accent-svg-fill;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: block;
    content: '';
    width: 14px;
    height: 14px;
    background: sassvg("ic-cart-plus", $color-background-grey-light) no-repeat center left;
  }

  &.accordion-active {
    filter: $color-background-accent-svg-fill;

    &:after {
      background: sassvg("ic-cart-minus", $color-background-accent) no-repeat center left;
    }
  }
}

.accordion-active-onload {
  max-height: inherit;
}

.product-list--filter-categories {
  &-row {
    border-top: 1px solid $color-border-filter;
    color: $color-text-lead;

    &:last-child {
      border-bottom: 1px solid $color-border-filter;
    }

    &.categories-row-remove-border {
      border: none !important;
    }
  }
}

.product-list--filter-rooms {
  font-size: $font-size-medium;
  line-height: $line-height-reduced;
}

// ------ COLORS
.product-list--filter-color {
  height: $filter-color-size;
  margin-bottom: 7px;
  margin-right: 7px;

  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

.color-filter-checkmark {
  background-size: 39px !important; // override inline style
}

.show-single-product {
  display: block;
}

.hide-single-product {
  display: none;
}

// ------ SYSTEM NAV
.product-list--systems-nav {
  display: none;

  @include from($desktop) {
    margin-left: -10px;
    display: flex;
    flex-wrap: wrap;
  }
}

.product-list--systems-nav-item-link {
  position: relative;
  display: inline-flex;
  padding: 9px 10px;
  align-items: center;
  border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 12px;
  font-size: $font-size-small;
  font-family: $font-copy-semi-bold;
  color: $color-text-grey-light;
  border: 1px solid $color-border-filter;

  @include from($desktop) {
    border: none;
  }

  &:hover {
    background-color: $color-background-grey-light-accent;
    color: $color-text-accent;

    .product-list--systems-nav-item-link-icon {
      filter: invert(22%) sepia(9%) saturate(7471%) hue-rotate(199deg) brightness(50%) contrast(90%);
    }
  }

  &.active-item {
    background-color: $color-background-grey-light-accent;
    color: $color-text-accent;

    .product-list--systems-nav-item-link-icon {
      filter: invert(22%) sepia(9%) saturate(7471%) hue-rotate(199deg) brightness(50%) contrast(90%);
    }
  }

  &-wrap {
    margin-right: 10px;
    margin-bottom: 10px;
    @include from($desktop) {
      margin-right: 20px;
      max-width: 182px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-icon {
    max-width: 20px;
    margin-right: 8px;
  }
}

.product-list--systems-border {
  display: none;
  //@include from($desktop) {
  //  display: block;
  //  height: 2px;
  //  margin-top: 15px;
  //  margin-bottom: 50px;
  //  background-color: $color-border-nav-white;
  //}
}

// ------ SYSTEM ELEMENTS
.product-list--systems-teasers {
  margin-top: 15px;
}

.product-list--systems-teasers-headline {
  position: relative;
  margin-bottom: 30px;
  color: $color-text-headline;
  font-size: 22px;
  font-family: $font-copy-semi-bold;
}

.product-list--systems-teasers-center {
  display: none;
  text-align: center;
}

.product-list--systems-teasers-toggle {
  display: inline-block;
  padding-bottom: 15px;
  color: $color-text-accent;
  font-size: $font-size-medium;
  cursor: pointer;
  background: sassvg("chevron_down", $color-background-accent) no-repeat center bottom 5px;

  &.active-toggle {
    padding-top: 15px;
    padding-bottom: 0;
    background: sassvg("chevron_up", $color-background-accent) no-repeat center top 5px;

    .product-list--systems-teasers-toggle-show {
      display: none;
    }

    .product-list--systems-teasers-toggle-hide {
      display: inline;
    }
  }
}

.product-list--systems-teasers-close {
  display: none;
  position: absolute;
  top: 5px;
  right: 0;
  height: 30px;
  width: 30px;
  background: sassvg("ic-close") no-repeat center;
  cursor: pointer;

  &.close-active {
    display: block;
  }
}

.product-list--systems-teasers-toggle-show {
  display: inline;
}

.product-list--systems-teasers-toggle-hide {
  display: none;
}

.product-list--content-top {
  @include until($desktop) {
    order: 10;
    margin-bottom: 0;
  }
  margin-bottom: 40px;
  //max-width: 760px;
  > h2,
  > h3 {
    margin-top: 0;
  }
}

.product-list--content-bottom {
  margin-top: 40px;
  margin-bottom: 40px;
  //max-width: 760px;

  @include until($desktop) {
    order: 11;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.product-list--container {

  &--three-cols {
    display: grid;
    gap: $container-margin/2;
    place-items: center;
    position: relative;
    z-index: 2;
    margin-bottom: $container-margin/2;
    grid-template-columns: repeat(3, 1fr);

    @include from($tablet) {
      margin-bottom: $container-margin;
    }

    @include from($desktop) {
      display: none;
    }
  }

  &--filter {
    width: 100%;
    background: sassvg('chevron-down', $color-white) no-repeat center right 12px, $color-background-accent;
    color: $color-white;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    min-width: 80px;
    font-size: 8px;
    padding: 10px 30px 10px 12px;
    background-size: 6px;

    @include from(360px) {
      font-size: $font-size-x-small;
      background-size: 8px;
    }

    @include from(400px) {
      font-size: $font-size-medium;
    }

    &.active {
      background: sassvg('chevron-up', $color-background-accent) no-repeat center right 12px, $color-background-grey-light-accent;
      color: $color-background-accent;
      position: relative;
      background-size: 6px;

      @include from(360px) {
        background-size: 8px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: -39px;
        left: 0;
        background: $color-background-grey-light-accent;
      }
    }
  }
}

.product-list--header-filter-container {

  &--mobile {
    display: flex;

    @include from($desktop) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include from($desktop) {
      display: flex;
    }
  }
}

.product-list--container--filters {
  display: none;

  .active & {
    display: block;
    position: absolute;
    left: 0;
    z-index: 1;
    background: $color-background-grey-light-accent;
    width: calc(300% + 20px);
    max-height: 350px;
    overflow-y: auto;
    border-radius: 2px;
    padding: 10px;
    top: 45px;

    &::-webkit-scrollbar-track {
      background-color: red;
    }

    @include from(400px) {
      top: 50px;
    }

    .product-list--systems-nav {
      display: grid;
      gap: $container-margin;
      background: $color-white;
      padding: 15px 10px;
    }

    .product-list--systems-nav-item-link-wrap {
      margin: 0;
    }

    .product-list--systems-nav-item-link {
      border: none;
      display: flex;
      align-items: center;
      padding: 0 $container-margin 0 0;
      background: sassvg('arrow-right', $color-background-grey-light) no-repeat center right, $color-white !important;
      color: $color-background-grey-light !important;
      border-radius: 2px;
    }

    .product-list--systems-nav-item-link-icon {
      margin-right: 15px;
    }
  }

  [data-mobile-filter-toggle-sort] & {
    left: unset;
    right: 0;
    overflow-y: hidden !important;
  }

  .mobile-sort-toggle--second.active & {
    left: calc(-100% - 10px) !important;
  }

  .mobile-sort-toggle--first.active & {
    left: 0 !important;
  }
}

.product-list--container--filters--checkboxes {
  display: grid;
  gap: 15px;
  background: $color-white;
  padding: $container-margin/2;
  border-radius: 2px;
}

.product-list--container--filters--single-checkbox {
  display: grid;
  grid-template-columns: 17px 1fr;
  gap: 15px;

  input {
    accent-color: $color-background-accent;
  }
}

.product-list--filter-title {
  //@include until($desktop) {
  background: sassvg('chevron-down', $color-background-accent) no-repeat center right 10px, $color-white;
  padding: 10px 10px 10px 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;

  @include from($desktop) {
    font-size: 12px; // categories without 'large' class have smaller font-size on desktop
  }

  &-large {
    font-weight: bold;

    @include from($desktop) {
      font-size: 14px;
    }
  }

  &:before {
    left: 10px;
    top: 12px;
    background-color: $color-background-detail-price;
  }

  &.accordion-active {
    background: sassvg('chevron-up', $color-background-accent) no-repeat center right 10px, $color-white;

    &[data-trigger="filter-mobile-characteristic"] {
      margin-bottom: 0;
    }

    @include until($desktop) {
      margin-bottom: 15px;
    }


    &:before {
      background-color: $color-background-accent;
    }
  }
}

.product-list--filter-categories,
.product-list--filter-rooms,
.product-list--filter-slider-container,
.product-list--filter-color-wrap,
.product-list--filter-slide-container--parent,
.product-list--filter-accordion {
  max-height: 0;
  overflow: hidden;

  .accordion-active ~ & {
    margin-bottom: 16px;
  }
}

.product-list--filter-button-mobile {
  @include until($desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: fit-content;
    }
  }

  @include from($desktop) {
    margin-top: 40px;
  }
}

.product-list--filter-header {
  @include until($desktop) {
    display: none;
  }
}

.product-list--filter-row {
  @include until($desktop) {
    margin-bottom: 0;
    border-radius: 2px;
  }
}

.product-list--filter-slide-container--parent {
  @include until($desktop) {
    max-height: 0;
    overflow: hidden;
    //padding-left: 35px;
    //padding-right: 45px;
  }
}

.product-list--filter-slider-container {
  @include until($desktop) {
    margin-top: 0;
  }
}

.product-list--filter-row {
  @include until($desktop) {
    margin-bottom: 15px;
  }
}

.product-list--filter-row .product-list--filter-color-wrap {
  @include until($desktop) {
    margin-left: 0;
    padding-left: 30px;
    background: $color-white;
  }
}

.product-list--filter-title.accordion-active + div {
  @include until($desktop) {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: -15px;
    background: $color-white;
  }
}

.product-list--filter-title.accordion-active + .product-list--filter-slider-container {
  padding-bottom: 35px !important;
  padding-top: 30px !important;

  @include from($desktop) {
    padding-top: 20px !important;
  }
}

.product-list--filter-rooms {
  @include until($desktop) {
    padding-left: 30px;
    font-size: $font-size-copy;
  }
}

.product-list--filter-slider-container {
  @include until($desktop) {
    margin-right: 0;
  }
}

.product-list--filter-slider-container {
  @include until($desktop) {
    padding-left: 35px;
    padding-right: 45px;
  }

  @include from($desktop) {
    padding-left: 8px;
    padding-right: 18px;
  }
}

.product-list--filter-price,
.filter-dimension {
  @include until($desktop) {
    height: 100%;

    .product-list--filter-range-from {
      left: unset !important;
      bottom: unset;
    }

    .product-list--filter-range-to {
      right: 35px !important;
      bottom: unset;
    }

    .noUi-horizontal {
      margin-bottom: 20px;
    }
  }
}

.product-list--filter-categories {
  @include until($desktop) {
    padding-left: 30px;
    padding-right: 10px;

    .accordion-panel {
      max-height: unset;
      overflow: hidden;
    }

    .product-list--filter-trigger {
      display: none;
    }

    .product-list--filter-accordion-inner {
      margin: 0;
    }

    .product-list--filter-categories-row {
      border: unset;
      font-size: $font-size-copy;
    }

    .product-list--filter-accordion-row {
      margin-bottom: 15px;
    }

  }
}

.filter-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.25rem);
}

.product-list--item-info--discount,
.product-list--item--loyalty-discount {
  font-size: 6px;
  line-height: 8px;

  .splide__list__single & {
    @include until($desktop) {
      font-size: 6px !important;
      line-height: 8px !important;
    }
  }

  .product-detail--cart-price & {
    font-size: 8px;
    line-height: 9.6px;
  }

  @include from($desktop) {
    font-size: 8px;
    line-height: 9.6px;

    .product-detail--cart & {
      font-size: 10px;
      line-height: 12px;
    }
  }
}