@import "../constants/constants";
@import "../base/mixins";

.text-with-breadcrumb {
  max-width: unset;

  .text-lead {
    font-size: $font-size-lead-large;
    color: inherit;
  }

  @include from($tablet) {
    max-width: $tablet - 40px;
    margin: 0 auto;
  }

  @include from($desktop) {
    max-width: $desktop - 40px;
  }

  @include from($desktop-large) {
    max-width: $desktop-large - 40px;
  }

  @include from($wide-screen) {
    max-width: $wide-screen - 40px;
  }

  @include from($ultra-wide-screen) {
    max-width: $ultra-wide-screen - 40px;
  }

  .product-list--breadcrumb {
    @include until($desktop) {
      display: none;
    }
  }

  &--container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include from($ultra-wide-screen) {
      padding: 30px 0;
    }
  }

  &--header {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;

    @include from($desktop) {
      margin: 40px 0 30px;
    }
  }

  &--text {
    color: $color-text-grey-light;
    max-width: $desktop-large;
    font-size: $font-size-lead;
  }

  .text-with-breadcrumb--back {
    position: absolute;
    left: -40px;
    display: block;
    background: sassvg("arrow-left", $color-text-accent) no-repeat center center;
    width: 15px;
    height: 12px;
    margin-right: 15px;
    color: transparent;

    //@include from($tablet) {
    //  display: none;
    //}
  }

  .breadcrumb--container {
    @include until($tablet) {
      display: none;
    }
  }
}