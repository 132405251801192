@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.collection-detail--container {
  @extend .ambient-detail--container;
}

.collection-detail--container-inner {
  @extend .ambient-detail--container-inner;
}

.collection-detail--grid {
  @extend .ambient-detail--grid;

  .slider-irre--item-badge-link-icon {
    @include until($desktop) {
      z-index: 2;
    }
  }
}

.collection-detail--grid-right {
  @extend .ambient-detail--grid-right;
}

.collection-detail--grid-left {
  @extend .ambient-detail--grid-left;
}


/************** STYLE *************/
.collection-detail {
  //***** Overrides hover from product list *****//
  // check also:
  // _ce-product-homepage.scss
  // _p_ambient_detail.scss
  .product-list--item,
  .product-list--item-content {
    margin-bottom: 0 !important;
  }
}

.collection-detail--headline {
  @extend .ambient-detail--headline;
}

.collection-detail--content {
  @extend .ambient-detail--content;
}

.collection-detail--image {
  @extend .ambient-detail--image;
}

.collection-detail--article-divider {
  @extend .ambient-detail--article-divider;
}

.collection-detail--article-wrap {
  @extend .ambient-detail--article-wrap;
}

.collection-detail--back-link {
  @extend .ambient-detail--back-link;
}

.collection-detail--subheadline {
  margin-top: 0;
}

.collection-detail--video {
  @extend .ambient-detail--video;
}

.collection-detail--video-inner {
  @extend .ambient-detail--video-inner;
}