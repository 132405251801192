@import "../constants/constants";
@import "../base/mixins";

$register-padding-horizontal: 50px;
$footer-height-desktop-large: 335px;
$register-input-inactive-opacity: 0.4;


//************* GRID ***************/
// Part of Grid for register page is in critical because of preventing jumping
.register--right-form-container-inner {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  @include from($tablet) {
    grid-column-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }
}

.register--left {
  .breadcrumb--container {
    display: none;

    @include from($desktop-large) {
      display: inline-block;
    }
  }
}

.register--right-statement {
  position: relative;
  margin: 40px 0;

  .text {
    padding-bottom: 0;
  }

  .checkbox-container {
    margin-bottom: 0;
  }
}

.checkbox-statement {
  margin-left: 20px;
  display: inline-block;
}

.register--right-column-border {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid $color-border-filter;
  @include from($desktop-large) {
    margin-bottom: 0;
  }
}


/******** STYLE *********/
.register--left-checkbox-company {
  margin-top: 40px;
  margin-bottom: 20px;

  .checkbox-container {
    margin-right: 20px;
  }

  .pristine-error {
    position: absolute;
    bottom: -25px;
    min-width: 320px;
  }
}

.register--left-checkbox-wrap--container {
  display: flex;
  grid-column-gap: 30px;
}

.register--subheadline {
  color: $color-text-label;
  margin-top: 0;
}

.register--input {
  @extend .default-input;
}

.register-right-flexibile {
  background-color: $color-background-grey-light-accent;
}

.register--left-breadcrumb {
  position: relative;
}

.register--back {
  @extend .felogin--back;
}

.register-status {
  margin-top: 10px;
}

.register--right-flash-message {

  li {
    padding-left: 0;
    color: $color-text-accent;

    &:before {
      display: none;
    }
  }
}

.register--input-wrap {
  @extend .default-fieldwrap;
  margin-bottom: 20px;
}

.register--submit {
  @extend .default-submit;
}

.register--input-label {
  @extend .default-label;
}

.register--btn-statement {
  @include buttonBlue;

  .link-with-arrow {
    transform: translateY(-50%);
  }
}

.register--input-company-wrap {
  display: none;
}

.register--input-company-wrap.animate-show-element {
  display: block;
}

.autocomplete-items {
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  margin-top: 0;
}

.autocomplete-item {
  cursor: pointer;
  list-style: none;
  padding: 3px 10px;
  list-style: none;

  &:before {
    content: none;
  }

  &[aria-selected="true"] {
    background: $color-background-header-search;
    color: #fff;
  }
}

.register--phone-input-container {
  position: relative;
  display: flex;
  width: 100%;

  & > span,
  .register--input {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .register--input {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
  }

  & > span {
    font-size: 14px;
    white-space: nowrap;
    padding: 10px 12px;
    font-size: $font-size-lead;
    color: $color-white;
    background: $color-background-accent;
    border: 1px solid $color-input-border-grey;
    border-right: 0;

    @include until($desktop) {
      padding: 11px 10px;
      font-size: $font-size-copy;
    }
  }
}