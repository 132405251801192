@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.ambient-list--container {
  @extend .ce-full-width-no-margin;
  margin: 30px 0;
}

.ambient-list--container-inner {
  @extend .ce-container-inner-width;
}

.ambient-list--grid {
  margin-top: 20px;

  @include from($desktop) {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-gap: 50px;
  }

  @include from($desktop-large) {
    margin-top: 0;
  }

  @include from($ultra-wide-screen) {
    grid-template-columns: 280px 1fr;
  }

}

.ambient-list--navigation-wrap {
  @include tablet() {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  @include from($desktop) {
    max-height: none;
    overflow: unset;
  }
}

.ambient-list--item-wrap {
  margin-top: 20px;
  @include from($tablet) {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-gap: 60px 30px;
  }

  @include from($desktop-large) {
    grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
    margin-top: 0;
    grid-gap: 60px 60px;
  }

  @include from($wide-screen) {
    grid-template-columns: repeat( auto-fit, minmax(450px, 1fr) );
  }
}

.ambient-list--item-wrap.ambient-list--item-single {
  @include from($tablet) {
    grid-template-columns: repeat( auto-fit, minmax(250px, 525px));
  }
}

/************** STYLE *************/
.ambient-list--headline {
  margin-bottom: 10px;

  @include until($tablet) {
    font-size: $font-size-h3;
  }
}

.ambient-list--item {
  margin-bottom: 50px;

  @include from($desktop) {
    margin-bottom: 0;
  }
}

.ambient-list--item-img {
  display: block; // Remove bottom space
  transition: all 0.5s;
  will-change: transform;

  &-wrap {
    border-radius: 4px;
    @include from($tablet) {
      margin: 0;
      line-height: 0;
    }
  }
}

// Zoom animation
.ambient-list--item-img-wrap {
  position: relative;
  // Hidden overflow  when zoom in picture
  overflow: hidden;

  &:hover {
    // Zoom in img on hover column
    .ambient-list--item-img {
      transform: scale(1.04);
    }
  }
}


.ambient-list--item-headline {
  margin: 15px 0;
}

.ambient-list--item-link {
  color: unset;
  text-decoration: none;

  &:hover {
    .ambient-list--item-headline {
      text-decoration: underline;
    }
  }
}

/*
 *** ambient navigation
 */
.ambient-list--nav-trigger-mobile {
  @extend .accordion-trigger-style;

  @include from($desktop) {
    display: none;
  }
}

// ul
.ambient-list--navigation-wrap {
  margin: 0;
  list-style: none;
  background-color: $color-white;

  @include from($desktop) {
    margin: 0
  }
}

// li
.ambient-list--navigation-item {
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: 26px;

    @include tablet {
      margin-bottom: 0;
    }
  }

  &:first-child {
    margin-top: 20px !important;
  }

  &:last-child {
    margin-bottom: 10px;

    @include from($desktop) {
      margin-bottom: 0;
      margin-top: 50px;
    }
  }

  &:before {
    display: none;

    @include until($desktop) {
      left: 0 !important;
    }
  }

  &:hover {
    .ambient-list--navigation-item-icon {
      filter: $color-background-accent-svg-fill;
    }

    .ambient-list--navigation-item-link {
      color: $color-text-accent;
      text-decoration: underline;
    }
  }

  &.ambient-active {
    position: relative;
    @include selectedProductNav(
            $arrow-color: $color-background-accent,
            $top: 5px
    );

    @include until($desktop) {
      padding-left: 30px !important;
    }

    .ambient-list--navigation-item-icon {
      filter: $color-background-accent-svg-fill;
    }
  }

}

.ambient-list--navigation-item-link {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: $color-text-grey-light;
}

.ambient-list--navigation-item-icon {
  width: 24px;
  height: 24px;
  transition: all 200ms;

  &.all-ambients {
    width: 25px;
    height: 28px;
    margin-right: 19px;
  }
}

/*
 *** Slider
 */
.ambient-list--slider-item {
  padding-bottom: 20px;
  border-bottom: 1px solid $color-border-nav-white;
  text-decoration: none;

  &:hover {
    .ambient-list--slider-item-headline {
      text-decoration: underline;
      color: $color-text-headline;
    }
  }
}

.ambient-list--slider-item-img-wrap {
  position: relative;
  line-height: 0;
  margin-right: 20px;
}

.ambient-list--slider-item-headline {
  min-height: 30px; // For headlines in two rows
  margin: 15px 0 9px 0;
  font: $font-size-medium $font-head-semi-bold;
  line-height: $line-height-headline;
  color: $color-text-grey-light;
}

.ambient-list--slider-item-price-old {
  font-size: $font-size-x-small;
  color: $color-text-lead;
  text-decoration: line-through;
}

.ambient-list--slider-item-price-new {
  font: $font-size-medium $font-head-bold;
  color: $color-text-product-price;
}

// slider override
.ambient-list--slider {
  margin-top: 20px;

  .splide__arrow {
    top: calc(50% - 20px);
  }

  .splide__arrow--next {
    right: -5px;
    background: sassvg("arrow-right", $color-background-grey-light) no-repeat center;

    &[disabled] {
      opacity: 0.3;
    }
  }

  .splide__arrow--prev {
    left: -5px;
    background: sassvg("arrow-left", $color-background-grey-light) no-repeat center;

    &[disabled] {
      opacity: 0.3;
    }
  }
}