@import "../constants/constants";
@import "../base/mixins";

.text-with-image {
  @include until($desktop) {
    margin-right: -$container-margin;
    margin-left: -$container-margin;
    padding: 0 $container-margin;
  }

  .text-lead {
    font-size: $font-size-lead-large;
    color: inherit;
  }

  .hide-from-desktop {
    @include from($desktop) {
      display: none !important;
    }
  }

  .show-from-desktop {
    @include until($desktop) {
      display: none !important;
    }
  }

  &.gray-background {
    background: $color-background-grey-light-accent !important;
  }

  &--text {
    @include until($desktop) {
      text-align: center;
      img {
        margin-bottom: $container-margin;
      }
    }

    a:has(h2) {
      text-decoration: none;
    }

    h2 {
      margin-top: 0;
    }

    p {
      color: $color-text-grey-light;
      max-width: $desktop-large;
      font-size: $font-size-lead;

      @include from($desktop) {
        text-align: left;
      }
    }
  }

  &--toggle-container {
    max-height: 0;
    overflow: hidden;
  }

  &--see-more {
    background: sassvg("arrow-down", $color-text-accent) no-repeat center right;
    font-size: $font-size-lead;
    color: $color-text-accent;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: $container-margin;
    padding-right: $container-margin*2;
    cursor: pointer;

    hr {
      width: 100%;
      background: $color-text-accent;
      border: none;
      border-top: 1px solid $color-border-detail;
    }
  }

  &--see-more.active {
    background: sassvg("arrow-up", $color-text-accent) no-repeat center right;
  }

  &--infobox {
    display: block;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: $image-border-radius;
    text-align: center;
    font-family: $font-head-bold;
    font-size: $font-size-lead-large;

    &-1 {
      background: $color-background-red;
      color: $color-white;
    }

    &--loyalty-text {
      font-size: $font-size-copy;
      margin: 0;
    }
  }
}


.text-with-image--inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 30px 0;

  @include from($tablet) {
    max-width: $tablet - 40px;
    padding: 50px 0;
    margin: 0 auto;
  }

  @include from($desktop) {
    max-width: $desktop - 40px;
    display: grid;
    grid-template-columns: auto $xmobile;
    gap: 112px;
    align-items: flex-start;
  }

  @include from($desktop-large) {
    max-width: $desktop-large - 40px;
  }

  @include from($wide-screen) {
    max-width: $wide-screen - 40px;
  }

  @include from($ultra-wide-screen) {
    max-width: $ultra-wide-screen - 40px;
    grid-template-columns: auto $tablet - 36px;
  }

  &.reverse {
    @include from($desktop) {
      grid-template-columns: $xmobile auto;

      .text-with-image--text {
        order: -1;
      }

      .image-wrapper {
        place-items: flex-start;
      }
    }

    @include from($ultra-wide-screen) {
      grid-template-columns: $tablet - 36px auto;
    }
  }

  .image-wrapper {
    width: 100%;
    height: auto;
    line-height: 1;
    display: grid;
    place-items: center;

    @include from($desktop) {
      place-items: flex-end;
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
}

@include until($desktop) {
  #text-with-image-1385,
  #text-with-image-1389,
  #text-with-image-1428 {
    .text-with-image--text {
      text-align: left !important;
    }

    .text-with-image--text {
      display: none !important;
    }

    .show-from-desktop {
      display: block !important;
    }
  }

  #text-with-image-1390,
  #text-with-image-1386,
  #text-with-image-1426 {
    .text-with-image--text {
      text-align: left !important;
    }

    .image-wrapper {
      display: none !important;
    }
  }
}

@include from($desktop) {
  #text-with-image-1390,
  #text-with-image-1386,
  #text-with-image-1426 {
    display: none !important;
  }
}
