@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.location-detail--container {
  @extend .ce-full-width-no-margin;
  margin-top: 10px;
  @include from($tablet) {
    margin-bottom: 100px;
  }
  @include from($desktop) {
    margin-top: 30px;
  }
}

.location-detail--container-inner {
  @extend .ce-container-inner-width;
}

.location-detail--store-image-container-inner {
  display: grid;
  grid-template-columns: 1fr;
  @include from($tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.location-detail--store-image-container-inner-bottom {
  display: grid;
  grid-template-columns: 1fr;
  background-color: $color-background-grey-light-accent;
  margin: 0 -20px;
  padding: 40px 20px 90px 20px;
  @include from($tablet) {
    padding: 0;
    margin: 0;
    grid-template-columns: 1fr 1fr;
    background-color: unset;
  }
}

.location-detail--breadcrumb {
  position: relative;

  .page-back-arrow {
    position: absolute;
    left: -30px;
  }
}

.location-detail--store {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  @include from($desktop) {
    display: grid;
    grid-gap: 0 50px;
    grid-template-columns: 300px 1fr;
  }

  @include from($desktop-large) {
    width: auto;
  }

  @include between($desktop-large, $wide-screen) {
    max-width: $container-width-desktop-large;
  }

  @include from($wide-screen) {
    grid-gap: 0 130px;
    grid-template-columns: 340px 1fr;
    max-width: $container-width-widescreen;
  }

  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.location-detail--store-content {
  &-inner {
    position: relative;
  }

  @include from($tablet) {
    margin: 0 auto;
  }

  @include between($tablet, $desktop) {
    max-width: $container-width-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
  }
}


.location-detail--store-image-container {
  width: 100% !important;

  &-inner {
    @include from($desktop) {
      margin: 0 0 40px 0;
    }

    @include from($content-extended-image-breakpoint) {
      margin: 0 -100px 40px 0; // extend container to right
    }
  }

  @include from($tablet) {
    margin: 0 auto;
  }

  @include between($tablet, $desktop) {
    max-width: $container-width-tablet;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }
}

.location-detail--google-map,
.location-detail--store-image-wrap {
  margin: 0 -20px;
  @include from(600px) {
    margin: 0;
  }
}


/************** STYLE *************/
#location-detail--map {
  height: 360px;
  @include from($tablet) {
    height: 100%;
  }
}

.location-detail--store {
  &-image-wrap {
    line-height: 0;
  }

  &-image {
    @include from($desktop) {
      border-top-right-radius: $image-border-radius;
      border-bottom-right-radius: $image-border-radius;
    }
  }

  &-headline {
    margin-top: 15px;
    @include from($desktop) {
      margin-top: 40px;
    }
  }

  &-image-container-inner {
    margin-top: 20px;

    @include from($tablet) {
      margin-bottom: 40px;
    }
    @include from($desktop-large) {
      margin-top: 0;
    }
  }

  &-caption {
    position: relative;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $color-border-input;
    color: $color-text-grey-light;
    font-size: $font-size-description;
    padding-left: 20px;
    font-family: $font-copy-italic;

    &:before {
      display: inline-flex;
      position: absolute;
      width: 13px;
      height: 13px;
      left: 0;
      top: 18px;
      content: '';
      background: sassvg('ic-info', $color-background-red) no-repeat center;
      background-size: cover;
      @include from($desktop) {
        left: -25px;
        top: 27px;
      }
    }

    @include from($desktop) {
      margin-top: 25px;
      padding-left: unset;
      padding-top: 25px;
    }
  }

  &-input-city {
    margin-bottom: 10px;
  }

  &-input-address {
    margin-bottom: 35px;
  }

  &-input-city,
  &-input-address {
    border: 1px solid $color-input-border-location-detail;
    display: block;
    color: $color-text-lead;
    font-size: $font-size-lead;
    @include from($desktop-large) {
      width: 350px;
    }

    &::placeholder {
      color: $color-text-lead;
      font-size: $font-size-lead;
    }
  }

  &-input-submit {
    @include buttonBlue;

    .link-with-arrow {
      transform: translateY(-50%);
    }
  }

  &-text {
    color: $color-text-accent;
    margin-top: 20px;
    @include from($desktop) {
      margin-top: 40px;
    }
  }
}

.location-detail--store-form {
  @include from($tablet) {
    padding-right: 30px;
  }
}

.location-detail--store-directions-panel {
  margin-top: 30px;

  & > div {
    padding: 15px;
    border: 1px solid $color-border-nav-white;
    border-radius: $image-border-radius;
  }


  @include from($tablet) {
    margin-top: 0;
  }

  // Overriding default styles of google maps directions panel (classes that begin with adp)
  .adp-placemark {
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: $color-background-grey-light-accent;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    border: unset;
    margin: 0 0 20px 0;
    @include from($tablet) {
      background-color: $color-white;
    }
  }

  .adp-text {
    padding-left: 10px;
  }

  .adp-marker2 {
    display: none;
  }

  // Overriding default pin in directions panel for start point
  div[data-leg-index="0"] {
    .adp-placemark {
      &:before {
        background: url('/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/svg/pin-marker.svg');
      }
    }
  }

  .adp-placemark {
    &:before {
      display: inline-block;
      position: relative;
      top: 3px;
      width: 44px;
      height: 60px;
      content: "";
    }
  }

  // Overriding default pin in directions panel for end point
  div[data-leg-index="1"] {
    .adp-placemark {
      &:before {
        background: url('/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/svg/pin-salon.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

}

.location-detail--store-backlink-wrap {
  margin-left: 30px;
  @include linkWithArrowReverseExtend($color-text-grey-light, $color-text-grey-light, 11px);

  .location-detail--store-backlink {
    color: $color-text-accent;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include from($desktop) {
    display: none;
  }
}

.location-detail--store-form-headline {
  font-size: $font-size-h3;
  margin-top: 0;
}

.location-detail--google-map {
  border-top-left-radius: $image-border-radius;
  border-bottom-left-radius: $image-border-radius;
}
