@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.image-tag {
  @extend .ce-full-width;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.image-tag--container-inner {
  @extend .ce-container-width-no-margin;
}

/************** STYLE *************/
.image-tag--image-wrap {
  position: relative;

  // Overrides .two-columns image margins
  img {
    margin: 0 !important;
  }
}

