@import "../constants/constants";
@import "../base/mixins";

$product-detail-media-grid: 1fr 180px;
$product-detail-media-gap: 40px;
$product-detail-media-grid-mobile: 1fr 80px;
$product-detail-media-gap-mobile: 10px;
$product-detail-media-title-indent-mobile: 30px;

.product-detail {
  background-color: $color-background-grey-light-accent;
}

.product-detail--right {
  display: grid;
  background: $color-white;
  padding: 0 $container-margin 30px $container-margin;
  @include from($desktop) {
    display: block;
    padding: 20px 20px 30px $header-grid-side-padding;
    background: $color-background-grey-light-accent;
  }
}

.product-detail--messages {
  @include from($desktop) {
    margin-left: -25px; // indent because of info icon
  }
}

.product-detail--media-top {
  margin-bottom: 20px;
}

.product-detail--media-top-action {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.product-detail--media-bottom {
  @extend .product-detail--media-grid;
  margin-top: 10px;

  @include from($desktop) {
    position: relative;
  }
}

.product-detail--media-right {
  position: absolute;
  top: 0;
  right: 0;

  @include from($desktop) {
    position: relative;
    top: unset;
    right: unset;
  }

  .product--sticker-row {
    .product--sticker {
      margin-bottom: 5px;
    }

    &:last-of-type {
      .product-sticker {
        margin-bottom: 0;
      }
    }
  }
}

.product-detail--media-left {
  .product-detail--media-main {
    position: relative;

    .product--sticker-row {
      position: absolute;
      bottom: 20px;
      left: 20px;
      margin-bottom: 0;

      .product--sticker {
        position: static !important;
      }
    }
  }
}

.product-detail--breadcrumb {
  position: relative;
  padding-left: 30px;
  margin-bottom: 18px;

  @include from($desktop) {
    margin: 30px 0 20px 0;
  }
  @include from($ultra-wide-screen) {
    padding-left: 0;
  }

  .page-back-arrow {
    position: absolute;
    left: 0;

    @include from($ultra-wide-screen) {
      left: -30px;
    }
  }

  @include until($desktop) {
    .breadcrumb--item-wrap {
      .breadcrumb--item {
        &:first-child,
        &:last-child {
          display: none;
        }
      }
    }
  }
}

@include until($desktop) {
  .product-list--breadcrumb {
    .breadcrumb--item-wrap {
      .breadcrumb--item {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

/****** STYLE ******/
/****** start RIGHT SIDE start ********/
.product-detail--description {
  display: none;

  @include from($desktop) {
    display: block;
    margin-bottom: 25px;
    padding-top: 10px;
    border-top: none;
  }

  p {
    margin: 0;
  }


  a {
    color: $color-text-accent-secondary;
  }

  p:first-child {
    margin-top: 0;
  }
}

.product-detail--description-mobile {
  padding-top: 20px;
  padding-right: 30px;
  border-top: 1px solid $color-border-detail;
  position: relative;
  overflow: hidden;
  max-height: 67px; // dead panda
  transition: max-height .2s ease-in-out;

  &.toggled {
    max-height: 100%;
  }

  &--toggle {
    position: absolute;
    right: 7.5px;
    bottom: 5px;
    background: sassvg("chevron_down", $color-background-accent-secondary) no-repeat center;
    width: 12px;
    height: 12px;
    cursor: pointer;
    padding: 10px;

    .toggled & {
      background: sassvg("chevron_up", $color-background-accent-secondary) no-repeat center;
    }
  }


  @include from($desktop) {
    display: none;
  }

  p {
    margin: 0;
  }

  a {
    color: $color-text-accent-secondary;
  }

  p:first-child {
    margin-top: 0;
  }
}

.product-detail--messages {
  margin: 8px 0;

  .small-cart-message-text,
  .montage-message-text {
    font-family: $font-copy-italic;

    @include until($desktop) {
      font-size: $font-size-x-small;
      background-position-y: 0;
    }
  }
}

.product-detail--sizes--foreign {
  .product-detail--sizes-title.product-detail--sizes-title--dimensions {
    display: none;
  }

  @include until($desktop) {
    display: grid;
    //grid-template-columns: 1fr;

    @include from($xsmobile) {
      //grid-template-columns: 110px 1fr;
    }

    .product-detail--sizes-row {
      grid-template-columns: 1fr 1.5fr;
    }

    .product-detail--sizes-title.product-detail--sizes-title--dimensions {
      display: flex !important;
      align-items: flex-end;
      margin: 25px 0 15px;
      top: unset;
    }

    .product-detail--sizes--foreign--container {
      width: 100%;
      display: grid;
      //grid-template-columns: auto auto auto;
    }

    .product-detail--sizes-row {
      margin-bottom: 0 !important;
    }
  }

}

.product-detail--sizes {
  margin: 25px 0 15px 0;
  //grid-template-columns: 1fr;

  @include between($xsmobile, $desktop) {
    display: grid;
    grid-gap: 15px;
    margin-bottom: 0;
  }

  &-row {
    @extend .product-detail--table-row;

    &--large {
      //@include until($desktop) {
      //  display: grid;
      //  grid-template-columns: 1fr;
      //  gap: 15px;
      //}
    }

    &--sizes {
      display: grid;
      margin-bottom: 15px;

      //@include until($desktop) {
      //  grid-template-columns: 1fr !important;
      //}
    }

    &--dimensions {
      //@include from($desktop) {
      display: none !important;
      //}
    }
  }

  &-title {
    @extend .product-detail--table-title;

    &.product-detail--sizes-title--dimensions {
      @include until($desktop) {
        top: 9.5px;
      }
    }
  }

  &-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 60px));
    grid-gap: 5px 10px;

    img {
      width: auto;
    }
  }

  &-link {
    opacity: 0.7;
    transition: all 200ms;

    &.active {
      opacity: 1;
      box-shadow: 0 0 1px 1px $color-background-grey-light-rgba;
    }

    &:hover {
      box-shadow: 0 0 1px 1px $color-background-grey-light-rgba;
      transition: all 200ms;
    }
  }

  &-icon {
    width: auto;
    max-width: 40px;
    padding: 0 4px 4px 0;
  }

}

.product-detail--single-benefit {
  display: flex;
  margin: 0;
  padding: 2px 10px 2px 2px;
  line-height: 1.2;
  align-items: center;
  background: $color-background-detail-price;
  border-radius: 5px;
  width: calc(50% - 10px);
  font-size: $font-size-medium;

  @include until($tablet) {
    line-height: 1.1;
  }

  @include from($desktop) {
    padding: 4px 10px 4px 2px;
    font-size: $font-size-copy;
  }

  img {
    margin-right: 10px;
  }

  .product-detail--sizes-icon {
    padding: 4px !important;
  }
}

.benefits-row {
  @include until($tablet) {
    padding-top: $container-margin/2;
    margin-bottom: $container-margin/2 !important;
  }

  @include until($desktop) {
    order: -1;
    padding-top: $container-margin;
  }
}

.product-detail--sizes-data--benefits {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include until($tablet) {
    gap: 5px;
    padding-bottom: $container-margin/2;
  }

  @include until($desktop) {
    order: -1;
    padding-bottom: $container-margin;
  }

}

.product-detail--dimensions-table {
  width: 100%;
  margin: 25px 0 30px 0;
  table-layout: fixed;

  @include until($desktop) {
    max-width: 450px;
  }

  th {
    top: unset !important;
    padding-bottom: 5px;
    font-size: 9px; // unique value, specific fix
    line-height: $line-height-reduced;
    font-family: $font-copy-semi-bold;
    color: $color-text-lead;
    vertical-align: bottom;
  }

  td {
    padding: 3px 0;
    font-size: $font-size-medium;
  }

  &-title {
    font-family: $font-copy-semi-bold;
    text-transform: uppercase;
    font-size: $font-size-x-small !important;
  }

  .product-detail--dimensions-table-title {
    @extend .product-detail--table-title;
    top: 2px !important;
    width: 122px;
  }
}

.product-detail--declaration {
  margin-top: 20px;
  padding-top: $container-margin;
  border-top: 1px solid $color-background-cart-grey;

  &.has-iframe-after {
    padding-bottom: $container-margin;
    border-bottom: 1px solid $color-background-cart-grey;
  }

  &-trigger {
    display: inline-block;
    padding-right: 20px;
    color: $color-text-accent-secondary;
    text-decoration: underline;
    background: sassvg("chevron_down", $color-background-accent-secondary) no-repeat 20px;
    background-position: 100% 10px;
    cursor: pointer;
    transition: all 200ms;

    &.accordion-active {
      transition: all 200ms;
      background: sassvg("chevron_up", $color-background-accent-secondary) no-repeat 20px;
      background-position: 100% 10px;
    }
  }

  &-content-inner {
    padding: 20px 0 0 0;
  }

  &-row {
    @extend .product-detail--table-row;
  }

  &-title {
    @extend .product-detail--table-title;
  }

}

.product-detail--no-discount {
  color: $color-text-grey-light !important;
  margin-bottom: 15px;
}

.product-detail--cart {
  padding: 30px 20px 45px $header-grid-side-padding;
  margin-left: -$header-grid-side-padding; // indent because of grey background
  margin-right: -20px; // indent because of grey background
  background: $color-background-detail-price;

  .product-list--item-info--discount {
    color: $color-text-red;
  }

  &-old {
    margin-bottom: 10px;
    line-height: 1; // used for reducing space between old and new price
    font-size: 16px;
    color: $color-text-lead;

    span {
      text-decoration: line-through;
    }

    small {
      text-decoration: line-through;
      font-size: 16px;
    }
  }

  &-new {
    line-height: 1; // used for reducing space between old and new price
    font-size: 28px;
    color: $color-text-product-price;
    font-family: $font-copy-bold;

    small {
      font-size: 16px;
    }

    &.product-detail-loyalty {
      color: $color-text-accent-secondary;


      &.product-detail-package-price {
        display: inline-block;
        font-size: 20px;
        margin-top: 6.5px;

        small {
          font-size: 16px;
        }
      }
    }
  }

  &-action {
    font-size: $font-size-small;
    color: $color-text-product-price;

    &.product-detail-loyalty {
      color: $color-text-accent-secondary;
      margin-bottom: 30px;

      .product-list--item-info--discount {
        color: $color-text-accent-secondary;
      }

      &--no-margin {
        margin-bottom: 10px;
      }
    }

    p {
      margin-top: 0;
    }
  }

  &-price {
    margin-bottom: 30px;

    .product-detail--cart-price--wrapper--left & {
      margin-bottom: 0;
    }
  }

  &-add {
    //position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 110px auto;
    grid-gap: 10px;
    justify-content: space-between;
    padding: 10px $container-margin;
    background-color: $color-white;
    z-index: 2;

    @include from($mobile) {
      justify-content: center;
      grid-gap: 20px;
    }

    @include from($desktop) {
      position: relative;
      bottom: unset;
      left: unset;
      right: unset;
      grid-template-columns: 120px 1fr;
      grid-gap: 10px;
      margin: 30px 0 20px 0;
      padding: 0;
      background-color: transparent;
    }

    @include from($desktop-large) {
      grid-gap: 20px;
    }
  }

  &-saloons {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 40px 15px 40px;
    text-decoration: none;
    color: $color-white;
    background-color: $color-background-grey-light;

    @include until($tablet) {
      line-height: 1.2;
    }
  }

  //&-action {
  //  margin-bottom: 20px;
  //}

  $productDetailBuyHeight: 52px; // height of a buy and quantity button

  &-quantity {
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    height: $productDetailBuyHeight;
    background-color: $color-white;
    border: 1px solid $color-background-icons;
    border-radius: 2px;

    &-number {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-text-grey-light;
      font-size: $font-size-lead;
      font-family: $font-copy-bold;
    }

    &-plus {
      background: sassvg("ic-cart-plus", $color-background-grey-light) no-repeat center;
    }

    &-minus {
      background: sassvg("ic-cart-minus", $color-background-grey-light) no-repeat center;
    }
  }

  &-buy {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: $productDetailBuyHeight;
    padding: 5px 60px 5px 30px;
    background: $color-background-accent sassvg("cart-icon", $color-white) no-repeat center right 30px;
    border-radius: $btn-border-radius;
    color: $color-white;
    font-size: $font-size-description;
    line-height: $line-height-title-description;
    text-decoration: none;

    @include from($tablet) {
      font-size: $font-size-copy;
    }

    @include desktop() {
      padding: 5px 40px 5px 10px;
      background-position: center right 10px;
    }

    @include from($desktop-large) {
      padding: 5px 70px 5px 40px;
    }
  }
}

.product-detail--cart-add--package {
  @include until($tablet) {
    span {
      transition: all .5s;
      position: fixed;
      z-index: 1;
      bottom: 70px;
      left: 0;
      right: 0;
      padding: 10px 20px;
      background: #fff;
    }
  }
}

.product-detail--cart-dimensions {
  margin-bottom: 20px;

  &-title {
    @extend .product-detail--table-title;
    top: 0 !important;
  }

  &-select {
    width: auto;
    min-width: 250px;
  }
}

.product-detail--links {
  margin-top: 20px;
  padding: 20px 0 10px 0;
  border-top: 1px solid $color-border-detail;

  .text {
    padding: 0;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin-top: 0;
    }
  }
}

.product-detail--link {
  &-wrap {
    margin-bottom: 10px;
  }
}

.product-detail--ambient {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-gap: 20px;
  padding: 30px 0;
  border-top: 1px solid $color-border-detail;
  color: $color-text-base;

  &.mobile {
    margin-top: 40px;
    border-bottom: 1px solid $color-border-detail;
  }

  &-link {
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .product-detail--ambient-highlight {
        text-decoration: underline;
      }
    }
  }

  &-highlight {
    color: $color-text-accent;
    font-family: $font-copy-semi-bold;
  }

  &-content {
    display: grid;
    align-content: space-between;
  }

  &-arrow {
    @include linkWithArrowExtend($top: 5px);
    font-size: $font-size-medium;
    line-height: 1;
  }

  &-image {
    img {
      border-radius: 4px;
    }
  }

  &-text {
    line-height: 1.4;
  }
}

/****** END right side END ********/

/****** start LEFT SIDE start ********/
.product-detail--compare-container {
  min-height: 44px;
}

.product-detail--compare-wrap {
  text-align: right;
  line-height: 1;

  label {
    font-size: 9px;
    text-transform: uppercase;
    color: $color-text-label;
  }

  .checkbox-container {
    margin-bottom: 5px;
  }
}

.product-detail--compare {
  &-result {
    text-align: right;
  }

  &-link {
    font-size: $font-size-small;
  }
}

.product-detail--title {
  margin: 0 0 10px 0;
  font-family: $font-copy-bold;

  @include until($desktop) {
    position: relative;
    padding-left: $product-detail-media-title-indent-mobile;
    font-size: $font-size-h3;
  }

}

.product-detail--id {
  padding-left: $product-detail-media-title-indent-mobile;
  font-size: $font-size-description;

  @include from($desktop) {
    padding-left: 0;
    font-size: $font-size-copy;
  }
}

.product-detail--media-main {
  text-align: left;
}

.product-detail--media-icon {
  width: 50px;

  @include from($mobile) {
    width: 80px;
  }

  img {
    border-radius: $image-border-radius;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin: 15px 0;
  }

  &.icon-image {
    background: sassvg("tmb-image-small", $color-background-icons-product) no-repeat center;
  }

  &.icon-video {
    background: sassvg("tmb-video-small", $color-background-icons-product) no-repeat center;
  }

  &.thumbnail-rotate {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      background: sassvg("ic-360", $color-background-accent) no-repeat center;
    }
  }

  &.thumbnail-animation {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      background: url('/source/svg/youtube-detail.svg') no-repeat center;
    }
  }
}

.product-detail--media-thumbnail {
  position: relative;
  border: 1px solid $color-background-grey-light-accent;
  transition: all 200ms;
  cursor: pointer;
  line-height: 0;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
  }

  &-wrap {
    display: none;

    @include from($desktop) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(80px, 100px));
      grid-gap: 10px;
      margin-top: 20px;
    }
  }

  &.thumbnail-image {
    background: sassvg("tmb-image-small", $color-background-icons) no-repeat center;
  }

  &.thumbnail-video {
    &:after {
      background: sassvg("ic-video-montaza", $color-white) no-repeat center;
    }
  }

  &.thumbnail-rotate {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      background: sassvg("ic-360", $color-background-accent) no-repeat center;
    }

    img {
      width: auto;
      height: 72px;
    }
  }

  &.thumbnail-animation {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      background: url('/source/svg/youtube-detail.svg') no-repeat center;
    }

    img {
      width: auto;
      height: 72px;
    }
  }

  &:hover {
    &:after {
      transition: all 200ms;
      background-color: $color-background-image-hover;
    }
  }
}

.product-detail--media-montage-link {
  display: inline-flex !important; // override lightbox style
  align-items: center;
  min-height: 30px; // height of video icon
  padding-left: 45px;
  background: sassvg("ic-video-montaza", $color-background-accent) no-repeat center left;
  color: $color-text-grey-light;

  &-wrap {
    margin-top: 15px;
    text-align: center;

    @include from($desktop) {
      text-align: left;
    }
  }
}

.product-detail--media-montage-now {
  background-color: $color-white;
  padding: 5px 20px;
  cursor: pointer;
}

.product-detail--back-link {
  position: absolute;
  left: -4px;
  top: 7px;
  display: block;
  width: 22px;
  height: 18px;
  background: sassvg("arrow-back") no-repeat center;
}

.product-detail--popup360 {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zIndex360 + 1;

  &.animate-show-element {
    display: flex !important;
  }
}

.product-detail--popup360-close {
  position: fixed;
  top: 5px;
  right: 5px;
  background: sassvg("ic-close", $color-white) no-repeat center, #2A2A2A;
  height: 40px;
  width: 40px;
}

.product-detail--popup360-image {
  margin: auto;
  max-width: 800px;
}

/****** END left side END ********/

/***** shared classes ****/
.product-detail--table-row {
  display: grid;
  grid-template-columns: 1fr 1.5fr !important;
  grid-gap: 15px;

  @include until($xsmobile) {
    grid-template-columns: 110px 1fr;
  }

  @include from($xsmobile) {
    align-content: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.product-detail--table-title {
  font-family: $font-copy-semi-bold;
  text-transform: uppercase;
  color: $color-text-accent;
  position: relative;
  // Fix aligning of td and th
  top: 5px;
  font-size: $font-size-x-small;
  font-weight: normal;
}

.product-detail--table-data {
  font-size: $font-size-description;
  line-height: $line-height-title-description;
}

.product-detail--media-grid {
  display: grid;
  grid-template-columns: $product-detail-media-grid-mobile;
  grid-gap: $product-detail-media-gap-mobile;

  @include from($desktop) {
    grid-template-columns: 1fr 80px;
    grid-gap: $product-detail-media-gap;
  }

  @include from($desktop-large) {
    grid-template-columns: $product-detail-media-grid;
    grid-gap: $product-detail-media-gap;
  }

  &.product-detail--images {
    @include until($desktop) {
      position: relative;
      display: block;
    }
  }
}

// Dynamically added class
.product-detail--active-design {
  border: 1px solid $color-border-nav-grey;
}

.product-detail--recommendation-wrap {
  margin-top: 25px;
  @include from($desktop) {
    margin: 20px 0 30px 0;
  }
}

.product-detail--additionaldescription-wrap {
  margin-top: 25px;
  font-weight: 700;
  @include from($desktop) {
    margin: 20px 0 30px 0;
  }
}

.product-detail--cart-price--wrapper--two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  .product-list--item-label {
    display: inline-block;
    margin-bottom: 20px;
  }

  .product-detail--cart-price--wrapper--left {
    order: 2;

    .product-detail--cart-new {
      display: inline-block;
      margin-top: 7px;
      font-size: 20px;
      color: $color-text-product-price;

      small {
        font-size: 16px;
      }
    }

    .product-detail--cart-new.product-detail-loyalty,
    .product-detail--cart-action {
      display: none;
    }
  }

  .product-detail--cart-price--wrapper--right {
    order: 1;

    .product-detail--cart-new {
      @include until($desktop) {
        font-size: 28px;
      }
    }
  }
}

.product-detail--scroll-element {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: $color-white;
  padding: $container-margin;
  transition: top 0.5s ease-in-out;
  box-shadow: 0 0 0 2px $color-background-cart-grey;

  &--two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $container-margin;
  }

  .product-detail--cart-price {
    margin: 0;
    display: grid;
    place-items: center flex-start;
  }

  .product-list--item-title {
    height: auto;
    margin-top: 0;
    margin-bottom: $container-margin/2;
    font-size: $font-size-slider-badge-title;
  }

  .product-detail--cart-new {
    font-size: $font-size-slider-badge-title;
  }

  .product-detail--cart-add {
    align-content: center;
    padding: 0;
    grid-template-columns: 1fr;
    place-items: center flex-end;

    @include until($xsmobile) {
      padding: 0;
    }
  }

  .product-detail--cart-buy {
    white-space: nowrap;
    font-size: 14px;
  }

  &.visible {
    top: 0;
  }

  @include from($desktop) {
    display: none;
  }
}


/** 360 viewer **/
#jsv-holder {
  width: 500px;
}

#jsv-holder img {
  width: 100%;
}

// Counter until the end of the action
.product-detail--price-wrapper-with-counter {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.product-detail--counter-container {
  width: fit-content;
  
  &--margin {
    opacity: 0;
    height: 0;
    margin-bottom: 0;
    transition: opacity 0.1s, height 0.3s 0.01s ease-out, margin-bottom 0.3s ease-out;

    .product-detail--animate-counter & {
      opacity: 1;
      height: 34px;
      margin-bottom: 30px;
      overflow: hidden;

      @include between($tablet, $desktop) {
        height: 46px;
      }

      @include from($ultra-wide-screen) {
        height: 46px;
      }
    }
  }
}

.product-detail--counter-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
}

.product-detail--counter-time-box {
  background: #f0f0f0;
  padding: 4px;
  display: grid;
  place-items: center;
  aspect-ratio: 1;

  @include between($desktop, $desktop-large) {
    padding: 2px;
  }

  @include until($xsmobile) {
    padding: 2px;
  }
}

.product-detail--counter-time-box--time {
  color: $color-text-accent-secondary;
  font-size: $font-size-header-nav;
  font-family: $font-copy-bold;

  @include between($tablet, $desktop) {
    font-size: $font-size-product-list-price;
  }

  @include from($ultra-wide-screen) {
    font-size: $font-size-product-list-price;
  }
}

.product-detail--counter-time-box--label {
  font-size: $font-size-product-old;

  @include between($tablet, $desktop) {
    font-size: $font-size-header-nav-sub-mobile;
  }

  @include from($ultra-wide-screen) {
    font-size: $font-size-header-nav-sub-mobile;
  }
}

.product-detail--counter-time-box--time,
.product-detail--counter-time-box--label {
  line-height: 1;
}