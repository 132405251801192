@import "../constants/constants";
@import "../base/mixins";

.product-group-irre--container {
  @extend .ce-full-width;
  // Remove margin top if in product list
  .product-list--item-container & {
    margin-top: 0;
  }
}

.product-list--item-container {
  margin-bottom: $ce-margin-vertical / 2;

  position: relative;

  @include until($tablet) {
    display: grid;
  }

  @include until($desktop) {
    min-height: 300px;
  }


  .product-list--loader-wrapper {
    width: 100%;
    height: 300px;
    background: transparent !important;
    position: absolute;
    left: 0;
    top: 80px;
    display: grid;
    place-items: center;

    @include until($desktop) {
      top: 0;
    }

    &.loaded {
      display: none !important;
    }
  }

  .product-list--loader-container {
    width: 75px;
    height: 75px;
    background: $color-white;
    border-radius: 10px;
    display: grid;
    place-items: center;

    @include until($tablet) {
      background: transparent;
    }

    .loaded & {
      display: none;
    }
  }

  .product-list--loader {
    width: 75px;
    height: 75px;
    background: sassvg("loader", $color-text-accent) no-repeat center center;
    animation: a 1s linear infinite;

    .loaded & {
      animation: none;
    }
  }
}

.product-group-irre--container-inner {
  @extend .ce-container-inner-width;
}

.product-group-irre--item-wrap {
  display: grid;
  grid-template-columns: 330px;
  grid-gap: 40px;
  justify-content: center;

  @include from($tablet) {
    grid-template-columns: repeat(2, 310px);
    grid-gap: 70px;
    justify-content: left;
  }
  @include from($desktop) {
    grid-template-columns: repeat(3, 310px);
    grid-gap: 30px;
  }
  @include from($desktop-large) {
    grid-gap: 70px;
  }
  @include from($wide-screen) {
    grid-template-columns: repeat(4, 300px);
    grid-gap: 40px;
  }
  @include from($ultra-wide-screen) {
    grid-template-columns: repeat(4, 310px);
    grid-gap: 60px;
  }
  @include from(1600px) {
    grid-gap: 66px;
  }
}


/************ STYLE ***********/
.product-group-irre--item {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-border-nav-grey;

  &:hover {
    .product-group-irre--item-color-wrap,
    .product-group-irre--item-link {
      transition: opacity 0.3s;
      opacity: 1;
    }
  }
}

.product-group-irre--item-image-wrap {
  position: relative;
  margin-bottom: 15px;
  padding-right: 50px;
}

.product-group-irre--item-title {
  margin-top: 0;
  margin-bottom: 10px;

  & > a {
    color: unset;
    text-decoration: none;
  }
}

.product-group-irre--item-link {
  @include linkWithArrowExtend;
  padding-right: 40px;

  .link-with-arrow {
    left: calc(100% - 40px);
  }
}

.product-group-irre--item-price {
  margin-bottom: 5px;
  color: $color-text-product-price;
  font-size: 24px;
  font-family: $font-copy-semi-bold;
  //font-weight: bold;
}

.product-group-irre--item-description {
  margin-bottom: 20px;
  color: $color-text-lead;
  font-size: 16px;
  //font-weight: bold;
  font-family: $font-copy-semi-bold;
}

.product-group-irre--item-link {
  @include from($desktop) {
    opacity: 0;
  }
}

// product stickers
.product-group-irre--item-stickers {
  position: absolute;
  top: 0;
  right: 0;
}

.product-group-irre--item-sticker {
  display: inline-block;
  width: 41px;
  height: 47px;

  &-row {
    margin-bottom: 10px;
    text-align: right;
  }
}

// product colors
.product-group-irre--item-color-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.product-group-irre--item-color {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 2px 3px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  transition: all .5s;
  background-position: 50% !important;

  &.small-filter-color {
    width: 20px;
    height: 20px;
  }
}