@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.statement--container-inner {
  margin-bottom: 30px;

  .bordered-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #E2E2E2;
  }
}

/************** STYLE *************/
.statement--button {
  @include buttonBlue;

  .link-with-arrow {
    transform: translateY(-50%);
  }
}


.statement--container-show {
  margin-bottom: 20px;
}

.statement--caption-large {
  margin-top: 15px;
}

.statement--headline {
  margin-top: 0;
}

.statement-container-hidden.animate-show-element {
  display: block;
}


.register-statement-city-newsletter {
  display: none;
}

// Adds new newsletter date on newsletter page
#statement-527 {

  //old date
  .register-statement-city {
    display: none;
  }

  //new newsletter date
  .register-statement-city-newsletter {
    display: inline;
  }
}




