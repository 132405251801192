@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/

// CE grid with grey background
@include greyBackgroundOption(
        $div-container-class: "blog-teaser-small--container",
        $div-inner-class: "blog-teaser-small--container-inner"
);

.blog-teaser-small--item-wrap {
  @include from($desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}


/************** STYLE *************/
.blog-teaser-small--headline {
  margin: 10px 0 30px 0;
}

.blog-teaser-small--subheadline {
  font-family: $font-head-bold;
  font-size: $font-size-h4;
  color: $color-text-accent;
}

.blog-teaser-small--item {
  position: relative;
  display: block;
  padding-bottom: 50px;
  background-color: $color-white;
  text-decoration: none;
  color: unset;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @include from($desktop) {
    margin-bottom: 0 !important;
  }

  &:hover {
    .blog-teaser-small--item-title {
      text-decoration: underline;
    }
  }
}

.blog-teaser-small--item-title {
  margin-top: 20px;
}

.blog-teaser-small--item-link {
  @include linkWithArrowExtend(
          $arrowColor: $color-text-accent,
          $top: 11px
  );
  display: inline-block;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.blog-teaser-small--item-image {
  border-radius: 4px;
}

.blog-teaser-small--item-content {
  padding: 20px;
  background-color: $color-white;
}

.blog-teaser-small--item-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  margin-bottom: 15px;
  justify-content: left;
  color: $color-text-cart-description;
  font-size: $font-size-small;
}

.blog-teaser-small--item-tag {
  position: relative;
  padding-left: 20px;

  &:before {
    position: absolute;
    height: 11px;
    width: 11px;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.tag-yellow {
    &:before {
      background-color: $color-background-yellow;
    }
  }

  &.tag-red {
    &:before {
      background-color: $color-background-red;
    }
  }

  &.tag-green {
    &:before {
      background-color: $color-background-green;
    }
  }
}
