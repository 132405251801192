@font-face {
  font-family: 'MulishRegular';
  font-display: swap;
  src: url('/assets/font/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishRegularItalic';
  font-display: swap;
  src: url('/assets/font/Mulish-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishSemiBold';
  font-display: swap;
  src: url('/assets/font/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishLight';
  font-display: swap;
  src: url('/assets/font/Mulish-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishBold';
  font-display: swap;
  src: url('/assets/font/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'StickerFont';
  font-display: swap;
  src: url('/assets/font/Geometr.ttf') format('truetype');
}


// Main Container widths
// We need these values to add space left and right of items which don't have 100% width

// Breakpoints
$xsmobile: 380px;
$xmobile: 480px;
$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;
$desktop-large: 1200px;
$wide-screen: 1366px;
$ultra-wide-screen: 1480px;
$popup-cart-breakpoint-large: 1550px;
$popup-cart-breakpoint-wide: 1650px;
$content-extended-image-breakpoint: 1655px;
$slider-max-width-breakpoint: 1920px;


// Margins and paddings
$container-margin: 20px; // Used for side margins, padding

$ce-margin-vertical: 80px; // used for top and bottom margin
$ce-margin-vertical-tablet: 80px; // used for top and bottom margin
$ce-margin-vertical-desktop: 100px; // used for top and bottom margin
$ce-margin-vertical-desktop-large: 100px; // used for top and bottom margin
$ce-margin-vertical-widescreen: 100px; // used for top and bottom margin
$headline-margin: 40px 0 20px 0;
$two-columns-header-margin-top: 30px;


$ce-padding-vertical: 50px; // Padding e.g. for Items with background colors


// Tablet view, should be at least less than $tablet + 2 * $container-margin
$container-width-tablet: $tablet - 2 * $container-margin;

// Desktop view, should be at least less than $desktop + 2 * $container-margin
$container-width-desktop: $desktop - 2 * $container-margin;

// Desktop large view, should be at least less than $desktop-large + 2 * $container-margin
$container-width-desktop-large: $desktop-large - 2 * $container-margin;

// Widescreen view, should be at least less than $wide-screen + 2 * $container-margin
$container-width-widescreen: $wide-screen - 2 * $container-margin;

// Widescreen view, should be at least less than $wide-screen + 2 * $container-margin
$container-width-ultra-widescreen: $ultra-wide-screen - 2 * $container-margin;

// Fonts
$font-head: 'MulishRegular', Trebuchet, Geneva, Arial, sans-serif;
$font-head-light: 'MulishLight', Trebuchet, Geneva, Arial, sans-serif;
$font-head-semi-bold: 'MulishSemiBold', Trebuchet, Geneva, Arial, sans-serif;
$font-head-bold: 'MulishBold', Trebuchet, Geneva, Arial, sans-serif;
$font-copy: 'MulishRegular', Trebuchet, Geneva, Arial, sans-serif;
$font-copy-italic: 'MulishRegularItalic', Trebuchet, Geneva, Arial, sans-serif;
$font-copy-light: 'MulishLight', Trebuchet, Geneva, Arial, sans-serif;
$font-copy-semi-bold: 'MulishSemiBold', Trebuchet, Geneva, Arial, sans-serif;
$font-copy-bold: 'MulishBold', Trebuchet, Geneva, Arial, sans-serif;
$font-sticker: 'StickerFont', Trebuchet, Geneva, Arial, sans-serif;

// Fonts sizes
$font-size-copy: 14px;
$font-size-lead: 16px;
$font-size-lead-medium: 18px;
$font-size-lead-large: 20px;

$font-size-h1: 54px;
$font-size-h2: 36px;
$font-size-h3: 26px;
$font-size-h4: 18px;

$font-size-h2-mobile: 26px;
$font-size-h3-mobile: 18px;

$font-size-x-small: 10px;
$font-size-product-old: 10px;
$font-size-sub-headline: 10px;
$font-size-small-uppercase: 11px;
$font-size-small: 11px;
$font-size-error-input: 11px;
$font-size-description: 12px;
$font-size-medium: 12px;
$font-size-product-title: 12px;
$font-size-header-nav-sub-mobile: 14px;
$font-size-header-nav: 16px;
$font-size-header-nav-mobile: 18px;
$font-size-product-list-price: 24px;
$font-size-slider-badge-title: 20px;
$font-size-slider-teaser-title: 48px;
$font-size-b2b-detail-price: 30px;

$line-height: 1.6;
$line-height-headline: 1.2;
$line-height-title-description: 1.2;
$line-height-reduced: 1.2;

// Main colors
$color-white: #fff;
$color-black: #000;

// Text colors
$color-text-base: #333333; // Main Text color
$color-text-headline: #000000; // Headline text color
$color-text-lead: #4d4d4d; // Lead text color
$color-text-accent: #263c75; // Main Base accent color
$color-text-accent-secondary: #098edc; // Main Secondary accent color
$color-text-grey-light: #707070;
$color-text-header-products-nav: #A2B4E1;
$color-text-header-input-placeholder: #A2B4E1;
$color-text-cart-description: #bababa;
$color-text-search-suggestion: #bababa;
$color-text-product-price: #F61A2F;
$color-text-red: #F61A2F;
// Article list new headline and line
$color-text-green: #b1d334;
$color-text-green-accent: #24B200;
$color-text-main-nav: #d8d8d8;
$color-text-label: #575757;
$color-text-product-discount: #777;


// Background colors
$color-background-accent: #263c75;
$color-background-accent-opacity: rgba(38, 60, 117, 0.7); //#263c75 with 0.7 opacity
$color-background-accent-secondary: #098edc;
$color-background-yellow: #FFD20A;
$color-background-green: #b1d334;
$color-background-red: #fe0000;
$color-background-red-accent: #ec0000;
$color-background-footer: #a2b4e1;
$color-background-grey-dark: #333333;
$color-background-grey: #4d4d4d;
$color-background-grey-light: #707070;
$color-background-grey-light-rgba: rgba(112, 112, 112, 1); //#707070
$color-background-grey-light-accent: #F0F0F0;
$color-background-grey-light-ultra: #F8F8F8;
$color-background-grey-light-accent-rgba: rgba(240, 240, 240, 0.4); // #F0F0F0 with 0.4 opacity
$color-background-purple: #425A98;
$color-background-checkout-light-grey: #f5f5f5;
$color-background-popup: rgba(0, 0, 0, 0.8);
$color-background-footer-bottom: #172959;
$color-background-main-nav-active: #3D5A95;
$color-background-icons: #d8d8d8;
$color-background-banner-icon: #BABABA;
$color-background-steps-shop-tutorial: #d8d8d8;
$color-background-slider-arrow-grey: #bababa;
$color-background-slider-dots-mobile: #bababa;
// Sticky vertical line from news list default
$color-background-vertical-line: #bababa;
$color-background-icons-product: #bababa;
$color-background-icons-product-svg-fill: invert(87%) sepia(0%) saturate(4%) hue-rotate(201deg) brightness(88%) contrast(85%);
$color-background-detail-price: #E6E6E6;
$color-background-cart-grey: #d7d7d7;
$color-background-image-hover: rgba(38, 60, 117, 0.5);
$color-background-white-transparent: rgba(255, 255, 255, 0.6);
$color-background-accent-svg-fill: invert(21%) sepia(47%) saturate(1108%) hue-rotate(191deg) brightness(75%) contrast(95%); //#263c75
$color-background-autocomplete-item-active: #2288ea;
$color-background-star-rates: #F1AE29;


// Borders
$color-border-nav-blue: rgba(66, 90, 152, 0.5);
$color-border-nav-white: #F0F0F0;
$color-border-nav-grey: rgba(186, 186, 186, 1); // #bababa
$color-border-divider: #425A98;
$color-border-socials: #3D5A95;
$color-border-filter: #d8d8d8;
$color-border-map-divider: #d9d9d9;
$color-border-steps-divider: #d9d9d9;
$color-border-filter-range: #bababa;
$color-border-input: #bababa;
$color-border-detail: #d7d7d7;
$ce-accordion-border-top: #e9e9e9;


// Inputs
$color-background-header-search: #425A98;
$color-input-text-error: #FF001D;
$color-input-border-error: #FF001D;
$color-input-background-grey: #F0F0F0;
$color-input-border-grey: #bababa;
$color-input-border-default: #bababa;
$color-input-form-border-default: #d9d9d9;
$color-input-border-location-detail: #e6e6e6;

// scroll
$color-scroll-rail: #fafafa;
$color-scroll-handler: #c1c1c1;


// Header
$header-grid-products-grid: 260px auto;
$header-grid-products-grid-wide: 300px auto;
$header-grid-main: 67% 33%;
$header-grid-main-wide: 970px 470px;
$header-bottom-nav-vertical-padding: 17px;
$header-nav-mobile-link-arrow-width: 50px;
$header-nav-mobile-li-decor-width: 20px;
$header-grid-side-padding: 50px;
$header-header-height: 145px;
$header-header-top-height: 90px;
$header-header-height-mobile: 70px;
$text-decor-vertical-line-indent: 15px;
$popup-grey-background-indent: 40px;
$popup-indent-if-headline-decor: 40px;
$popup-indent-right-desktop: 40px;
$popup-indent-grid: 90px;
$header-left-margin-for-popup: 30px;
$header-left-indent-right: 80px;
$form-input-margin-bottom: 25px;
$filter-color-size: 30px;

// Border radius
$image-border-radius: 4px;
$btn-border-radius: 2px;

// z index
$zIndex360: 100;

// Product list image right indent
$imagePaddingRightIndent: 0;

//for horizontal scroll - Scrollbars
$color-scrollbar-background: #333;
