$int-debugger-color-mobile-small: #8BC349;
$int-debugger-color-mobile: #249B23;
$int-debugger-color-tablet: #E51C22;
$int-debugger-color-desktop: #FF9800;
$int-debugger-color-large: #04A9f3;
$int-debugger-color-wide: #3f51B6;
$int-debugger-color-ultra-wide: #683AB7;


.int-debugger {
  &-small-mobile {
    display: none;
    @include between(0px, 320px) {
      display: inline-block;
    }
  }

  &-mobile {
    display: none;
    @include between(321px, 767px) {
      display: inline-block;
    }
  }

  &-tablet {
    display: none;
    @include tablet() {
      display: inline-block;
    }
  }

  &-desktop {
    display: none;
    @include desktop() {
      display: inline-block;
    }
  }

  &-large {
    display: none;
    @include desktopLarge() {
      display: inline-block;
    }
  }

  &-wide {
    display: none;
    @include between(1367px, 1600px) {
      display: inline-block;
    }
  }

  &-ultra-wide {
    display: none;
    @include from($ultra-wide-screen) {
      display: inline-block;
    }
  }

  &-viewport {
    margin-left: 12px;
  }

  &-box {
    display: flex;
    white-space: nowrap;
    position: fixed;
    color: #fff;
    font-size: 14px;
    right: 5px;
    bottom: 0;
    padding: 2px 15px 1px;
    border-radius: 5px 5px 0 0;
    z-index: 2;

    @include until(320px) {
      background-color: $int-debugger-color-mobile-small;
    }
    //Small mobile
    @include from(320px) {
      background-color: $int-debugger-color-mobile;
    }
    @include from($tablet) {
      background-color: $int-debugger-color-tablet;
    }
    @include from($desktop) {
      background-color: $int-debugger-color-desktop;
    }
    @include from($desktop-large) {
      background-color: $int-debugger-color-large;
    }
    @include from($wide-screen) {
      background-color: $int-debugger-color-wide;
    }
    @include from($ultra-wide-screen) {
      background-color: $int-debugger-color-ultra-wide;
    }
  }

  &-class-name-first-level {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 999999;
    border: none;
    background: darkgray;
    color: white;
    padding: 2px 10px !important;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 0, 0, 0.2);
    }
  }

}

.location-list-map {
  width: 100%;
  height: 100vh;
}