/**** override splider defaults */
.splide {
  overflow: hidden;

  button {
    svg {
      display: none;
    }
  }
}

.splide__slide {
  padding-left: 0;
  user-select: auto !important; // makes text selectable

  &:before {
    display: none;
  }
}

.splide__pagination {
  li:before {
    display: none;
  }
}

.splide--nav {
  .splide__slide {
    border: none !important;
  }
}

