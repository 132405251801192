@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.image-gallery--item-wrap {
  display: grid;
  margin: 30px 0;
  grid-gap: 20px;
  grid-template-columns: 1fr;


  @include between(500px, $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include from($tablet) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }

}

/************** STYLE *************/
.image-gallery--item {
  text-align: center;

  &.pointer {
    cursor: pointer;
  }
}

.image-gallery--item-content {
  position: relative;
  transition: all .4s;

  &:hover {
    cursor: pointer;
    // Hover state for image hovering
    .image-gallery--item-overlay {
      opacity: 0.2;
    }

    &:after {
      opacity: 1;
    }

  }

  // Magnify icon
  &:after {
    position: absolute;
    display: block;
    transition: all .4s;
    opacity: 0;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: sassvg('ZoomRegular', $color-white) center no-repeat;
  }

  img {
    margin: 0;
    object-fit: cover;
  }
}

.image-gallery--default {
  height: 300px;
  @include between($desktop, $desktop-large) {
    height: 230px;
  }
  @include from($desktop-large) {
    height: 300px;
  }
}

.image-gallery--description {
  font-size: $font-size-lead;
  line-height: $line-height;
  display: grid;
  margin-top: -8px;
  padding-top: 15px;
  color: $color-text-accent-secondary;
}

.image-gallery--item-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.image-gallery--item-video {
  position: relative;
}

.image-gallery--item-link {
  display: inline-block;
  line-height: 0;
}


// Hover overlay for image
.image-gallery--item-overlay {
  transition: all .4s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-background-accent;
}

// Ce Image, image
// Overriding global css for img on right column
.two-columns--right-bottom .image--content-image {
  margin: 0;
}

.image--content-image,
.image-gallery--item-image {
  border: 1px solid $color-border-nav-white;
  border-radius: $image-border-radius;
}

.image-gallery--item-overlay {
  border-radius: $image-border-radius;
}