@import "../constants/constants";
@import "../base/mixins";

$color-border-active-thumbnail: #098edc;

/************* GRID ***************/
.news-detail--container {
  @extend .ce-full-width-no-margin;
  margin-top: 30px;
  margin-bottom: 30px;
}

.news-detail--container-inner {
  @extend .ce-container-inner-width;
  padding-bottom: 100px;
}

.news-detail--post-image-container-inner {
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  @include from($wide-screen) {
    grid-template-columns: 1fr 202px;
  }
}

.news-detail--post-image-thumbnail-wrap {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  @include between(450px, $tablet) {
    grid-gap: 10px;
  }
  @include between($tablet, $wide-screen) {
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(190px, 200px));
  }
  @include from($wide-screen) {
    grid-gap: 20px;
    display: inline-block;
  }
}

.news-detail--breadcrumb {
  position: relative;

  .page-back-arrow {
    position: absolute;
    left: -30px;
  }
}

.news-detail--post {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  @include from($desktop) {
    display: grid;
    grid-gap: 0 50px;
    grid-template-columns: 300px 1fr;
  }

  @include from($desktop-large) {
    width: auto;
  }

  @include between($desktop-large, $wide-screen) {
    max-width: $container-width-desktop-large;
  }

  @include from($wide-screen) {
    grid-gap: 0 70px;
    grid-template-columns: 400px 1fr;
    max-width: $container-width-widescreen;
  }

  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.news-detail--post-content {
  width: 100% !important;

  &-inner {
    position: relative;
    @include from($desktop-large) {
      position: sticky;
      top: 10px;
    }
  }

  @include from($tablet) {
    margin: 0 auto;
  }

  @include between($tablet, $desktop) {
    max-width: $container-width-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
  }
}


.news-detail--post-image-container {
  width: 100% !important;

  &-inner {
    @include from($desktop) {
      margin: 0;
    }
  }

  @include from($tablet) {
    margin: 0 auto;
  }

  @include between($tablet, $desktop) {
    max-width: $container-width-tablet;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }
}


/************** STYLE *************/
.news-detail--post {
  &-date {
    color: $color-text-accent;
    font-size: $font-size-x-small;
    font-family: $font-copy-bold;
  }

  &-text {
    p {
      font-size: $font-size-lead;
      color: $color-text-lead;
    }
  }

  &-headline {
    margin-top: 0;
    @include from($desktop) {
      margin-top: 30px;
    }
  }

  &-image-container-inner:first-child {
    margin-bottom: 30px;
    @include from($desktop-large) {
      margin-bottom: 40px;
    }
  }

  &-image-description {
    margin: 0 !important;
    font-family: $font-copy-light;
    font-style: italic;
    color: $color-text-grey-light;
    font-size: $font-size-description;
  }

  &-image-container-inner {
    margin-bottom: 60px;
  }
}

.news-detail--post-image-thumbnail-item,
.news-detail--post-image-preview {
  overflow: hidden;
  border-radius: $image-border-radius;
}


.news-detail--post-image-thumbnail-item {
  position: relative;
  margin-bottom: 20px;
  transition: all .4s;
  line-height: 0;

  @include from($desktop) {
    &:hover {
      cursor: pointer;
      // Hover state for image hovering
      .news-detail--post-image-overlay {
        opacity: 0.2;
      }

      &:after {
        opacity: 1;
      }
    }

    // Magnify icon
    &:after {
      position: absolute;
      display: block;
      transition: all .4s;
      opacity: 0;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: sassvg('ZoomRegular', $color-white) center no-repeat;
    }
  }

  img {
    margin: 0;
    object-fit: cover;
  }
}

// Hover overlay for image
.news-detail--post-image-overlay {
  transition: all .4s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-background-accent;
}

.news-detail--post-image-preview-wrap {
  position: relative;
  line-height: 0;
}

.news-detail--post-image-preview {
  // Every image preview is positioned absolute except active image-preview, active image-preview is positioned relative,
  // and that image holds height of container
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  transition: 300ms ease-in;
  // Class added by JS
  &.active-preview {
    position: relative;
    opacity: 1;
    visibility: visible;
  }
}

.news-detail--post-image-thumbnail-item {
  opacity: 0.8;
}

// Class added by JS
.active-thumbnail {
  opacity: 1;
  border: 1px solid $color-border-active-thumbnail;
}

.news-detail--post-image-preview,
.news-detail--post-image-thumbnail-item {
  border: 1px solid $color-border-nav-white;
}