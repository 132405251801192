@import "../constants/constants";
@import "../base/mixins";

/******** grid ********/

// CE grid with grey background
@include greyBackgroundOption(
        $div-container-class: "icon-box-irre--container",
        $div-inner-class: "icon-box-irre--container-inner"
);

.icon-box-irre--item {
  display: grid;
  grid-template-columns: 54px 1fr;
  grid-gap: 20px;

  @include from($tablet) {
    display: block;
  }

  &-wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 70px;
    justify-content: center;

    @include from($tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 70px;
    }
    @include from($desktop-large) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;
    }
    @include from($ultra-wide-screen) {
      grid-gap: 100px;
    }
  }
}

/******** style ********/

.icon-box-irre--item {

  &-text {
    margin-bottom: 20px;

    @include from($tablet) {
      margin-bottom: 30px;
    }

    a {
      font-size: $font-size-lead;
      color: $color-text-accent-secondary;
    }
  }

  &-image {
    width: auto;
    max-width: 54px;

    &-wrap {
      display: grid;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 54px;
      background-color: $color-background-icons;
      border-radius: 50%;
      // removes empty space around image
      line-height: 0;

      @include from($tablet) {
        margin-bottom: 20px;
      }
    }
  }

  &-icon {
    width: auto;

    &-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40px, auto));
      grid-gap: 10px;
      justify-content: left;
    }
  }

  &-title {
    margin-top: 0;
    color: $color-background-accent;

    @include until($tablet) {
      font-size: $font-size-h4;
    }

    &-small {
      margin-bottom: 15px;
    }
  }
}