@import "../constants/constants";
@import "../base/mixins";

/******** Content grid ********/
.video--container {
  @extend .ce-full-width-no-margin;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  @include from($desktop) {
    margin-top: 40px !important;
    margin-bottom: 60px !important;
  }

}

.video--full-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  outline: none;
  border-radius: $image-border-radius;
}

.video--container-inner {
  iframe {
    border-radius: $image-border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include until($desktop) {
      display: block;
      min-height: 220px;
    }
  }
}

/******* Content style *******/
.video--container-inner {
  position: relative;
  // Provides youtube video proportion for current width, padding-bottom sets height of video
  padding-bottom: 56.25%;
}

.video--content-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  // Provides transparent gray mask over video
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.8) 100%);
  @include until($tablet) {
    display: none;
  }
}

.video--text {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 0 30px 50px;
}

