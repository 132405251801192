@import "../constants/constants";
@import "../base/mixins";

.claim-submit-button {
  position: relative;
  cursor: pointer;
  @include linkWithArrowExtend(
          $arrowColor: $color-white,
          $fontColor: $color-white,
          $left: calc(100% - 70px),
          $top: 50%
  );

  input {
    border-radius: 2px;
    padding: 20px 80px 20px 30px;
    cursor: pointer;
  }

  @include from($desktop) {
    margin-top: unset;
  }
}

.claim-label {
  font-size: $font-size-lead;
  font-family: MulishBold, Trebuchet, Geneva, Arial, sans-serif;
  color: $color-text-accent;
  margin-bottom: $container-margin / 4;
}

.claim-form {
  margin-top: $container-margin/2;

  @include from($tablet) {
    margin-top: $container-margin * 3.5;
  }

  .form-group {
    margin-bottom: $container-margin * 2;

    @include from($tablet) {
      margin-bottom: $container-margin * 3;
    }

    input {
      font-size: $font-size-lead;
    }
  }
}

.claim-form-extended {

  @include from($tablet) {
    margin-top: $container-margin * 2;
  }

  .form-group {
    margin-bottom: $container-margin !important;

    @include from($desktop) {
      margin-bottom: $container-margin * 2 + $container-margin / 2 !important;
    }
  }

  .claim-submit-button {
    margin-top: $container-margin;
    margin-bottom: $container-margin;
  }
}

/************** STYLE *************/
.statement--button {
  @include buttonBlue;

  .link-with-arrow {
    transform: translateY(-50%);
  }

  &.claim--statement--button {
    color: $color-text-accent !important;
    font-size: $font-size-lead-large;
    background: sassvg("chevron_down", $color-text-accent) right center no-repeat;
    background-size: 20px;
    border-bottom: 1px solid #E2E2E2;
    transition: background 0.2s;
    padding: 10px 10px 10px 0;
    width: 100%;
    margin-top: 25px;

    @include from($tablet) {
      margin-top: 45px;
      padding: 10px 250px 10px 0;
      width: auto;
    }

    &.toggle-open {
      background: sassvg("chevron_up", $color-text-accent) right center no-repeat;
      background-size: 20px;
      border-bottom: unset;
    }
  }
}


.claim--detail--container {
  margin: $container-margin * 3 0;

  .claim--detail:last-of-type {
    margin-bottom: 0;
  }

  .powermail_field {
    margin-bottom: $container-margin * 2;
  }
}

/* ----------------------------------------------------------- */
/* ------------------------- DETAIL -------------------------- */
/* ----------------------------------------------------------- */

.claim--detail {
  display: flex;
  flex-direction: column;
  margin-bottom: $container-margin;

  @include from($xmobile) {
    flex-direction: row;
    gap: $container-margin * 2;
    align-items: center;
  }
}

.claim--detail--label {
  font-size: $font-size-lead;
  color: $color-text-lead;
  margin: 0;
  width: 240px;
}

.claim--detail--value {
  margin: 0;
  color: $color-text-accent;
}

.claim--items--container {
  display: grid;

  .claim--item:last-of-type {
    border-bottom: 1px solid #e2e2e2;
  }
}

.claim--item--image {
  width: 130px;
  height: 100px;
  object-fit: cover;
}

.claim--item--no-image {
  width: 130px;
  height: 100px;
  background-color: #eee;
}

.claim--item {
  padding: 20px 0 30px;
  border-top: 1px solid #E2E2E2;
  display: grid;
  place-items: center;
  gap: $container-margin;

  @include from($tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: unset;
    place-items: unset;
    padding: 15px 0;
  }

}

.claim--item--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @include from($tablet) {
    flex-direction: row;
  }
}

.claim--item--name {
  @include until($tablet) {
    text-align: center;
  }

  font-size: $font-size-lead;
  font-family: MulishBold, Trebuchet, Geneva, Arial, sans-serif;
  color: $color-text-accent;
  margin: 0;
}

.claim--item--code {
  @include until($tablet) {
    text-align: center;
  }

  font-size: $font-size-lead;
  color: $color-text-grey-light;
  margin: 0;
}

.claim--item--toggle {
  @include until($tablet) {
    margin: 0 auto;
  }

  margin-left: auto;
  background: sassvg('chevron_down', $color-text-accent) right center no-repeat;
  background-size: 16px;
  border: none;
  outline: none;
  padding-right: $container-margin * 2;
  font-size: $font-size-lead;
  color: $color-text-accent;
  cursor: pointer;

  &.toggle-open {
    background: sassvg('chevron_up', $color-text-accent) right center no-repeat;
    background-size: 16px;
  }
}

.claim-form-extended--adress-container,
.claim-form-extended--data-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include from($desktop) {
    grid-gap: unset;
    flex-wrap: unset;
    gap: $container-margin*2;
  }
}

.claim-form-extended--adress,
.claim-form-extended--email {
  flex: 1;
  flex-basis: 100%;

  @include from($desktop) {
    flex-basis: unset;
  }
}

.claim-form-extended--description textarea {
  resize: none;
  min-height: 130px;
}

.claim-form-extended--option-container {
  display: flex;
  align-items: center;
  gap: $container-margin;

  @include from($desktop) {
    gap: $container-margin * 4;
  }

  label {
    display: flex;
    align-items: center;
    gap: 15px;
    white-space: nowrap;
    cursor: pointer;

    input {
      order: -1;
      width: 18px;
      height: 18px;
      accent-color: $color-text-accent;
    }
  }
}

.claim-form-extended--upload-container {
  display: grid;
  gap: $container-margin;

  @include from($tablet) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @include from($desktop) {
    gap: $container-margin*2;
  }
}

.claim-form-extended--upload {
  border: 1px solid $color-text-cart-description;
  background: $color-white;
  border-radius: 3px;
  position: relative;
  height: $container-margin*10;

  @include from($tablet) {
    height: $container-margin*3;
  }

  &.preview {
    height: $container-margin * 10;
    @include from($desktop) {
      height: $container-margin*5;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background: sassvg('image-placeholder', $color-text-grey-light) center no-repeat;
    width: 60px;
    height: 60px;
    background-size: 60px 60px;
    left: 30px;
    pointer-events: none;
    top: 70px;

    @include from($tablet) {
      top: 15px;
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: sassvg('cloud', $color-text-grey-light) center no-repeat;
    width: 42px;
    height: 32px;
    background-size: 42px 32px;
    right: 30px;
    pointer-events: none;
    top: 84px;

    @include from($tablet) {
      top: 22px;
      background-size: 21px 16px;
      width: 21px;
      height: 16px;
    }
  }

  img {
    display: none;

    &.preview {
      display: block;
      position: absolute;
      inset: 0;
      height: 100%;
      object-fit: cover;
      border: 3px;
      z-index: 5;
    }
  }

  input {
    opacity: 0;
    cursor: pointer;

    @include until($tablet) {
      height: 100%;
    }
  }

  .claim-form-extended--remove {
    width: 32px;
    height: 32px;
    background: sassvg('x', $color-background-grey-light) center no-repeat, $color-background-grey-light-accent;
    border-radius: 50%;
    position: absolute;
    z-index: 15;
    cursor: pointer;
    display: none;
    top: -8px;
    right: -8px;
    background-size: 16px;

    @include from($tablet) {
      width: 16px;
      height: 16px;
      right: -5px;
      top: -5px;
      background-size: unset;
    }

    &.preview {
      display: block;
    }
  }
}

.claim-success {
  width: 100%;
}

.claim-success--headline {
  font-size: $font-size-lead-large;
  color: $color-text-green;
  margin-bottom: $container-margin / 2;

  @include until($tablet) {
    text-align: center;
  }
}

.claim-success--text {
  font-size: $font-size-lead;
  color: $color-text-grey-light;
  margin: 0;

  @include until($tablet) {
    text-align: center;
  }
}

.claim-form--container {
  display: grid;
  grid-template-columns: 1fr;
  gap: $container-margin * 2;

  @include from($tablet) {
    gap: $container-margin * 3;
    grid-template-columns: 1fr 1fr;
  }
}

.claim-form-extended--city,
.claim-form-extended--zip,
.claim-form-extended--mobile,
.claim-form-extended--phone {
  @include until($tablet) {
    flex-basis: 100%;
  }

  @include between($tablet, $desktop) {
    max-width: calc(50% - 10px);
    flex: 1;
  }
}

.claim-form-extended--zip,
.claim-form-extended--phone {
  @include between($tablet, $desktop) {
    margin-left: $container-margin;
  }
}

.claim--item--form-container.whole-width {
  @include until($desktop) {
    flex: 1;
  }
}

.claim-form-extended--adress,
.claim-form-extended--city,
.claim-form-extended--zip,
.claim-form-extended--email,
.claim-form-extended--mobile,
.claim-form-extended--phone {
  position: relative;

  .pristine-error.text-help {
    position: absolute;
    top: 100%;
  }
}

.no-claim-available {
  div {
    font-size: $font-size-h3;
    color: $color-input-text-error;
    margin: 45px 0 $container-margin;

    @include from($tablet) {
      margin: 45px 0 0;
    }
  }
}

[data-error-msg] {
  color: $color-input-text-error;
}

.claim-form-extended--notification {
  .form-group.has-danger {
    .claim-form-extended--option-container label {
      &:before {
        border-color: $color-text-red;
      }
    }
  }
}

.claim-form-extended--notification {
  .claim-form-extended--option-container {

    label {
      position: relative;

      input {
        opacity: 0;
      }

      &.selected {
        &:before {
          border-color: $color-text-accent;
        }

        &:after {
          display: block;
        }
      }

      &:after {
        display: none;
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $color-text-accent;
        left: 3px;
        top: 5px;
      }

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid $color-input-border-grey;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }
}

.claim-success-message {
  max-height: 90px;
  overflow: hidden;
  transition: max-height 0.2s;

  &.remove-height {
    max-height: 0;
  }
}

.claim-form-extended--images .claim-form-extended--upload-container .form-group {
  margin-bottom: 0 !important;
}

.claim-form--gif-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $container-margin;

  @include from($tablet) {
    display: flex;
    height: fit-content;
    margin-top: $container-margin*3 + $container-margin/2;
  }

  img {
    box-shadow: 0px 0px 10px 0px #0000000D;
    cursor: pointer;

    @include from($tablet) {
      width: 207px;
      height: 207px;
    }
  }
}