@import "../constants/constants";
@import "../base/mixins";

// product stickers
.product--sticker {
  display: block;
  float: right;
  clear: both;
  margin-bottom: 0;

  width: 40px;
  height: 48px;

  .product-list--stickers-small & {
    width: 30px;
    height: 36px;
  }

  @include until($tablet) {
    width: 22px;
    height: 26px;

    &-grid {
      grid-template-columns: 6px 6px;
      margin-top: 10px;
    }

    &-number {
      font-size: 12px;
    }

    &-sign {
      font-size: 6px;
    }
    &.sticker-discount {
      background-size: 100%;
    }

    &.sticker-new {
      background-size: 98%;
    }

    &.sticker-limit {
      background-size: 98%;
    }

    &.sticker-outlet {
      width: 30px;
      background-size: 98%;
      margin-left: -10px;
    }
  }

  font-family: $font-sticker;


  &-row {
    margin-bottom: 10px;
    text-align: right;

    img {
      width: auto;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 20px 10px;
    grid-gap: 2px;
    margin-top: 20px;

    .product-list--stickers-small & {
      display: block;
      grid-template-columns: unset;
      text-align: center;
      margin-top: 15px;
    }

    justify-content: center;
    line-height: 1;
  }

  &-number {
    font-size: 18px;

    .product-list--stickers-small & {
      font-size: 12px;
    }

    font-weight: normal;
    letter-spacing: -1px;
  }

  &-sign {
    display: grid;
    align-items: flex-start;

    .product-list--stickers-small & {
      display: inline;
      align-items: unset;
    }

    font-size: 12px;
    font-weight: normal;
  }

  &.sticker-discount {
    background: url("/source/svg/markice/popust.svg") no-repeat center;
    color: $color-white;

    .product-list--stickers-small & {
      background-size: 30px 36px;
    }
  }

  &.sticker-recommend {
    background: url("/source/svg/markice/idealna-cena.png") no-repeat center;
    background-size: 100%;

    .product-list--stickers-small & {
      background-size: 30px 36px;
    }
  }

  &.sticker-limit {
    background: url("/source/svg/markice/ogranicena-kolicina.svg") no-repeat center;

    .product-list--stickers-small & {
      background-size: 30px 36px;
    }
  }

  &.sticker-outlet {
    background: url("/source/svg/markice/outlet.svg") no-repeat center;
    width: 54px;
    margin-left: -15px;

    .product-list--stickers-small & {
      background-size: 36px 40px;
      width: 40px;
    }
  }

  &.sticker-new {
    background: url("/source/svg/markice/novo.svg") no-repeat center;

    .product-list--stickers-small & {
      background-size: 30px 36px;
    }
  }

  &.sticker-webDiscount {
    width: 40px;
    height: 49px;
    background: url("/source/svg/markice/web-popust.svg") no-repeat center;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }
  }

  &.sticker-weekDiscount {
    // display: none !important;
    width: 40px;
    height: 49px;
    background-image: url("/source/svg/markice/week-discount.png");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }

  }

  &.sticker-shockAction {
    width: 40px;
    height: 49px;
    background-image: url("/source/svg/markice/shock-action.png");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }

  }

   &.sticker-fairAction {
    width: 40px;
    height: 49px;
    background-image: url("/source/svg/markice/fair-action.png");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }

  }

  &.sticker-lowestPriceEverAction {
    width: 65px;
    height: 75px;
    background-image: url("/source/svg/markice/nikad-niza-cena.svg");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 38px;
      height: 44px;
      margin-bottom: 2px;
    }
  }

  &.sticker-lastMinute {
    width: 40px;
    height: 49px;
    background-image: url("/source/svg/markice/last-minute.png");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }

  }

  &.sticker-webArticle {
    width: 40px;
    height: 49px;
    background-image: url("/source/svg/markice/web-artikal.png");
    background-size: cover;

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    @include until($tablet) {
      width: 22px;
      height: 26px;
      margin-bottom: 2px;
    }

  }

  &.sticker-freeShipping {
    position: absolute;
    width: 63px;
    height: 8px;
    background-image: url("/source/svg/markice/free-shipping.svg");
    background-size: cover;

    @include from($xmobile) {
      width: 94px;
      height: 12px;
    }

    @include from($tablet) {
      width: 125px;
      height: 16px;
    }

    @include between($desktop-large, $wide-screen) {
      width: 117px;
      height: 15px;
    }

    .product-list--stickers-small & {
      width: 30px;
      height: 36px;
      background-size: 30px 36px;
    }

    .product-detail--media-bottom & {
      position: static;
    }

    .product-list--item-image-wrap & {
      top: 5px;
      left: 5px;
    }

    .product-list--item-image-wrap .sticker-freeShipping--wrap & {
      top: 0;
      left: -3px;
    }
  }
}

.product--sticker-wrap {
  display: grid;
  gap: 2px;
}
