@import "../constants/constants";
@import "../base/mixins";

.header-main,
.footer,
.content-main {
  // Add margin for all items for mobile view
  // We will move full width items to the borders with negative margins
  margin: 0 $container-margin;
  @include from($desktop) {
    margin: 0 auto;
  }
}

/*
 * Used for pinning footer on bottom
 */

.container-main {
  position: relative;
  min-height: 100vh;
  padding-top: 130px;

  @include from($tablet) {
    padding-top: 120px;
  }

  @include from($desktop-large) {
    padding-top: 0;
  }

  .mobile-countdown-active & {
    @include until($xsmobile) {
      padding-top: 240px;
    }

    @include until($xmobile) {
      padding-top: 220px;
    }
  }

  .stoerer-open-mobile & {

    @include until($tablet) {
      padding-top: 200px;
    }

    @include until($desktop) {
      padding-top: 190px;
    }

  }
}

.content-main {
  //padding-bottom: 350px; /* Footer height */
  //
  //@include from($xmobile) {
  //  padding-bottom: 350px; /* Footer height */
  //}
  //
  //@include from($mobile) {
  //  padding-bottom: 350px; /* Footer height */
  //}
  //
  //@include from($tablet) {
  //  padding-bottom: 455px; /* Footer height */
  //}

  @include from($desktop-large) {
    padding-bottom: 680px; /* Footer height */
  }
}

.footer {
  @include from($desktop-large) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/*
 * END Used for pinning footer on bottom END
 */

.ce-full-width-no-margin {
  //margin-top: 0;
  //margin-bottom: 0;
  max-width: none;
  @include until($desktop) {
    padding: 0 $container-margin;
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }
}

.ce-full-width {
  @include until($tablet) {
    margin-top: $ce-margin-vertical;
    margin-bottom: $ce-margin-vertical;
  }
  @include until($desktop) {
    margin-left: -$container-margin;
    margin-right: -$container-margin;
    padding: 0 $container-margin;
  }
  // Left and right margin not needed for desktop+
  @include tablet() {
    margin-top: $ce-margin-vertical-tablet;
    margin-bottom: $ce-margin-vertical-tablet;
  }
  @include desktop() {
    margin-top: $ce-margin-vertical-desktop;
    margin-bottom: $ce-margin-vertical-desktop;
  }
  @include desktopLarge() {
    margin-top: $ce-margin-vertical-desktop-large;
    margin-bottom: $ce-margin-vertical-desktop-large;
  }
  @include widescreen() {
    margin-top: $ce-margin-vertical-widescreen;
    margin-bottom: $ce-margin-vertical-widescreen;
  }
  @include ultraWidescreen() {
    margin-top: $ce-margin-vertical-widescreen;
    margin-bottom: $ce-margin-vertical-widescreen;
  }

}

.ce-container-width {
  margin-top: $ce-margin-vertical-tablet;
  margin-bottom: $ce-margin-vertical-tablet;
  width: auto;

  @include from($tablet) {
    margin: $ce-margin-vertical-tablet auto;
  }

  @include tablet() {
    max-width: $container-width-tablet;
    margin-top: $ce-margin-vertical-tablet;
    margin-bottom: $ce-margin-vertical-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
    margin-top: $ce-margin-vertical-desktop;
    margin-bottom: $ce-margin-vertical-desktop;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
    margin-top: $ce-margin-vertical-desktop-large;
    margin-bottom: $ce-margin-vertical-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
    margin-top: $ce-margin-vertical-widescreen;
    margin-bottom: $ce-margin-vertical-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
    margin-top: $ce-margin-vertical-widescreen;
    margin-bottom: $ce-margin-vertical-widescreen;
  }
}

.ce-container-width-no-margin {
  width: auto;

  @include from($tablet) {
    margin: 0 auto;
  }

  @include tablet() {
    max-width: $container-width-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.ce-container-inner-width {
  width: auto;

  @include from($tablet) {
    margin: 0 auto;
  }

  @include tablet() {
    max-width: $container-width-tablet;
  }
  @include desktop() {
    max-width: $container-width-desktop;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

// Used for some CE's
// Extends grid for 240px
// below 1700px goes full width
// over 1700px has space
.ce-container-inner-width-extended {
  @extend .ce-full-width;

  max-width: $container-width-ultra-widescreen + 240px;
  margin: 0 auto;
  padding: 0 !important;
}

.ce-full-width-simple {
  @include until($desktop-large) {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

// Products list full width grid
.article-list-grid {
  display: grid;
  grid-template-columns:1fr;
  grid-row-gap: 30px;
  @include from($mobile) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @include from($desktop) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }

  @include from($wide-screen) {
    grid-template-columns: repeat(4, 1fr);
  }
}