@import "../constants/constants";
@import "../base/mixins";

$product-item-hover-animation-speed: 300ms;

// ------ PRODUCTS
.product-list--item {
  position: relative;
  padding-bottom: 20px;
  //border-bottom: 1px solid $color-border-nav-grey;

  &-info {
    transition: opacity $product-item-hover-animation-speed;
    opacity: 1;
  }

  &-info--active {
    transition: opacity $product-item-hover-animation-speed;
    opacity: 0;
  }

  &-add span {
    transition: opacity $product-item-hover-animation-speed;
    opacity: 1;
  }

  &-color-wrap {
    transition: opacity $product-item-hover-animation-speed;
    opacity: 1;
  }

  &-add {
    transition: background $product-item-hover-animation-speed;
    background: sassvg("cart-icon", $color-background-accent) no-repeat center left;
  }

  &-title {
    transition: color $product-item-hover-animation-speed;
    color: $color-text-headline;
  }

  &-label {
    display: inline-block;
    font-family: $font-head-bold;
    font-size: $font-size-sub-headline;
    line-height: 1;
    color: $color-text-accent;
    text-transform: uppercase;
    width: 100%;
  }
}

.product-list--image-and-stickers-wrap {
  position: relative;

  .product-list--stickers {
    top: 5px !important;
    right: 5px !important;
  }
}

.product-list--stickers {
  position: absolute;
  top: 5px;
  right: 5px;
  background: $color-white;
  padding: 2px;
  border-radius: 2px;

  &.product-list--stickers-small {
    @include until($tablet) {
      right: 30px;
      top: unset;
      bottom: 3px;
    }
  }

  @include from($desktop) {
    right: 25px;
    &-small {
      right: 0;
    }
  }

  .search-popup--product & {


    @include from($desktop) {
      right: 5px;
      top: -5px;
    }
  }

  .product--sticker-row {
    margin-bottom: 0;
  }
}

.product-list--stickers-small {
  @include until($tablet) {
    .product--sticker-wrap {
      display: flex;
      justify-content: flex-end;
    }
    .product--sticker-row {
      margin-bottom: 0;
    }
    .product--sticker {
      margin-bottom: 0;
      margin-left: 3px;
    }
  }
}


.product-list--item-image {
  //max-width: 300px;

  &-wrap {
    position: relative;
    padding-right: $imagePaddingRightIndent;

    .container-foreign & {
      padding-right: 0; // No icons on foreign pages
    }

    .container-main-b2b & {
      padding-right: 0;
    }

    &--system-element {
      padding-right: 0 !important;
    }

    @include until($tablet) {
      margin: -5px -5px 0 -5px;

      img {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
  }
}

.product-list--item-link {
  display: block;
}

.product-list--item-title {
  margin: 30px 0 15px 0;
  color: $color-text-grey-light;
  transition: color $product-item-hover-animation-speed;
  @include until($tablet) {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
    max-height: 30px;
    overflow: hidden;

    .b2b-ambients-detail--system-elements & {
      max-height: unset;
    }
  }
  @include from($tablet) {
    height: 50px;
    margin: 20px 0 0 0;
  }

  &-link {
    text-decoration: none;

    @include until($tablet) {
      display: inline-block;
      line-height: 120%;
      height: 55px;
    }

    &:hover {
      text-decoration: none;
      text-decoration-color: $color-text-headline;
    }
  }
}

.product-list--item-dimensions {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $color-text-product-discount !important;
  line-height: 120%;
  min-height: 14px;
  font-size: 9px;
  margin-top: 5px;

  @include from($desktop) {
    font-size: 12px;
    color: $color-text-cart-description;
  }
}

.product-list--item-picked-image {
  transition: all 0.5s;
  will-change: transform;
}


// Because of scaling image we need to add div holder with certain properties
.product-list--item-picked-image-wrap {
  width: fit-content;
  height: fit-content;
  line-height: 0;
  overflow: hidden;
  border-radius: $image-border-radius;
}

.pick-and-prepend.show-picked-el {
  //display: inline-block;
  display: none;
  border-bottom: 1px solid $color-border-nav-grey;
  // Hidden overflow  when zoom in picture
  overflow: hidden;

  &:hover {
    cursor: pointer;
    // Zoom in img on hover container
    .product-list--item-picked-image {
      transform: scale(1.04);
    }
  }

  @include until(520px) {
    padding-bottom: 20px;
  }
}


.show-picked-filter-no-results {
  display: block;
}


.product-list--item-last-minute-expired.show-picked-last-minute-el {
  display: inline-block;

  .product-list--last-minute-expired-offer-subheadline {
    margin-top: 0;
    color: $color-text-lead;

    &:after {
      display: block;
      margin: 30px 0;
      width: 130px;
      height: 1px;
      background-color: $color-background-grey-light;
      content: '';
    }
  }

  .product-list--last-minute-expired-offer-text {
    margin-top: 30px;
    color: $color-text-red;
    font-family: $font-copy-bold;
  }

}

.product-list--no-results {
  margin-top: 0;
  color: $color-text-red;
  font-family: $font-copy-bold;
}

.product-list--item-wrap-last-minute-expired {
  grid-template-columns: 1fr !important;
}


.product-list--item-data {

  &.product-list--item-data--two-prices {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    //@include until($tablet) {
    //}
    grid-gap: 10px;
    @include from($desktop) {
      grid-gap: 20px;
    }

    .product-list--item-data-left {
      order: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-list--item-price-loyalty,
      .product-list--item-price-new {
        //display: inline-block;
        margin-top: 0;
        color: $color-text-red;
        font-size: 14px;
        margin-bottom: 1px;
        @include from($desktop) {
          font-size: 18px;
        }
      }

      .product-list--item-discount-info,
      .product-list--item--loyalty-container {
        display: none;
      }
    }

    .product-list--item-discount-info {
      grid-column: 1/3;
      order: 2;
      margin-top: -10px;

      .splide__list__single & {
        margin-top: -15px;
      }

      @include from($desktop) {
        margin-top: -10px;
      }


      @include from($desktop) {
        margin-top: -20px;
      }

      &.product-list--hidden-discount {
        opacity: 0;
      }
    }

    .product-list--item-data-right {
      order: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-list--item-discount-info {
        margin-bottom: 0 !important;
      }
    }
  }
}

.product-list--item-price {
  &-old {
    min-height: 25px;
    text-decoration: line-through;
    @include until($tablet) {
      font-size: 9px;
      line-height: 8px;
      color: $color-text-cart-description;
    }
  }

  &-loyalty,
  &-new {
    font: $font-size-product-list-price $font-copy-bold;
    color: $color-text-product-price;

    & small {
      font-size: 60%;
    }

    &.product-list--no-discount {
      color: $color-text-grey-light !important;

      @include from($tablet) {

        .product-list--item-data-right &,
        .product-list--item-data-left & {
          //margin-bottom: 10px !important;

          @include until($desktop) {

            .splide__list__single & {
              margin-bottom: 23px !important;
              margin-top: 8px !important;
            }
          }
        }
      }

      .product-list--item-data--two-prices & {
        .splide__list__single & {
          @include until($desktop) {
            margin-bottom: 23px !important;

            .product-list--item-data-left & {
              margin-bottom: 24px !important;
            }
          }
          @include from($desktop) {
            margin-bottom: 19px !important;

            .product-list--item-data-left & {
              margin-bottom: 20px !important;
            }
          }
        }


        .splide__list__single & {
          margin-bottom: 22px;
        }
      }
    }

    @include until($tablet) {
      font-size: 14px;
    }
  }

  &-loyalty {
    color: $color-background-accent-secondary;

    small {
      display: none;
    }
  }
}

.product-list--item-add {
  display: inline-block;
  color: $color-white;
  font-family: $font-copy-bold;
  text-decoration: none;
  margin: 0;
  border-radius: 2px;
  padding: 6px 6px 6px 20px;
  background: sassvg("cart-icon", $color-white) no-repeat center left 6px, $color-background-accent;
  background-size: 10px;
  font-size: 6px;
  line-height: 1.1;

  @include from(380px) {
    font-size: 7px;
  }

  @include from(420px) {
    font-size: 9px;
  }

  @include from(520px) {
    font-size: 12px;
  }

  @include from($mobile) {
    padding: 10px 10px 10px 40px;
    background: sassvg("cart-icon", $color-white) no-repeat center left 10px, $color-background-accent;
    background-size: 20px;
    font-size: 11px;
  }

  @include from($wide-screen) {
    font-size: 14px;
  }

  span {
    transition: all $product-item-hover-animation-speed;
  }

}

.product-list--item-color {
  &-wrap {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all $product-item-hover-animation-speed;
  }

  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 2px 3px;
  border-radius: 5px;
  border: 1px solid #DBDBDB;
  cursor: pointer;
  transition: all .5s;
  background-position: center !important;

  &.small-filter-color {
    width: 20px;
    height: 20px;
  }
}

.no-scroll {
  overflow: hidden;
  position: relative;
}

.sticker-lowestPriceEverAction {
  .product-detail--media-right & {
    position: absolute;
    top: 9px;
    right: 36px;

    @include from($tablet) {
      right: 56px;
    }
  }

  .splide-homepage & {
    position: absolute;
    top: 0 !important;
    right: 32px;

    @include until($xmobile) {
      width: 28px !important;
      height: 34px !important;
      right: 30px;
    }

    @include between($desktop, $wide-screen) {
      width: 38px !important;
      height: 44px !important;
      right: 50px;
    }

    @include from($desktop) {
      right: 56px;
    }
  }

  .product-list--item-container & {
    position: absolute;
    top: 0 !important;
    right: 36px;

    @include until($xmobile) {
      width: 28px !important;
      height: 34px !important;
      right: 30px;
    }

    @include from($tablet) {
      right: 56px;
    }
  }
}

.sticker-freeShipping--wrap {
  position: absolute;

  .splide-homepage & {
    top: 5px;
    left: 8px;

    @include from($desktop) {
      top: 25px;
      left: 8px;
    }
  }

  .product-detail--media-bottom & {
    top: 10px;
    left: 0;
  }
}

.sticker-freeShipping {
  .splide-homepage & {
    width: 63px !important;
    height: 8px !important;

    @include from($xmobile) {
      width: 117px !important;
      height: 15px !important;
    }

    @include from($mobile) {
      width: 140px !important;
      height: 18px !important;
    }

    @include between($desktop, $wide-screen) {
      width: 94px !important;
      height: 12px !important;
    }
  }

  .product-detail--media-bottom & {
    @include from($tablet) {
      width: 176px !important;
      height: 22px !important;
    }
  }
}

.splide-homepage .product--sticker.sticker-freeShipping {
  right: unset; // unsetting default 'right' value from list page
}