@import "../constants/constants";
@import "../base/mixins";

$footer-grid-first-column: 230px;
$footer-grid-first-column-small: 150px;
$footer-grid-indent-nav: 70px;

/*************** grid ***************/
.footer {
  display: flex;
  flex-direction: column-reverse; // reverse div order on mobile

  @include from($desktop-large) {
    display: block;
  }

  &--top {

    &-grid {
      position: relative;

      @include from($desktop-large) {
        display: grid;
        grid-template-columns: 1fr;
        //grid-template-columns: $footer-grid-first-column 1fr auto;
        //grid-gap: 30px;
      }

      @include from($wide-screen) {
        //grid-gap: 70px;
      }
    }

    &-nav {
      .page-uid-active {
        a {
          color: $color-white;
        }
      }

      @include from($desktop-large) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
      }
      @include from($wide-screen) {
        grid-gap: 50px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;

      //@include from($desktop-large) {
      //  display: grid;
      //  grid-template-rows: auto auto;
      //  margin-bottom: 0;
      //}


      &--headline {
        font-size: $font-size-lead;
        padding: 5px 0;
        color: $color-white;
        text-transform: uppercase;

        background: sassvg('chevron-down', $color-white) no-repeat right center;
        cursor: pointer;

        &.accordion-active {
          @include until($desktop-large) {
            background: sassvg('chevron-up', $color-white) no-repeat right center;
          }
        }

        @include from($desktop-large) {
          background: unset;
          pointer-events: none;
          cursor: default;
          font-size: $font-size-copy;
          margin-bottom: 24px;
        }

        @include from($wide-screen) {
          font-size: $font-size-lead;
        }
      }

      &-badges {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 10px;
        justify-content: center;

        img {
          max-width: 90px;
          aspect-ratio: 1;

          @include from($desktop-large) {
            max-width: 71px;
          }
        }

        &--mobile-link {
          display: block;
          @include from($desktop) {
            display: none;
          }
        }

        &--desktop-link {
          display: none;
          @include from($desktop) {
            display: block;
          }
        }

        @include from($desktop-large) {
          position: absolute;
          right: 0;
          bottom: -40px;
          grid-gap: 6px;
          justify-content: left;
          align-self: flex-end;
        }

        @include from($ultra-wide-screen) {
          right: 25px;
        }

        &-desktop {
          &-image {
            display: none;
            @include from($desktop-large) {
              display: block;
            }
          }

          &-image1 {
            width: 71px;
          }

          &-image2 {
            width: 75px;
          }

          &-image3 {
            width: 72px;
          }
        }

        &-mobile {
          &-image {
            display: block;
            @include from($desktop-large) {
              display: none;
            }
          }

          &-image1 {
            width: 90px;
          }

          &-image2 {
            width: 97px;
          }

          &-image3 {
            width: 91px;
          }
        }
      }

      &-logo {
        display: none;

        @include from($desktop-large) {
          display: block;
          margin-bottom: 80px;
        }

        img {
          width: $footer-grid-first-column;

          @include from($desktop-large) {
            width: $footer-grid-first-column-small;
          }

          @include from($wide-screen) {
            width: $footer-grid-first-column;
          }
        }
      }

      &.footer--single-image {
        display: grid;
        justify-content: center;
        margin: 50px 0 60px 0;

        @include from($desktop-large) {
          margin-bottom: 0;
          align-content: flex-end;
        }

        img {
          width: 200px;

          @include from($desktop-large) {
            width: auto;
          }
        }
      }

      &.footer--navigation {
        display: none;

        @include from($desktop-large) {
          display: block;

          // Gap between logo on left and nav have to be 140px
          // Gap between nav and image on right have to be 70px
          // footer--top-grid have grid-gap 70px
          // so another 70px is added as padding-left on navigation div
          //padding-left: $footer-grid-indent-nav;
        }
      }
    }

  }

  &--bottom {

    &-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;

      @include from($desktop-large) {
        display: grid;
        grid-template-columns: $footer-grid-first-column 1fr auto;
        grid-gap: 30px;
      }

      @include from($wide-screen) {
        grid-gap: 70px;
      }
    }

    &-info {
      //display: none;

      @include from($desktop-large) {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 50px;
      }

      &-mobile {
        display: block;
        padding-top: $container-margin*2;


        @include from($desktop-large) {
          padding-top: 0;
          //display: none;
        }

        .footer--bottom-info {
          display: grid;
          grid-template-columns: auto;
          justify-content: center;
          grid-gap: 30px;

          @include from($desktop-large) {
            &-copyright,
            &-cards {
              display: none;
            }
          }
        }
      }
    }

    &-empty {
      display: none;

      @include from($desktop-large) {
        display: block;
      }
    }
  }

}

.footer--inner {
  &-top {
    @extend .ce-full-width-no-margin;

    // Using linear-gradient to display two colors. Fixed switch between two colors.
    background: linear-gradient(to top, $color-background-footer-bottom 296px, $color-background-accent 296px);

    @include from($desktop-large) {
      background: $color-background-accent;
    }

    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $color-background-purple;
      position: absolute;
      left: 0;
      top: 346px; // fixed value for exact location on mobile view

      @include from($desktop-large) {
        top: $container-margin*4 !important;
      }
    }

    &.foreign-languages {
      &:before {
        top: 230px;
      }
    }
  }

  &-bottom {
    @extend .ce-full-width-no-margin;
    background: $color-background-accent;

    @include from($desktop-large) {
      background: $color-background-footer-bottom;
    }
  }

}

.footer--inner-top-container {
  @extend .ce-container-inner-width;

  padding: 0 0 $container-margin*2 0;

  @include from($desktop-large) {
    padding: $container-margin 0 $container-margin*3;
  }
}

.footer--inner-bottom-container {
  @extend .ce-container-inner-width;
  padding: 20px 0;

  .footer--bottom-grid {
    position: relative;
    grid-template-columns: 1fr;
    place-items: center;
  }

  .footer--bottom-info {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

// USED WHEN LANGUAGEID IS TRUE
.footer--bottom-empty {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*************** style ***************/
.footer--top {
  &-item {

    &:last-of-type {
      .footer--top-item-list {
        max-height: 148px; // fixed value to avoid losing animation
      }
    }

    &.show-on-mobile {
      @include from($desktop-large) {
        display: none;
      }

      .footer--top-item--headline {
        text-decoration: none;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-list {
      margin: 0;

      @include until($desktop-large) {
        overflow: hidden;
        transition: max-height .2s ease-in-out;
        max-height: 0;
      }

      li {
        padding: 0;
        line-height: 1.1; // reducing gap between rows otherwise it will be same as gap between two li's
        &:before {
          display: none;
        }

        &.show-from-desktop-large {
          @include until($desktop-large) {
            display: none !important;
          }
        }
      }

      &-link {
        display: inline-block;
        padding: 6px 0;
        color: $color-text-header-products-nav;
        text-decoration: none;
        transition: color 200ms;

        &:hover {
          transition: color 200ms;
          text-decoration: underline;
          color: $color-white;
        }
      }

      &-phone,
      &-email {
        padding: 6px 0 !important;
        color: $color-text-header-products-nav;
        line-height: 1.2;

        span {
          margin-bottom: 2.5px;
          display: inline-block;
        }
      }

      &-phone {
        margin-bottom: 12px;

        a {
          &:first-of-type {
            padding-bottom: 0;
          }
          &:last-of-type {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.footer--bottom {
  &-socials {
    display: grid;
    grid-template-columns: repeat(4, 40px);
    grid-gap: 20px;
    margin: auto;

    @include from($desktop-large) {
      gap: 18.5px;
      margin: unset;
    }

    @include from($wide-screen) {
      gap: 20px;
      margin: unset;
    }

    @include from($ultra-wide-screen) {
      margin: 0 30px 0 0;
    }

    &-link {
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 4px;
      transition: border 0.2s;
      border: 1px solid $color-white;
      transition: border 0.2s;

      &:hover {
        border: 1px solid $color-border-socials;
      }

      @include from($desktop-large) {
        border: 1px solid $color-border-socials;

        &:hover {
          border: 1px solid $color-white;
        }
      }
    }
  }

  &-info {

    &-cards {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 7px;
      justify-content: center;

      @include from($desktop-large) {
        align-content: center;
      }

      img {
        width: 64px;
      }
    }

    &-copyright {
      display: grid;
      align-content: center;
      text-align: center;
      font-size: $font-size-description;
      color: $color-text-header-products-nav;
      @include from($desktop) {
        text-align: right;
      }
    }
  }
}

.footer--bottom-empty {
  img {
    display: inline-block;
    width: 40px;
    height: auto;
    margin-right: 7px;
  }
}

.footer--socials-youtube {
  background: sassvg("ic-youtube", $color-text-accent) no-repeat center, $color-white;

  @include from($desktop-large) {
    background: sassvg("ic-youtube", $color-white) no-repeat center;
  }
}

.footer--socials-instagram {
  background: sassvg("ic-instagram", $color-text-accent) no-repeat center, $color-white;

  @include from($desktop-large) {
    background: sassvg("ic-instagram", $color-white) no-repeat center;
  }
}

.footer--socials-linkedin {
  background: sassvg("ic-linkedin", $color-text-accent) no-repeat center, $color-white;

  @include from($desktop-large) {
    background: sassvg("ic-linkedin", $color-white) no-repeat center;
  }
}

.footer--socials-facebook {
  background: sassvg("ic-facebook", $color-text-accent) no-repeat center, $color-white;

  @include from($desktop-large) {
    background: sassvg("ic-facebook", $color-white) no-repeat center;
  }
}

.footer-certificate-icon {

}

.footer--inner-newsletter {
  display: none;

  @include from($desktop-large) {
    display: block;
    background: $color-background-grey-light-accent;
    border-top: 2px solid $color-white;

    &--text {
      margin: 0 !important;
    }

    .footer--inner-top-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $container-margin 0;
    }

    .product-homepage--new-cta-wrap {
      padding-top: 0;

      .product-homepage--link-cta {
        background: $color-background-accent;
      }
    }
  }
}

.footer--inner-bottom {
  @include until($desktop-large) {
    display: none;
  }
}

.footer--inner-top-container.footer--top-grid {
  display: grid;
  padding: $container-margin*2 0;
  grid-gap: $container-margin*2;

  @include from($desktop-large) {
    grid-template-columns: $footer-grid-first-column-small 1fr auto;
    padding: $container-margin 0;
    grid-gap: 45px;
  }

  @include from($wide-screen) {
    grid-template-columns: $footer-grid-first-column 1fr auto;
  }

  @include from($ultra-wide-screen) {
    grid-gap: 70px;
  }

  .footer--top-item-logo {
    margin-bottom: 0;
  }

  .footer--top-item-logo {
    display: grid;
    place-items: center;
  }

  .footer--top-nav {
    display: grid;
    grid-template-columns: 1fr;
    gap: $container-margin;
    place-items: center;

    @include from($desktop-large) {
      gap: 50px;
      grid-template-columns: repeat(3, 1fr);
      place-items: center flex-start;
      padding-left: $container-margin*2;
    }

    @include from($wide-screen) {
      padding-left: 0;
    }

    @include from($ultra-wide-screen) {
      gap: 70px;
    }

    a {
      color: $color-white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: $font-size-lead;

      @include from($desktop-large) {
        font-size: $font-size-copy;
      }

      @include from($wide-screen) {
        font-size: $font-size-lead;
      }
    }
  }
}

.footer--top-item.footer--navigation {
  display: block;
  padding: $container-margin*2 0;
}

.footer--top-nav {
  @include until($desktop-large) {
    display: grid;
    gap: 20px;
  }
}