@import "../constants/constants";
@import "../base/mixins";

// CE grid with grey background
@include greyBackgroundOption(
        $div-container-class: "tab-products--tabs-container",
        $div-inner-class: "tab-products--tabs-inner"
);

.tab-products--tabs-inner {
  padding: 30px 0 0 0 !important; // overrides default grid
}

.tab-products--container {
  @extend .ce-full-width-no-margin;
}

.tab-products--container-inner {
  @extend .ce-container-inner-width;
  margin: 30px 0;
}

.tab-products--content-inner {
  padding: 30px 0;

  .splide__arrows {
    display: none;

    @include from($desktop) {
      display: block;
    }
  }

  @include from($desktop) {
    padding: 70px 30px 30px 30px;
  }
}

.tab-products--tabs {
  display: none;

  @include from($tablet) {
    display: block;
  }
}


/********* STYLE ********/
.tab-products--product {
  position: relative;
  max-width: 150px;
  margin-right: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  border-bottom: 1px solid $color-border-nav-white;
  transition: all 200ms;

  /** Slider tweaks (gap between items) **/
  @include from($xsmobile) {
    margin-right: 50px;
  }
  @include from($desktop) {
    margin-right: 65px;
  }
  @include from($wide-screen) {
    margin-right: 56px;
  }
  @include from($ultra-wide-screen) {
    margin-right: 75px;
  }

  &:hover {
    border-bottom: 1px solid $color-background-cart-grey;
    transition: all 200ms;

    .tab-products--product-link-wrap {
      opacity: 1;
      transition: all 200ms;
    }
  }
}

.tab-products--trigger {
  @extend .accordion-trigger-style;

  @include from($tablet) {
    display: none;
  }
}

.tab-products--product-info {

  @include from($desktop) {
    margin-bottom: 30px;
  }
}

.tab-products--product-image {
  &-wrap {
    margin-bottom: 5px;
  }
}

.tab-products--product-title {
  margin-bottom: 7px;
  font-family: $font-head-bold;
  color: $color-text-grey-light;
  line-height: $line-height-title-description;

  @include from($desktop) {
    font-size: $font-size-description;
  }
}

.tab-products--product-price {
  &-old {
    font-size: $font-size-product-old;
    color: $color-text-lead;
    text-decoration: line-through;
  }

  &-new {
    font-family: $font-head-bold;
    font-size: $font-size-lead;
    color: $color-text-product-price;

    @include from($desktop) {
      font-size: $font-size-description
    }
  }

  &-loyalty {
    font-family: $font-head-bold;
    font-size: $font-size-lead;
    color: $color-background-accent-secondary;

    small {
      display: none;
    }

    @include from($desktop) {
      font-size: $font-size-description
    }
  }
}

.tab-products--product-link {
  @include linkWithArrowExtend(
          $top: 0
  );

  // Arrow overrides
  .link-with-arrow {
    width: 20px !important;
    margin-left: 0 !important;
    left: 0 !important;
  }

  &-wrap {
    display: none;

    @include from($desktop) {
      display: block;
      position: absolute;
      bottom: 20px;
      opacity: 0;
      transition: all 200ms;
    }

  }
}

.tab-products--tabs-grid {
  display: flex;
}

.tab-products--tabs-tab {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 75px;
  margin-right: 10px;
  padding: 0 20px;
  background-color: $color-background-white-transparent;
  border-radius: 2px 2px 0 0;
  color: $color-text-grey-light;
  font-size: $font-size-h4;
  font-family: $font-head-bold;
  cursor: pointer;

  &:hover {
    color: $color-text-accent;
  }

  &.active-tab {
    color: $color-text-accent;
    background-color: $color-white;
  }

  @include from($desktop) {
    padding: 0 40px;
  }
}

.tab-products--content {
  @include from($tablet) {
    display: none;
    max-height: unset;
  }

  &.accordion-panel {
    max-height: unset;
  }

  &.active-tab {
    display: block;
  }
}

.tab-products--splide-arrow {
  position: absolute;
  width: 35px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;

  &.splide__arrow--prev {
    // Arrow to left <-
    @include linkWithArrowReverseExtend(
            $top: 0
    );
    position: absolute;
    height: 30px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    border: none;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;

    .link-with-arrow {
      right: -20px !important;
      left: unset;
    }
  }

  &.splide__arrow--next {
    right: 0;

    .link-with-arrow {
      right: unset;
      left: -20px !important;
    }
  }

  &-link {
    // Arrow to right ->
    @include linkWithArrowExtend(
            $top: 0,
            $arrowColor: $color-background-grey,
    );
    cursor: pointer;
  }
}

[data-container-recommended-tab="4"] {
  display: grid;
  gap: 20px;
  padding: 15px 20px !important;

  @include from($tablet) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
    padding: 40px 0 40px !important;

    .product-location-inventory-item {
      @include from($tablet) {
        margin-right: 10px;
      }

      @include from($desktop-large) {
        margin-right: 30px;
      }

      @include from($wide-screen) {
        margin-right: 40px;
      }

      @include from($ultra-wide-screen) {
        margin-right: 70px;
      }

      &.bordered {
        padding-bottom: 10px;
        border-bottom: 1px solid $color-background-cart-grey;
      }
    }
  }

  @include from($desktop-large) {
    grid-template-columns: repeat(6, auto);
  }

  .product-location-inventory-item {
    a {
      font-size: $font-size-lead;
    }
  }
}