@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.product-search-list--container-inner {
  @extend .ce-container-inner-width;
}


.product-search-list--list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @include from(550px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px
  }
  @include from($tablet) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 70px
  }

  @include from($desktop-large) {
    grid-template-columns: repeat(4, 1fr);
  }
}


/************* STYLE ***************/
.product-search-list--headline {
  @include from($desktop-large) {
    margin: 50px 0 40px 0;
    font-size: $font-size-h2;
  }
}

.product-search-list--search-container {
  position: relative;
  margin-bottom: 20px;
  max-width: 400px;
}

.product-search-list--breadcrumb {
  margin: 30px 0;
}

.product-search-list--search-input {
  padding-right: 30px;
  border: 1px solid $color-background-accent;
  font-family: $font-copy-italic;
  font-size: $font-size-lead;
  color: $color-black;
}

.product-search-list--search-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  background: sassvg("ic-search", $color-background-accent) no-repeat center;

  &.btn-search-loading {
    background: sassvg("spinner-white", $color-background-accent) no-repeat center !important;
    background-size: 16px !important;
  }
}

.product-search-list--result-info {
  color: $color-text-grey-light;
}

.product-search-list--sword {
  color: $color-text-accent;
  font-family: $font-copy-bold;
}

.product-search-list--result-info {
  p {
    display: inline-block;
  }
}

.product-search-list--title {
  margin-top: 30px;
}

.product-search-list--list {
  margin: 60px 0;
}

.product-search-list--has-results {
  .search-popup--all-link {
    display: inline-block;
  }
}