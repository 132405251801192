@import "../constants/constants";
@import "../base/mixins";

/******** grid ********/
.product-ambients--container {
  @extend .ce-full-width;
}

.product-ambients--container-inner {
  @extend .ce-container-inner-width;
}

/******** style ********/
.product-ambients--title {
  @include from($desktop) {
    font-size: $font-size-h2;
    margin-bottom: 40px;
  }
}

.product-ambients--item {
  display: block;
  margin-bottom: 50px;
  text-decoration: none;

  &:hover {
    .product-ambients--item-title {
      text-decoration: underline;
      text-decoration-color: $color-text-base;
    }
  }

  @include from($desktop) {
    margin-bottom: 0;
  }

  &-wrap {
    @include from($desktop) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }

    @include from($ultra-wide-screen) {
      grid-template-columns: repeat(3, 460px);
    }
  }
}

.product-ambients--item-image {
  border-radius: 4px;

  &-wrap {
    margin: 0 -20px; // full screen image on mobile

    @include from($tablet) {
      margin: 0;
    }
  }
}

.product-ambients--item-title {
  font-size: $font-size-h4;

  @include from($desktop-large) {
    font-size: $font-size-h3;
  }
}

.product-ambients--item-text {
  display: none;
  color: $color-text-base;

  @include from($desktop) {
    display: block;
  }
}

.product-ambients--item-link {
  @include linkWithArrowExtend(
          $arrowColor: $color-text-accent,
          $top: 11px
  );
  display: inline-block;
}