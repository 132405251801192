@import "../constants/constants";
@import "../base/mixins";


$ce-jobs-irre-padding-vertical: 35px;
$ce-jobs-irre-padding-horizontal-small: 30px;

$ce-jobs-irre-padding-horizontal: 40px;
$ce-jobs-irre-padding-vertical-small: 30px;
$padding-small: 45px $ce-jobs-irre-padding-horizontal-small $ce-jobs-irre-padding-vertical-small $ce-jobs-irre-padding-horizontal-small;
$padding-large: 50px $ce-jobs-irre-padding-horizontal $ce-jobs-irre-padding-vertical $ce-jobs-irre-padding-horizontal;
/************* GRID ***************/
.jobs-irre--trigger {
  display: grid;
  position: relative;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: flex-start;
}

.jobs-irre--container-inner {
  @extend .ce-full-width;
  margin-top: 30px !important;
}

/************** STYLE *************/
.jobs-irre--trigger {
  transition: all 300ms;
  border-top: 1px solid transparent;

  &:hover {
    cursor: pointer;
  }

  h4 {
    margin: 0;
    padding-right: 20px;
    @include from($tablet) {
      padding-right: 40px;
    }
  }

  &.accordion-active {
    border-top: 1px solid $ce-accordion-border-top;
    background-color: $color-white;

    .jobs-irre--arrow:after {
      background: sassvg("chevron_up", $color-text-red) no-repeat center right;
      background-size: 12px;
    }

    @include from($tablet) {
      .jobs-irre--arrow-close {
        display: inline-block;
      }

      .jobs-irre--arrow-open {
        display: none;
      }
    }
  }
}

.jobs-irre {
  &--assign-here {
    @include buttonBlue;

    .link-with-arrow {
      transform: translateY(-50%);
    }
  }

  &--text-blue {
    font-family: $font-copy !important;
    margin: 0;
    padding: 35px 0 10px 0;
    color: $color-text-accent;
    border-top: 1px solid $color-border-nav-grey;
  }

  &--date {
    color: $color-text-accent;
  }


  &--date {
    font-family: $font-copy-bold;
  }

  &--text-caption {
    margin-top: 25px;
    color: $color-text-red;
  }

  &--accordion-inner {
    padding: 0 $ce-jobs-irre-padding-horizontal-small;

    @include from($tablet) {
      padding: 0 $ce-jobs-irre-padding-horizontal;
    }

    p {
      font-family: $font-copy-bold;
      margin-bottom: 35px;
    }

    ul {
      margin: 30px 0;
    }
  }

  &--item {
    border-bottom: 1px solid $color-background-grey-light-accent;
    border-left: 1px solid $color-background-grey-light-accent;
    border-right: 1px solid $color-background-grey-light-accent;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &--location {
    top: 25px;
    left: $ce-jobs-irre-padding-horizontal-small;
    @include from(500px) {
      left: $ce-jobs-irre-padding-horizontal;
    }

    position: absolute;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: $font-size-sub-headline;
    color: $color-background-accent;
    font-family: $font-copy-bold;
  }

  &--trigger {
    padding: $padding-small;
    background-color: $color-background-grey-light-accent;
    @include from(500px) {
      padding: $padding-large;
    }
  }

  &--trigger-content {
    @include from($tablet) {
      margin: 0;
    }

  }

  &--arrow-close {
    display: none;
    color: $color-text-red;
  }

  &--arrow-open {
    color: $color-background-accent-secondary;
    display: none;
    @include from($tablet) {
      display: inline-block;
    }
  }

  &--arrow {
    display: inline-block;

    &:after {
      position: relative;
      display: inline-block;
      margin-left: 12px;
      transform-origin: center;
      content: '';
      top: 2px;
      width: 12px;
      height: 12px;
      background: sassvg("chevron_down", $color-background-accent-secondary) no-repeat center right;
      background-size: 12px;
    }
  }
}

