@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.product-list-full--pager {
  @extend .ce-full-width;
}

.product-list-full--pager-wrap {
  text-align: center;
}


/************** STYLE *************/
.product-list-full--pager-number,
.product-list-full--pager-dots {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-text-accent;
  font-size: $font-size-h3;
  font-family: $font-copy-bold;
  // Prevent of jumping elements
  border-bottom: 2px solid transparent;
}

.product-list-full--pager-number {
  margin: 0 2px;
  @include from($tablet) {
    margin: 0 12px;
  }

  &:hover {
    border-bottom: 2px solid $color-background-accent;
  }
}

.product-list-full--pager-prev-arrow,
.product-list-full--pager-next-arrow {
  display: inline-block;
  text-transform: uppercase;
  font-size: $font-size-small-uppercase;
  font-family: $font-copy-bold;
  color: $color-text-grey-light;
  text-decoration: none;
}

.product-list-full--pager-number-active {
  border-bottom: 2px solid $color-background-accent;
}

.product-list-full--pager-prev-arrow {
  margin-right: 10px;
  @include linkWithArrowReverseExtend($color-text-grey-light, $color-text-grey-light, 7px);
  @include from($tablet) {
    margin-right: 60px;
  }
}

.product-list-full--pager-next-arrow {
  margin-left: 10px;
  @include linkWithArrowExtend($color-text-grey-light, $color-text-grey-light, 7px);
  @include from($tablet) {
    margin-left: 60px;
  }
}
