@import "../constants/constants";
@import "../base/mixins";

/******** GRID ********/
.image-box-irre--container {
  @extend .ce-full-width;
}

.image-box-irre--container-inner {
  @extend .ce-container-inner-width-extended;
}

.image-box-irre--item-wrap {

  @include from($tablet) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }
}

.image-box-irre--item-title {
  margin: 0;

  &-wrap {
    position: absolute;
    bottom: 30px;
    left: $container-margin;
    right: $container-margin;
    @include from($desktop-large) {
      left: 40px;
      right: 40px;
      bottom: 45px;
    }
  }
}

/******** STYLES ********/
.image-box-irre--item {
  position: relative;

  &:hover {
    transition: opacity 200ms;

    .image-box-irre--item-overlay {
      opacity: 1;
    }
  }
}

.image-box-irre--item-title {
  $image-box-title-padding: 70px;
  $image-box-title-left-arrow: $image-box-title-padding - 10;
  @include linkWithArrowExtendLong(
          $left: calc(100% - #{$image-box-title-left-arrow}),
          $top: 15px
  );

  .link-with-arrow-extended {
    top: unset;
    bottom: 13px;
  }

  padding-right: $image-box-title-padding;
  z-index: 1;
}

.image-box-irre--item-link {
  display: block;
  color: $color-white;
  line-height: 0;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  @include from($tablet) {
    margin-bottom: 0;
  }
}

.image-box-irre--item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 200ms;
  opacity: 0.5;
  background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.75) 100%
  );
}
