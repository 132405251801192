@import "../constants/constants";
@import "../base/mixins";

.checkout-step1--center-inner {
  margin: auto;

  @include tablet() {
    max-width: $container-width-tablet;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: $header-grid-main;
    width: 2000px;
    //max-width: 1440px;
  }
  @include from($ultra-wide-screen) {
    grid-template-columns: $header-grid-main-wide;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.checkout-step1--inner-left {
  @extend .ce-full-width-no-margin;

  @include from($desktop-large) {
    padding-right: $header-grid-side-padding;
  }
}

.checkout-step1--inner-left-content {
  //@extend .ce-container-inner-width;
}

.checkout-step1--inner-right {
  @extend .ce-full-width-no-margin;
  background-color: $color-white;
  margin-top: 70px;
  padding-bottom: 30px !important;
  border-top: 1px solid $color-input-border-grey;

  @include between($tablet, $desktop-large) {
    margin: 0 -$container-margin;
    margin-top: 70px;
    padding: 0 $container-margin 30px $container-margin;
  }

  @include from($desktop-large) {
    margin-top: 0;
    margin-right: -1px; // hides grey line which appears because of grid with percentage widths
    padding-left: $header-grid-side-padding;
    border-top: none;
  }
}

.checkout-step1--inner-right-content {
  @include from($desktop-large) {
    position: sticky;
    top: 0;
    padding-top: 75px;
  }

  .checkbox-container {
    margin-top: 20px;
    margin-right: 0 !important;
  }
}

.checkout-step1--form {
  &-inner {

  }

  &-row {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-areas:
            "productimage productdata"
            "productmontage productmontage";
    grid-gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $color-white;
    border-bottom: 10px solid $color-background-checkout-light-grey;

    &:first-child {
      border-top: 10px solid $color-background-grey-light-accent;
    }

    @extend .ce-full-width-simple;

    @include from($xmobile) {
      grid-template-columns: 130px 1fr;
    }

    @include from($desktop-large) {
      padding: 5px $container-margin 5px $container-margin;
      border-bottom: 15px solid $color-background-checkout-light-grey;
      border-radius: 4px;

      &:first-child {
        border-top: none;
      }
    }

    &-container {
      display: grid;
      grid-area: productdata;
      grid-template-columns: auto auto;
      grid-template-areas:
          'cartPopupItemTitle cartPopupItemTitle cartPopupItemRemove'
          'cartPopupItemQuantity cartPopupItemPrice cartPopupItemPrice';
      grid-gap: 15px 10px;

      @include from($desktop-large) {
        grid-template-columns: 140px 215px 50px 115px 20px;
        grid-template-areas:
          'cartPopupItemTitle cartPopupItemPrice cartPopupItemQuantity checkoutPriceSum cartPopupItemRemove';
        grid-gap: 10px;
        justify-content: space-between;
      }
    }

  }

  &-data {
    @extend .ce-full-width-simple;
    border-top: 1px solid transparent;
    background-color: $color-white;
  }

  &-title {
    grid-area: cartPopupItemTitle;
  }

  &-quantity {
    grid-area: cartPopupItemQuantity;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;

    @include from($desktop-large) {
      display: grid;
      grid-template-columns: 18px auto;
      grid-gap: 5px;
      justify-content: left;
    }
  }

  &-price {
    display: grid;
    grid-area: cartPopupItemPrice;
    align-content: center;
    justify-items: right;

    @include from($desktop-large) {
      justify-items: left;
    }

    &-old {
      display: none;
      @include from($desktop-large) {
        display: block;
      }
    }
  }

  &-remove {
    display: grid;
    grid-area: cartPopupItemRemove;
    justify-items: right;

    @include from($desktop-large) {
      align-content: center;
    }

    &.cart-popup-form-remove-desktop {
      display: none;
      @include from($desktop-large) {
        display: grid;
      }

    }

    &.cart-popup-form-remove-mobile {
      @include from($desktop-large) {
        display: none;
      }
    }
  }

  &-image {
    aspect-ratio: 4/3;
  }
}

.checkout-step1--header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  align-items: center;

  @include until($desktop-large) {
    padding: 10px $container-margin;
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }

  @include from($desktop-large) {
    display: block;
  }
}

/****** STYLE *******/
.checkout-step1--header {
  background-color: $color-white;
  @include from($desktop-large) {
    background-color: transparent;
  }
}

/*
.checkout-step1--steps {
  position: relative;
  background: $color-white;
  border: 5px solid $color-border-nav-white;
  border-top: 5px solid $color-background-accent;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  transform: rotate(45deg);

  @include from($desktop-large) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    content: '1';
    transform: rotate(-45deg);
    justify-content: center;
    align-items: center;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    font-size: $font-size-lead;
  }
}
*/
.checkout-step1--title {
  margin: 0;
  color: $color-text-accent;

  @include from($desktop-large) {
    margin: 30px 0;
    font-size: $font-size-h2;
  }
}

.checkout-step1--form {

  &-title {
    font-family: $font-head-semi-bold;
    line-height: 1.2;

    @include from($desktop-large) {
      display: grid;
      align-items: center;
      //font-size: 12px;
    }

    @include from($wide-screen) {
      //font-size: $font-size-copy;
    }

    &-main {
      margin-bottom: 5px;
      @include from($desktop-large) {
        margin-bottom: 0;
      }
    }
  }

  &-description {
    font-size: $font-size-small;
    color: $color-text-cart-description;
    line-height: 15px;

    @include from($desktop-large) {
      display: none;
    }
  }

  &-quantity {
    &-buttons {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: 'cart-minus cart-plus';
      grid-gap: 8px;
      align-content: center;
      margin-right: 10px;

      @include from($desktop-large) {
        grid-template-columns: auto;
        grid-template-areas: 'cart-plus' 'cart-minus';
        grid-gap: 5px;
        margin-right: 0;
      }
    }
  }

  &-price {
    color: $color-text-lead;

    @include from($desktop-large) {
      display: grid;
      align-items: center;
    }

    &-loyalty,
    &-new {
      font-size: $font-size-lead;

      @include from($desktop-large) {
        font-size: $font-size-h4;
        font-family: $font-copy-bold;
        color: $color-text-product-price;
      }
    }

    &-old {
      color: $color-text-lead;
      font-size: $font-size-description;
      text-decoration: line-through;
    }

    &-loyalty {
      color: $color-background-accent-secondary !important;

      p {
        margin: 0;
      }

      small {
        font-size: 100% !important;
      }
    }
  }

  &-discount-percent {
    color: $color-text-lead;
    font-size: $font-size-description;
  }

  &-price-total {
    display: none;
    grid-area: checkoutPriceSum;

    @include from($desktop-large) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }

    &-loyalty,
    &-new {
      font-size: $font-size-h4;
      color: $color-text-lead;
      text-align: right;
    }

    &-loyalty {
      color: $color-background-accent-secondary !important;

      p {
        margin: 0;
      }

      small {
        font-size: 100% !important;
      }
    }
  }

  &-number {
    display: grid;
    align-items: center;
    color: $color-text-lead;
    font-size: $font-size-lead;
    text-align: right;
  }

  &-remove {
    @include from($desktop-large) {
      display: grid;
      align-items: center;
    }

    &-link {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-top: -4px; // align icon to top
      margin-right: -6px; // align icon to right edge
      background: sassvg('trash', $color-input-text-error) no-repeat center;

      @include from($desktop-large) {
        margin-top: unset;
        margin-right: unset;
      }
    }
  }

  &-plus {
    @extend .checkout-step1--form-quantity-button;
    grid-area: cart-plus;

    & > a {
      background: sassvg("ic-cart-plus", $color-text-grey-light) no-repeat center;
    }
  }

  &-minus {
    @extend .checkout-step1--form-quantity-button;
    grid-area: cart-minus;

    & > a {
      background: sassvg("ic-cart-minus", $color-text-grey-light) no-repeat center;
    }
  }

  &-image {

    &-wrap {
      grid-area: productimage;
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
}

.checkout-step1--form-quantity-button {
  $cart-quantity-button: 20px;
  width: $cart-quantity-button;
  height: $cart-quantity-button;
  border-radius: 3px;
  background-color: $color-background-grey-light-accent;
  text-decoration: none;

  a {
    display: block;
    height: $cart-quantity-button;
  }
}

.checkout-step1--divider {
  width: 100px;
  height: 2px;
  margin: 10px auto 30px auto;
  background-color: $color-background-cart-grey;

  @include from($desktop-large) {
    display: none;
  }
}

.checkout-step1--headline {
  margin-top: 30px;

  @include from($desktop-large) {
    font-size: $font-size-h3;
  }
}


.checkout-step1--loyalty-container {
  display: grid;
  grid-template-columns: 1fr auto;

  .pristine-error {
    position: relative;
    bottom: 0;
  }
}

.checkout-step1--loyalty-input {
  font-size: $font-size-lead;
  line-height: 1;
  padding: 8px 10px;
  border-radius: 0;
}

.checkout-step1--loyalty-calculate {
  display: grid;
  align-items: center;
  padding: 0 15px;
  background-color: $color-background-accent;
  color: $color-white !important;
  font-family: $font-copy-light !important;
  font-size: $font-size-medium !important;
  border: none;
  border-radius: 0 !important;
  line-height: 1;
  cursor: pointer;
}

.checkout-step1--loyalty-label {
  text-transform: uppercase;
  font-family: $font-copy-bold;
  font-size: $font-size-small-uppercase;
  color: $color-text-accent;
}

.checkout-step1--loyalty-message {
  margin-top: 5px;
  font-family: $font-copy-bold;
  font-size: $font-size-medium;

  &.success {
    color: $color-text-green-accent;
  }

  &.error {
    color: $color-text-red;
  }
}

.checkout-step1--center-inner {
  h3 {
    margin: 20px 0;
    font-size: $font-size-h3-mobile;
    font-family: $font-copy-bold;
  }

  .checkout-step1--loyalty-new-caption-small {
    display: block;
  }

  .statement--button {
    @extend .button-blue;
    padding: 5px 15px;
    font-size: $font-size-medium;
    cursor: pointer;

    .link-with-arrow {
      display: none;
    }
  }

  .statement--caption-small,
  .statement--headline {
    display: none;
  }

  .register-statement-city {
    display: none;
  }

  .loyalty-statement-city {
    display: inline;
  }
}

.checkout-step1--loyalty-new-confirm-container,
.loyalty-statement-city,
.checkout-step1--loyalty-new-caption-small {
  display: none;
}

.checkout-step1--steps {
  position: relative;
  background: $color-white;
  border: 5px solid $color-border-nav-white;
  border-top: 5px solid $color-background-accent;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  transform: rotate(45deg);

  @include from($desktop-large) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    content: '1';
    transform: rotate(-45deg);
    justify-content: center;
    align-items: center;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    font-size: $font-size-lead;
  }
}

.checkout-step1--table-header {
  display: none;

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 295px 135px 70px 1fr;
    grid-gap: 10px;
    justify-content: space-between;
    margin: 60px 0 15px 0;
    padding: 15px 20px;
    background-color: $color-background-grey-light-accent;
    border-radius: 4px;
  }

  @include from($wide-screen) {
    grid-template-columns: 300px 230px 70px 1fr;
  }
}

.checkout-step1--out-of-stock {
  margin-top: 15px;
  padding: 15px 20px;
  border: solid 1px $color-background-red-accent;
  border-radius: 4px;
  color: $color-text-red;

  h4{
    margin: 0 0 10px;
    font-size: 16px;
    color: $color-text-red;
  }
}

.checkout-step1--table-header-title {
  font-family: $font-copy-bold;
  font-size: $font-size-small-uppercase;
  color: $color-text-accent;
  text-transform: uppercase;

  &:last-child {
    text-align: right;
  }
}

.checkout-step1--banks {
  display: none;

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 15px $container-margin;
    background-color: $color-white;
  }
}

.checkout-step1--banks-right {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
}

.checkout-step1--center-inner {
  .checkout-step--next-step {
    background: $color-background-accent;
  }

  .checkout-step--data-message {
    margin-bottom: 10px;
  }

}

.checkout-step1--form--montage-costs--container {
  padding: 10px 0 10px 20px;
  border-top: 1px solid $color-border-steps-divider;
  grid-area: productmontage;
  @extend .montage-message-text;
  background: transparent; // Override Icon
  p {
    margin: 0;
  }
}

.checkout-step1--form--montage-costs,
.checkout-step1--form--category-message {
  padding-right: 30px;
  align-items: center;

  &,
  .checkbox-container {
    display: inline;
    color: $color-text-accent-secondary !important;
    padding-left: 20px;
    margin-left: -20px;
  }

  a {
    // padding-left: 25px;
    @include from($desktop) {
      //padding-left: 6px;
    }
  }

  &--no-padding {
    padding-left: 0 !important;
  }

  input[type="checkbox"] {
    width: auto;
  }
}

.checkout-step1--form--loyalty-message {
  display: inline;
  width: 350px;
  margin-top: -15px;
  margin-left: 20px;
  font-size: 12px;
}

.checkout-step1--form--category-message {
  display: block;
}

.checkout-step1--form--no-stock-message{
  font-size: 13px;
  font-weight: bold;
  color: $color-text-red;
  grid-column: 1/3;
  margin-left: 20px;
  font-family: $font-head-bold;
}