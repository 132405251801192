@import "../constants/constants";
@import "../base/mixins";

.carpet-container {
  padding-bottom: 300px;

  .product-list--item-wrap {
    grid-row-gap: 120px;

    @include until($mobile) {
      grid-template-columns: auto;
    }

    @include until($tablet) {
      background: transparent;
    }

  }

  .carpet--image-wrapper {
    position: relative;

    .product-list--stickers {
      top: 5px;
      right: 5px;
    }
  }

  .carpet--item-group {
    position: relative;
  }

  .carpet--item-header {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .product-list--item-image img {
    display: block;
    line-height: 1;
    margin-bottom: $container-margin/2 + $container-margin/4;
  }

  .carpet--item-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background: $color-background-grey-light-ultra;
    border-radius: 4px;
    padding: $container-margin $container-margin/2 $container-margin/2;

    &.accordion-active {
      .carpet--dimensions-wrapper {
        opacity: 1;
      }

      .carpet--item-count {
        &:before {
          transform: rotate(180deg);
        }
      }

      .carpet--first-dimension {
        padding-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .carpet--item-container {
          padding-bottom: 5px !important;
          border-bottom: 1px solid $color-background-banner-icon;
        }
      }
    }
  }

  .carpet--item-count {
    cursor: pointer;
    color: $color-background-accent-secondary;
    font-size: 12px;
    position: relative;
    padding-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    border-top: 1px solid $color-border-detail;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      background: sassvg('chevron_down', $color-background-accent-secondary) center no-repeat;
      transition: transform .2s;
      transform-origin: center;
    }
  }

  .carpet--dimensions-wrapper {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .2s, opacity .1s, padding .1s;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    div:last-of-type .carpet--item-container {
      border: none;
    }
  }

  .carpet--item-container {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 90px 1fr 1fr;
    padding-top: $container-margin;
    padding-bottom: $container-margin/4;
    border-bottom: 1px solid $color-background-banner-icon;
    position: relative;

    .carpet--price-dimension,
    .product-list--item-price-new,
    .product-list--item--loyalty-price {
      font-size: 14px;
      line-height: 17px;
      text-align: right;
    }

    .carpet--price-dimension {
      height: 19px;
      text-align: left;
    }

    .product-list--item-price-old {
      color: $color-border-input;
      font-size: 10px;
      line-height: 1;
      min-height: unset;
      text-align: right;
      position: absolute;
      bottom: 100%;
      right: 0;
    }

    .carpet--price-container {
      position: relative;
    }
  }

  .carpet--first-dimension {
    border-radius: 4px;

    .carpet--item-container {
      align-items: flex-end;
      border: none;
      padding-top: unset !important;
    }
  }

  .carpet--item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}