@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.shop-tutorial--item-wrap {
  padding-top: 30px;
  @include between($desktop, $desktop-large) {
    padding: 0 90px 15px 0;
  }
  @include from($desktop-large) {
    padding: 0 0 15px 0;
  }
}

/************** STYLE *************/
.shop-tutorial--item--image {
  margin: 0 !important;
  border: 1px solid $color-border-nav-white;
  border-radius: $image-border-radius;
}

.shop-tutorial--caption {
  padding-top: 40px;
  border-top: 1px solid $color-border-steps-divider;
}

.shop-tutorial--item {

  &-number {
    display: flex;
    margin: 50px 0 25px 0;
    justify-content: center;
    align-items: center;
    font-size: $font-size-h4;
    font-family: $font-copy-bold;
    color: $color-white;
    background-color: $color-background-accent;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    @include from($tablet) {
      margin: 75px 0 25px 0;
    }
  }

  &:first-child {
    .shop-tutorial--item-number {
      margin: 0 0 25px 0;
    }
  }

  &-headline {
    margin-top: 0;
    color: $color-text-accent;
  }
}

.shop-tutorial--register {
  text-align: center;

  &-headline {
    margin-top: 45px;
    margin-bottom: 35px;
    text-align: center;
    color: $color-text-accent;
  }

  &-link {
    @include buttonBlue;
    margin-bottom: 50px;

    .link-with-arrow {
      transform: translateY(-50%);
    }
  }
}


.shop-tutorial--contact {
  margin-top: 50px;

  &-link {
    display: block;
  }

  &-phone {
    padding: 20px 80px;
    background: url('/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/svg/ic-chat-phone.svg') no-repeat left;
    background-size: 60px;

    a {
      text-decoration: none;
      font-family: $font-copy-semi-bold;
      font-size: $font-size-h4;
      color: $color-text-accent;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-mail {
    padding: 20px 80px;
    background: url('/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/svg/ic-reply-mail.svg') no-repeat left;
    background-size: 60px;
    font-family: $font-copy-semi-bold;
    font-size: $font-size-h4;
    color: $color-text-accent-secondary;

    &:hover {
      text-decoration: none;
    }
  }
}
