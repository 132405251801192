@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.planner--item-wrap {
  padding-top: 30px;
  @include between($desktop, $desktop-large) {
    padding: 0 90px 15px 0;
  }
  @include from($desktop-large) {
    padding: 0 0 15px 0;
  }
}

/************** STYLE *************/
.planner--item--image {
  margin: 0 !important;
  border-radius: $image-border-radius;
}

.planner--item {
  strong {
    font-family: $font-copy-bold;
  }

  &-number {
    display: flex;

    justify-content: center;
    align-items: center;
    font-size: $font-size-h4;
    font-family: $font-copy-bold;
    color: $color-white;
    background-color: $color-background-accent;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin: 25px 0;
  }

  &:first-child {
    .planner--item-headline {
      margin-top: 0;
      @include from($desktop) {
        margin-top: 50px;
      }
    }
  }

  &-content {
    padding-bottom: 20px;
    @include from($desktop) {
      padding-bottom: 40px;
    }
  }

  &:last-child {
    border-bottom: 1px solid $color-border-steps-divider;

    .planner--item-content {
      padding-bottom: 40px;
      @include from($desktop) {
        padding-bottom: 70px;
      }
    }
  }
}



