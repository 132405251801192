@import "../constants/constants";
@import "../base/mixins";


.checkout-page {
  .checkbox-container {
    margin-bottom: 0;
    color: $color-text-lead;
  }
}

.checkout-step2--form-container {
  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    //margin-top: 30px;
  }
}

.checkout-step2--input-disabled {
  pointer-events: none;
  background-color: $color-background-grey-light-accent;
}

.checkout-step2--form-container-legal-entity {
  margin-bottom: 20px;
  @include from($desktop-large) {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    //margin-top: 30px;
  }
  &.animate-slide-element {
    @include from($desktop-large) {
      display: grid !important;
    }
  }
}

.checkout-step2--center-inner {
  margin: auto;
  padding-bottom: 30px;

  @include tablet() {
    max-width: $container-width-tablet;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: $header-grid-main;
    width: 2000px;
  }
  @include from($ultra-wide-screen) {
    grid-template-columns: $header-grid-main-wide;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.checkout-step2--inner-left {
  @extend .ce-full-width-no-margin;

  @include from($desktop-large) {
    padding-right: $header-grid-side-padding;
  }
}

.checkout-step2--inner-left-content {
  //@extend .ce-container-inner-width;
}

.checkout-step2--inner-right {
  @extend .ce-full-width-no-margin;
  background-color: $color-background-checkout-light-grey;
  margin-top: 10px;
  padding-bottom: 30px;

  @include between($tablet, $desktop-large) {
    margin: 0 -$container-margin;
    margin-top: 70px;
    padding: 0 $container-margin 30px $container-margin;
  }

  @include from($desktop-large) {
    margin-top: 0;
    margin-right: -1px; // hides grey line which appears because of grid with percentage widths
    padding-left: $header-grid-side-padding;
    border-top: none;
    background-color: $color-white;
  }
}

.checkout-step2--inner-right-content {
  @include from($desktop-large) {
    position: sticky;
    top: 0;
    padding-top: 75px;
  }
}

.checkout-step2--header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  align-items: center;

  @include until($desktop-large) {
    padding: 10px $container-margin;
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }

  @include from($desktop-large) {
    display: block;
  }
}

/****** STYLE *******/
.checkout-step2--header {
  background-color: $color-white;
  @include from($desktop-large) {
    background-color: transparent;
  }
}

.checkout-step2--headline {
  @include from($desktop-large) {
    font-size: $font-size-h3;
  }
}

.checkout-step2--steps {
  position: relative;
  background: $color-white;
  border: 5px solid $color-border-nav-white;
  border-top: 5px solid $color-background-accent;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  transform: rotate(45deg);

  @include from($desktop-large) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    content: '2';
    transform: rotate(-45deg);
    justify-content: center;
    align-items: center;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    font-size: $font-size-lead;
  }
}

.checkout-step2--title {
  margin: 0;
  color: $color-text-accent;

  @include from($desktop-large) {
    margin: 30px 0;
    font-size: $font-size-h2;
  }
}


/* form left */
.checkout-step2--form {
  //margin-bottom: 50px;
  padding-top: 30px;

  @include from($desktop-large) {
    padding-top: 60px;
  }
}

.checkout-step2--form-left {
  margin-bottom: $form-input-margin-bottom;
}

.checkout-step2--form-field {
  &:not(:last-child) {
    margin-bottom: $form-input-margin-bottom;
  }
}

.checkout-step2--form-alternative {
  //display: none;
  //opacity: 0.5;
  //transition: all 200ms;
  //
  //&.activate-form {
  //  opacity: 1;
  //  transition: all 200ms;
  //}
}

.checkout-step2--form-checkbox {
  //margin-bottom: 15px;

  .checkbox-container {
    display: inline-block;
    margin-right: 50px;
    @include until($desktop) {
      margin-bottom: 20px;
    }

  }
  .checkout-step--data-message {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.checkout-step2--form--left-checkbox-wrap--container {
  margin-bottom: 40px;

  .checkbox-checkmark {
    top: 10px;
    left: 8px;
  }

  label.checkbox-container {
    display: inline-block;
    margin-right: 20px;
    //border: 1px solid blue;
    background: $color-background-accent;
    color: #fff;
    padding: 10px 10px 10px 30px;
    width: 205px;
  }
}

/* form right */
.checkout-step2--divider {
  display: none;
  @include from($desktop-large) {
    display: block;
    height: 1px;
    margin-bottom: 20px;
    background-color: $color-border-nav-white;
  }
}

.checkout-step2--payment-data {
  margin-bottom: 20px;

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 20px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .checkbox-container {
    margin-bottom: 0;
    color: $color-text-lead;
    font-size: $font-size-medium;
  }
}

.checkout-step2--payment-data-left {
  margin-bottom: 10px;
}

.checkout-step2--payment-data-right {
  padding-bottom: 10px;
  border-bottom: 1px solid $color-border-filter;

  @include from($desktop-large) {
    border: none;
  }
}

.checkout-step2--payment-data-checkbox {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.checkout-step2--data-message-error {
  color: $color-text-red !important;
}


.checkout-step2--form-input-container {
  position: relative;
  display: flex;
  width: 100%;

  & > span,
  .checkout-step2--form-input-field {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .checkout-step2--form-input-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
  }

  & > span {
    white-space: nowrap;
    padding: 10px 12px;
    font-size: $font-size-lead;
    color: $color-white;
    background: $color-background-accent;
    border: 1px solid $color-input-border-grey;
    border-right: 0;

    @include until($desktop) {
      padding: 9px 10px;
      font-size: $font-size-copy;
    }
  }
}

.checkout-phone-error {
  border: 1px solid $color-background-red;
}