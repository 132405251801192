@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.planner-image {
  // Decrease spacing between header img and app icons
  @include from($desktop-large) {
    margin-top: -15px;
  }
}

.planner-image--container {
  padding-bottom: 0;
  @include from($tablet) {
    padding-bottom: 30px;
  }
}

/************** STYLE *************/
.planner-image--app {
  &-wrap {
    @include from($desktop) {
      display: grid;
      align-items: flex-end;
      grid-template-columns: auto 1fr;
      grid-column-gap: 30px;
    }

    @include from($wide-screen) {
      grid-column-gap: 70px;
    }
  }

  &-text {
    margin: 20px 0 0 0;
    font-size: $font-size-description;
    padding-left: 40px;
    background: sassvg("ic-phone-list", $color-text-accent) no-repeat left;
    background-size: 30px;

    a {
      text-transform: uppercase;
    }

    p {
      margin: 0;
    }

    @include from($desktop) {
      margin-top: 0;
    }
  }

  &-logo {
    @include from($desktop-large) {
      display: flex;
      align-items: flex-end;
    }
  }

  &-link {
    display: inline-block;
    width: 150px;
    height: 44px;
  }

  &-link-ios {
    background: url("/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/images/Icons/stickers/slider/ic-appstore.svg") no-repeat center;
    background-size: cover;
    margin-right: 15px;
  }

  &-link-android {
    background: url("/typo3conf/ext/intbuilder/Resources/Public/Frontend/src/images/Icons/stickers/slider/googleplay.png") no-repeat center;
    background-size: cover;
  }
}

.planner-image--caption {
  margin: 30px 0;
  font-size: $font-size-lead;
  color: $color-text-lead;
}

.planner-image--item-text {
  strong {
    font-family: $font-copy-bold;
  }
}


.planner-image--video {
  &-holder {
    position: relative;
    // Provides youtube video proportion for current width, padding-bottom sets height of video
    padding-bottom: 56.25%;
  }

  &-element {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;
  }

  &-headline {
    margin-top: 40px;
    color: $color-text-accent;
    @include from($desktop) {
      margin-top: 70px;
    }
  }
}



