@import "../constants/constants";
@import "../base/mixins";

.small-cart-message-text {
  display: inline-block;
  padding-left: 25px;
  color: $color-text-grey-light;
  font-size: $font-size-description;
  background: sassvg("ic-info", $color-background-red) no-repeat top 3px left;

  a {
    color: $color-text-accent;
  }
}

.small-cart-message-transport-text {
  background: sassvg('delivery-icon', $color-background-accent) no-repeat top left !important;
  background-size: 16px !important;
}

.montage-message-text {
  display: inline-block;
  padding-left: 25px;
  color: $color-text-grey-light;
  font-size: $font-size-description;
  background: sassvg("ic-info", $color-background-green) no-repeat top 3px left;

  a {
    color: $color-text-accent-secondary;
  }
}