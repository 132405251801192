/*** Simulate jquery show-hide element ***/
// works in conjuction with function hideElement(element) and function showElement(element)
.animate-show-element {
  display: block;
  animation-name: show;
  animation-duration: 0.2s;
}

.animate-hide-element {
  animation-name: hide;
  animation-duration: 0.2s;
}

.animate-fadein-element {
  transition: 400ms;
  opacity: 1;
}

.animate-fadeout-element {
  transition: 400ms;
  opacity: 0.4;
}


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.slide-element.animate-slide-element {
  transition: height .2s ease;
  overflow: hidden;
}

.slide-element:not(.animate-slide-element) {
  overflow: hidden;
  height: 0 !important;
  //display: none;
}