@import "../constants/constants";
@import "../base/mixins";

.indexed-search--container-inner {
  @extend .ce-container-inner-width;
}

form#tx_indexedsearch {
  margin-bottom: 15px;
  min-height: 40px;
}

/* milan */
.searchFor {
  display: inline-block;
  margin: 0 0 30px 0;
  color: $color-text-accent;
}

.tx-indexedsearch-res {
  padding: 25px 0;
  .search-breadcrumb {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      margin: 0 12px 0 0;
      a {
        color: $color-text-accent;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:after {
        display: block;
        position: absolute;
        right: -15px;
        top: 5px;
        content: "";
        //background: sassvg('arrow-bcd', #F49128) no-repeat center;
        width: 15px;
        height: 15px;
      }
      &.last {
        &:after {
          display: none;
        }
      }
    }
  }

  .tx-indexedsearch {
    &-title {
      margin: 0;
      color: $color-text-accent;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      a {
        color: $color-text-accent;
      }
    }
    &-redMarkup {
      background-color: #F8E71C;
    }
  }

  h4 {
    margin-bottom: 5px;
  }
}

ul.tx-indexedsearch-browsebox {
  padding-left: 0;
  margin-left: 0;
  li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 6px;
    padding: 0 5px;
    a {
      color: $color-text-accent;
    }
    &:last-child,
    &:first-child {
      border: none;
    }
    &.tx-indexedsearch-browselist-currentPage {
      //border: 1px solid #3068B4;
    }
    &:before {
      display: none;
    }
  }
}

.tx-indexedsearch-browsebox.results-out-of {
  margin-bottom: 20px;
  color: $color-text-grey-light;
  font-size: $font-size-lead;
  strong {
    color: $color-text-accent;
  }
}

.tx-indexedsearch-headline {
  margin-top: 0;
}

.tx-indexedsearch-title {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.indexed-search--header {
  margin: 20px 0 50px 0;
}

.product-search-list--result-info {
  p {
    margin: 0;
  }
}