@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.product-list-full--select {
  @extend .default-sort-select;

  &-container {
    display: none;
    @include from($desktop) {
      display: grid;
      align-items: center;
    }
  }
}

.product-list-full--date {
  display: inline-block;
}

.product-list-full--header {
  margin-bottom: 30px;
  @include from($desktop) {
    display: grid;
    grid-template-columns: 1fr 200px;
    justify-content: space-between;
    grid-column-gap: 30px;
  }
}


.product-list-full--headline-wrap {
  @include from($desktop) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 60px;
  }
}


.product-list--header-title-wrap.product-list-title-new,
.product-list--header-title-wrap.product-list-title-web-discount,
.product-list--header-title-wrap.product-list-title-action,
.product-list--header-title-wrap.product-list-title-outlet {
  @include from($desktop) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 60px;
  }
}

.product-list--header-title-wrap {
  .product-list--header-divider {
    display: none;
  }
}
