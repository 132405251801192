@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.location-teaser--container {
  @extend .ce-full-width;
  // Full width under tablet screen size
  padding: 0 !important;
  margin-bottom: 40px !important;
}

.location-teaser--container-inner {
  @extend .ce-container-inner-width;
  display: grid;
  grid-template-columns: 1fr;
  @include from($desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

/************** STYLE *************/

.location-teaser--search-wrap {
  padding: 40px 20px 40px 20px;
  background-color: $color-background-grey-light-accent;
  order: -1;
  @include from($desktop) {
    padding: 70px 60px;
    order: 0;
    border-left: 1px solid $color-border-map-divider;
  }
  @include from($desktop-large) {
    padding: 90px 80px 90px 80px;
  }
}

.location-teaser--search-headline {
  margin-top: 0;
  font-size: $font-size-h3;
  @include from($desktop) {
    font-size: $font-size-h2;
  }
}

.location-teaser--map-image {
  line-height: 0; // removes empty space below image
}

.location-teaser--search-text {
  margin-bottom: 0;
}

.location-teaser--link {
  display: inline-block;
  margin-top: 35px;
  font-size: $font-size-copy;
  text-decoration: none;
  @include linkWithArrowExtend($color-text-accent, $color-text-accent, 11px);
  font-family: $font-copy-bold;
}

// Don't delete, it's extended on location list
.location-teaser--search-input {
  margin-top: 35px;
  padding-left: 54px;
  background: sassvg('pin-icon') no-repeat 16px;
  font-size: $font-size-h4;
  line-height: $line-height-headline;
  font-family: $font-copy-bold;
  border: 1px solid $color-border-input;
  background-color: $color-white;

  &::placeholder {
    color: $color-black;
    font-family: $font-copy-bold;
    font-size: $font-size-h4;
  }
}