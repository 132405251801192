@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.multimedia-irre--item-wrap {
  display: grid;
  margin: 30px 0;
  grid-gap: 20px;
  grid-template-columns: 1fr;


  @include between(400px, 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @include from(600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }


  @include from($tablet) {
    grid-gap: 40px;
  }

}

/************** STYLE *************/
.multimedia-irre--item {
  position: relative;
}

.multimedia-irre--item-image {
  margin: 0 !important;
}

.multimedia-irre--item-link {
  display: inline-block;
  height: 100%;
  position: relative;
}

.multimedia-irre--item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  background: sassvg("play", $color-white) no-repeat center;
  background-size: 110px;
}