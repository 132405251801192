@import "../constants/constants";
@import "../base/mixins";

.image-teaser {
  margin-bottom: 50px !important;
  margin-top: 50px !important;

  @include from($desktop-large) {
    margin-bottom: 80px !important;
    margin-top: 80px !important;
  }

  & .image-teaser--container {
    @extend .ce-container-inner-width;
  }

  & .image-teaser--inner {
    position: relative;
  }

  & .image-teaser--image-wrapper {
    @include from($desktop-large) {
      max-width: 770px;
    }

    @include from($wide-screen) {
      max-width: 940px;
    }
  }

  & .image-teaser--content {
    position: relative;
    margin: -34px auto 0;
    width: calc(100% - 48px);
    padding: 24px;
    background-color: $color-white;
    box-shadow: 0 5px 80px 0 rgba(0, 0, 0, 0.05);

    @include from($desktop) {
      padding: $container-margin * 3;
      box-shadow: 10px 0 80px 0 rgba(0, 0, 0, 0.05);
    }

    @include from($desktop-large) {
      position: absolute;
      width: 460px;
      bottom: 75px;
      top: unset;
    }

    @include from($ultra-wide-screen) {
      width: 560px;
      bottom: 68px;
    }

    & .image-teaser--content-header {
      color: $color-text-accent;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;

      @include from($desktop) {
        font-size: 42px;
      }
    }

    & .image-teaser--content-subheader {
      color: $color-text-grey-light;
      font-size: 16px;
      margin-bottom: 4px;

      @include from($desktop) {
        font-size: 24px;
      }

      & p {
        margin: 0 0 -5px 0;
      }
    }
  }

  & .image-teaser--inner-left {
    & .image-teaser--content {
      @include from($desktop-large) {
        right: 0;
      }
    }
  }

  & .image-teaser--inner-right {
    & .image-teaser--image-wrapper {
      margin-left: auto;
    }

    & .image-teaser--content {
      @include from($desktop-large) {
        left: 0;
      }
    }
  }
}