@import "../constants/constants";
@import "../base/mixins";

.checkout-page--container {

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  input:not(.checkout-step--next-step) {
    font-size: $font-size-lead;
    color: $color-text-lead
  }

  @include until($desktop) {
    input:not(.checkout-step--next-step) {
      padding: 9px 10px;
    }
  }


}

.checkout-page--left {
  display: none;

  @include from($desktop-large) {
    display: block;
    background-color: $color-background-checkout-light-grey;
  }
}

.checkout-page--right {
  display: none;

  @include from($desktop-large) {
    display: block;
  }
}

.checkout-page--center {
  max-width: none;
  padding: 0;
  background-color: $color-background-checkout-light-grey;
  @include until($desktop) {
    padding: 0 $container-margin;
  }
  @include until($desktop-large) {
    padding: 0 40px;
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.checkout-step--select {
  padding: 10px;

  @include from($desktop) {
    padding: 14px 10px;
  }
}

.checkout-step--data-message {
  margin-top: 10px;
  padding-left: 20px;
  background: sassvg("ic-info", $color-text-red) no-repeat top 1px left;
  color: $color-text-grey-light;
  font-size: $font-size-medium;
  line-height: $line-height-reduced;
  font-family: $font-copy-italic;

  @include from($desktop-large) {
    margin-bottom: 20px;
  }

  & > p:first-child {
    margin-top: 0;
  }
}

.checkout-step--note {
  @include from($desktop-large) {
    margin: 20px 0;
  }
}

.checkout-step--note {
  margin-top: 20px;
  color: $color-text-grey-light;
  font-size: $font-size-medium;
  line-height: $line-height-reduced;
  font-family: $font-copy-italic;

  @include from($desktop-large) {
    margin-top: 0;
  }

  &-text {
    margin-bottom: 4px;
  }

  & > p:first-child {
    margin-top: 0;
  }
}

/* continue checkout */
.checkout-step--next {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
      'checkoutNextButton'
      'checkoutContinue';
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @include from($xmobile) {
    grid-template-columns: 1fr auto;
    grid-template-areas:'checkoutContinue checkoutNextButton';
  }

  @include from($desktop-large) {
    grid-template-columns: auto;
    grid-template-areas:
      'checkoutNextButton'
      'checkoutContinue';
    grid-gap: 20px;
    margin-top: 50px;
  }
}

.checkout-step--next-continue-container {
  grid-area: checkoutContinue;
}

.checkout-step--next-button-container {
  grid-area: checkoutNextButton;
}

.checkout-step--next-step {
  @include buttonBlue();
  font-size: $font-size-medium;
  background: sassvg("arrow-right", $color-white) no-repeat center right 40px, $color-background-accent;
  cursor: pointer;

  @include from($desktop-large) {
    font-size: $font-size-copy;
    font-family: $font-copy;
  }

  &.disable-checkout-step {
    background: sassvg("arrow-right", $color-white) no-repeat center right 40px, $color-background-grey;
    cursor: none;
    pointer-events: none;
  }
}

.checkout-step--continue-link {
  @include from($desktop-large) {
    font-size: $font-size-lead;
  }
}

/* checkout steps */
.checkout-step--steps-full {
  display: none;

  @include from($desktop-large) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, auto);
  }
}

.checkout-step--steps-full-step {
  display: grid;
  justify-content: center;
  z-index: 1;

  &.first-child {
    justify-content: start;
  }

  &.last-child {
    justify-content: end;
  }
}

.checkout-step--steps-full-line {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 10px));
  left: 0;
  right: 0;
  height: 1px;
  background-color: $color-background-vertical-line;
}

.checkout-step--steps-full-step-number {
  display: grid;
  width: 36px;
  height: 36px;
  margin: auto;
  justify-self: center;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  border: 1px solid $color-background-vertical-line;
  font-size: $font-size-h4;
  font-family: $font-copy-bold;
  color: $color-text-grey-light;
  line-height: 1;

  &.active {
    border: 1px solid $color-background-accent;
    background: sassvg("check", $color-white) no-repeat center, $color-background-accent;
  }

  &.current {
    width: 44px;
    height: 44px;
    border: 3px solid $color-background-accent;
    background-color: $color-border-nav-white;
    color: $color-text-accent;
  }
}

.checkout-step--steps-full-step-label {
  color: $color-text-lead;
  font-size: $font-size-medium;
  margin-top: 3px;

  &.active {
    color: $color-text-accent;
  }

  &.current {
    color: $color-text-accent;
  }
}

.checkout-step--form-label {
  color: $color-text-accent;
  font-size: $font-size-small-uppercase;
  font-family: $font-copy-bold;
  text-transform: uppercase;
}


.checkout-step--table-row {
  display: grid;
  grid-template-columns: auto 120px;
  grid-gap: 10px;
  border-bottom: 1px solid $color-background-cart-grey;
  padding: 5px 0;

  @include from($desktop-large) {
    padding: 8px 0;
  }

  &.checkout-difference-success {
    div {
      color: $color-text-green;
    }
  }

  &.last-child {
    border-bottom: none;
  }
}

.checkout-step--table-row-label {
  display: grid;
  justify-content: left;
  align-items: center;
  font-family: $font-copy-bold;
  font-size: $font-size-x-small;
  color: $color-text-accent;
  text-transform: uppercase;

  &.checkout-step--label-accent {
    color: $color-text-accent;
    font-size: $font-size-lead;
    font-family: $font-copy-bold;
    text-transform: none;
  }

  &.checkout-step--label-accent-secondary {
    color: $color-text-red;
    font-size: $font-size-lead;
    font-family: $font-copy-bold;
    text-transform: none;
  }
}

.checkout-step--table-row-value {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  justify-content: right;
  align-items: center;
  font-size: $font-size-lead;
  color: $color-text-lead;

  &.checkout-step--value-accent {
    font-family: $font-copy-bold;
  }

  &.checkout-step--value-accent-secondary {
    font-family: $font-copy-bold;
    color: $color-text-red;
  }
}

.checkout-step--text {
  margin-top: 30px;
  font-size: $font-size-lead;
  color: $color-text-lead;

  @include from($desktop-large) {
    margin-top: 60px;
  }
}

.checkout-step--textarea {
  width: 100%;
  padding: 10px;
}

.checkout-step3--input {

}

.checkout-step--cart--discount--description {
  color:#bbb;
}