@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.location-list--container {
  @extend .ce-full-width;
  // Full width under tablet screen size
  margin-top: 30px !important;
  padding: 0 !important;
}

.location-list--container-inner {
  @extend .ce-container-inner-width;
}

.location-list--header {
  display: grid;
  grid-template-columns: 1fr;
  padding: 30px 20px;
  @include from($tablet) {
    padding: 30px 0;
  }
  @include from($desktop) {
    grid-template-columns: auto 570px;
    align-items: end;
  }
}

.location-list--main-location-content {
  display: grid;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-border-map-divider;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  @include from($tablet) {
    padding-bottom: 0;
    border-bottom: unset;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  @include from($desktop-large) {
    grid-column-gap: 55px;
    grid-template-columns: auto 1fr;
  }

}

.location-list--item-inner {
  display: grid;
  padding-bottom: 15px;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  border-bottom: 1px solid $color-border-map-divider;
  @include from($tablet) {
    padding-bottom: 0;
    display: inline-block;
    border-bottom: unset;
  }
}

/************** STYLE *************/
// location list main grid
#location-list--map {
  height: 470px;
  @include from($desktop) {
    height: 800px;
  }
}


.location-list--item-data-header {
  @include from($tablet) {
    // Because of aligning with other items we are setting explicit height
    height: 90px;
    border-bottom: 1px solid $color-border-nav-white;
  }
}

.location-list--headline {
  margin-top: 0;
  @include from($desktop) {
    margin: 0;
  }
}

.location-list--search-input {
  @extend .location-teaser--search-input;
  margin: 0 !important;
}

.location-list--breadcrumb {
  position: relative;

  .page-back-arrow {
    position: absolute;
    left: -30px;
  }
}

// Locations container
.location-list--locations {
  position: relative;
  margin-top: 20px;
  border-top: 1px solid transparent;
  padding: 0 20px;

  &-headline {
    display: none;
    @include from($tablet) {
      display: block;
    }
  }

  &-divider {
    display: none;
    @include from($tablet) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-border-nav-white;
    }
    @include from(1560px) {
      left: -50px;
      right: -50px;
    }
  }

  &-headline {
    margin-bottom: 60px;
  }

  @include from($tablet) {
    padding: 0;
  }
}

.location-list--item-wrap {
  display: grid;
  grid-row-gap: 20px;


  &.location-list--item-opening-times-height {
    .location-list--item-opening-times {
      height: 235px !important;
    }
  }


  @include from($tablet) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include desktopLarge {
    grid-gap: 30px;
  }

  @include from($desktop) {
    grid-row-gap: 70px;
  }

  @include from($desktop-large) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.location-list--item-image-link {
  display: block;
}

// Filtering locations based on displayed marker on map
.location-list--item {
  display: none;
}

// Filtering locations based on displayed marker on map
.location-list--show {
  display: block;
}

.location-list--item-headline-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.location-list--item-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0 8px 0;
  font-size: $font-size-medium;
  text-transform: uppercase;
  color: $color-text-accent;

  &:after {
    display: inline-block;
    top: 5px;
    height: 14px;
    width: 17px;
    background: sassvg("arrow-right", $color-background-accent) no-repeat center;
    content: '';
  }

  @include from($tablet) {
    margin: 8px 0;
    font-size: $font-size-h4;
    &:after {
      display: none;
    }
  }
}

.location-list--item-text {
  font-size: $font-size-medium;
  @include from($tablet) {
    font-size: $font-size-copy;
  }
}


.location-list--item-opening-times,
.location-detail--store-opening-times,
.location-detail--store-phone {
  margin-bottom: 20px;

  p {
    margin-top: 0;
  }

}

.location-list--item-phone {
  // Use until to avoid useless overriding
  @include until($tablet) {
    color: $color-text-accent;
    margin-top: 5px;
    font-family: $font-copy-bold;
  }
}

.location-detail--store-email-label,
.location-list--item-phone-label,
.location-list--item-opening-times-label,
.location-detail--store-phone-label,
.location-detail--store-opening-times-label,
.location-detail--store-input-address-label,
.location-detail--store-input-city-label {
  text-transform: uppercase;
  color: $color-text-accent;
  font-size: $font-size-x-small;
  font-family: $font-copy-bold;
  margin-bottom: 0;
  @include from($desktop) {
    margin-bottom: 5px;
  }
}

.location-list--item-mail-label {
  @include from($desktop) {
    display: none;
  }
}


.location-detail--store-opening-times,
.location-detail--store-phone {
  @include until($desktop) {
    font-size: $font-size-lead;
    font-family: $font-copy-bold;
    color: $color-black !important;
  }
}

.location-detail--store-mail {
  a {
    @include until($desktop) {
      font-size: $font-size-lead;
      font-family: $font-copy-bold;
      color: $color-black !important;
    }
  }
}

.location-list--item-phone-label {
  display: none;
  @include from($tablet) {
    display: block;
  }
}

.location-list--item-opening-times-label,
.location-list--item-opening-times {
  display: none;
  @include from($tablet) {
    display: block;
  }
}

.location-list--item-opening-times {
  @include from($tablet) {
    // Because of aligning with other items we are setting explicit height
    height: 65px;
  }
}

.location-list--item-mail,
.location-detail--store-mail {
  font-size: $font-size-lead;
}

.location-list--item-mail {
  display: none;
  @include from($tablet) {
    margin-top: 20px;
    display: block;
    font-size: $font-size-lead;
  }
}

.location-list--item-info {
  max-width: 270px;
}

.location-list--google-map,
.location-list--item-image {
  border: 1px solid $color-border-nav-white;
  border-radius: $image-border-radius;
}

.location-list--main-location {
  padding: 0 20px;
  margin-top: 30px;

  h2 {
    margin-top: 0;
  }

  &-phone {
    margin: 0 0 5px 0;
  }

  &-headline,
  &-subheadline {
    margin-top: 0;
    @include until($tablet) {
      font-size: $font-size-lead;
    }
  }

  &-headline {
    margin-bottom: 13px;
  }

  &-subheadline {
    color: $color-text-grey-light;
  }

  &-phone-label {
    display: none;
    @include from($tablet) {
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 10px;
      font-size: $font-size-small-uppercase;
      color: $color-text-accent;
      font-family: $font-head-bold;
    }
  }

  &-phone {
    @include until($tablet) {
      font-size: $font-size-copy;
      font-family: $font-head-bold;
    }
  }

  &-phone-wrap {
    margin-top: 20px;
    @include from($tablet) {
      margin-top: 60px;
    }
  }

  @include from($tablet) {
    padding: 0;
    margin-top: 70px;
  }
}