@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/


/************** STYLE *************/
.text {
  padding-bottom: 40px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-family: $font-copy-bold;
    font-weight: normal;
    .is-cyrillic & {
      font-weight: bold !important;
    }
  }

  table {
    tr {
      //display: grid;
      //grid-template-columns: 120px 1fr;
      //grid-gap: 15px;
      //@include from(360px) {
      //  grid-template-columns: 160px 1fr;
      //}
      //@include from($tablet) {
      //  grid-gap: 55px;
      //}
    }

    th {
      position: relative;
      // Fix aligning of td and th
      padding: 5px 20px 5px 5px;
      font-size: $font-size-x-small;
      vertical-align: baseline !important;
    }
    td {
      padding: 5px 20px 5px 5px;
      vertical-align: baseline !important;
    }
  }
}

// Add margin bottom only to li that only appears in CE Text in two-columns page layout
div[class^="two-columns"] {
  .text {
    li {
      margin-bottom: 10px;
    }
  }
}

