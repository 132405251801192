@import "../constants/constants";
@import "../base/mixins";

$blog-teaser-item-width: 1000px; // Slide (image) width

/*
 * Must be same as padding in splider.js ( let imageTeaserTop )
 * space between slide and edge is min 320px
 */
$blog-teaser-slider-padding: 320px;

/*
 * Horizontal margin of bottom slider
 * for centering with top slider
 */
$blog-teaser-slider-bottom-margin: $blog-teaser-slider-padding * 2;

$blog-teaser-item-breakpoint: 1655px; // 1655px same as breakpoint in splider.js ( let imageTeaserTop )

/******** grid ********/
.blog-teaser-large--container {
  @extend .ce-full-width;
  padding: 0 !important;
}

.blog-teaser-large--slider-top {
  @include from($desktop) {

    // Added because of arrow positioning
    padding-bottom: 55px;
    margin-bottom: -55px;
  }
}

.blog-teaser-large--header,
.blog-teaser-large--slider-bottom {
  position: relative;
  max-width: $blog-teaser-item-width;
  padding: 0 20px;
  margin: auto;
  pointer-events: none;

  @include from($desktop) {
    padding: 0;
  }

  @include between($desktop, $blog-teaser-item-breakpoint) {
    margin: 0 $blog-teaser-slider-padding;
  }

  .splide__list {
    margin: auto !important;
    width: 100%;
    max-width: $blog-teaser-item-width;
  }

}

.blog-teaser-large--header {
  margin-bottom: 30px;

  @include between($desktop, $blog-teaser-item-breakpoint) {
    margin-bottom: 30px;
  }
}

/****** style ********/
.blog-teaser-large--subheadline {
  font-family: $font-copy-bold;
  font-size: $font-size-h4; 
  color: $color-text-accent;
}

.blog-teaser-large--headline {
  margin: 10px 0 0 0;
  font-size: $font-size-h4;
}

.blog-teaser-large--slide-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: $color-background-accent;
  transition: background 200ms;
  z-index: 1;

  @include from($desktop) {
    position: unset;
    top: unset;
    transform: unset;
  }

  &-wrap {
    @include from($desktop) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: $blog-teaser-item-width;
      padding-right: 0;
      text-align: right;
    }

    @include between($desktop, $blog-teaser-item-breakpoint) {
      width: calc(100% - #{$blog-teaser-slider-bottom-margin});
    }

    @include between($desktop, $desktop-large) {
      display: flex;
      justify-content: space-between;
      bottom: 50%;
      z-index: 1;
    }
  }

  &.splide__arrow--prev {
    background: $color-white sassvg("arrow-left", $color-text-accent) no-repeat center;

    @include from($desktop) {
      background: $color-background-slider-arrow-grey sassvg("arrow-left", $color-white) no-repeat center;
    }

    @include between($desktop, $desktop-large) {
      margin-left: -20px;
    }

  }

  &.splide__arrow--next {
    margin-left: 20px;
    background: $color-white sassvg("arrow-right", $color-text-accent) no-repeat center;

    @include from($desktop) {
      background: $color-background-slider-arrow-grey sassvg("arrow-right", $color-white) no-repeat center;
    }

    @include between($desktop, $desktop-large) {
      margin-right: -20px;
    }
  }

  &:hover {
    transition: background 200ms;
    background-color: $color-background-grey-light-accent;

    @include from($desktop) {
      background-color: $color-background-accent;
    }
  }
}


.blog-teaser-large--slider-top {

  @include from($desktop) {
    .splide__slide {
      opacity: 0.2;
      transition: opacity 500ms;
    }

    .splide__slide.is-active.is-visible {
      transition: opacity 500ms;
      opacity: 1;
    }

    // next slider (after active) opacity
    .splide__slide.is-active.is-visible + li.splide__slide {
      transition: opacity 500ms;
      opacity: 0.2;
    }
  }


  // on mobile resolutions
  // there is blink during slide sweep
  // Changing opacity to 0 fixes that bug
  //
  // important!
  // Turning off pagination during slider init
  // won't fix that bug
  .splide__pagination {
    opacity: 0;
    visibility: hidden;
  }
}

.blog-teaser-large--slide-content {
  padding-top: 20px;

  @include from($desktop) {
    padding-right: 180px;
  }

  @include between($desktop, $desktop-large) {
    padding-right: 0;
  }
}

.blog-teaser-large--slide-headline {
  margin-top: 0;
  color: $color-text-headline;
  pointer-events: all;

  &-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $color-text-headline;
    }
  }
}

.blog-teaser-large--slide-link {

  pointer-events: all;
  @include linkWithArrowExtend();

  &-wrap {
    margin-top: 20px;
  }

}

