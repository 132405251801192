@import "../constants/constants";
@import "../base/mixins";


.checkout-step3--form-container {
  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    margin-top: 30px;
  }
}

.checkout-step3--center-inner {
  margin: auto;
  padding-bottom: 30px;

  @include tablet() {
    max-width: $container-width-tablet;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: $header-grid-main;
    width: 2000px;
  }
  @include from($ultra-wide-screen) {
    grid-template-columns: $header-grid-main-wide;
  }
  @include desktopLarge() {
    max-width: $container-width-desktop-large;
  }
  @include widescreen() {
    max-width: $container-width-widescreen;
  }
  @include ultraWidescreen() {
    max-width: $container-width-ultra-widescreen;
  }
}

.checkout-step3--inner-left {
  @extend .ce-full-width-no-margin;

  @include from($desktop-large) {
    padding-right: $header-grid-side-padding;
  }
}

.checkout-step3--inner-left-content {
  //@extend .ce-container-inner-width;
}

.checkout-step3--inner-right {
  @extend .ce-full-width-no-margin;
  background-color: $color-background-checkout-light-grey;
  margin-top: 10px;
  padding-bottom: 30px;

  @include between($tablet, $desktop-large) {
    margin: 0 -$container-margin;
    margin-top: 70px;
    padding: 0 $container-margin 30px $container-margin;
  }

  @include from($desktop-large) {
    margin-top: 0;
    margin-right: -1px; // hides grey line which appears because of grid with percentage widths
    padding-left: $header-grid-side-padding;
    border-top: none;
    background-color: $color-white;
  }
}

.checkout-step3--inner-right-content {
  @include from($desktop-large) {
    position: sticky;
    top: 0;
    padding-top: 75px;
  }
}

.checkout-step3--header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  align-items: center;

  @include until($desktop-large) {
    padding: 10px $container-margin;
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }

  @include from($desktop-large) {
    display: block;
  }
}

/****** STYLE *******/
.checkout-step3--header {
  background-color: $color-white;
  @include from($desktop-large) {
    background-color: transparent;
  }
}

.checkout-step3--headline {
  @include from($desktop-large) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: $font-size-h3;
  }
}

.checkout-step3--steps {
  position: relative;
  background: $color-white;
  border: 5px solid $color-border-nav-white;
  border-top: 5px solid $color-background-accent;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  transform: rotate(45deg);

  @include from($desktop-large) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    content: '3';
    transform: rotate(-45deg);
    justify-content: center;
    align-items: center;
    color: $color-text-accent;
    font-family: $font-copy-bold;
    font-size: $font-size-lead;
  }
}

.checkout-step3--title {
  margin: 0;
  color: $color-text-accent;

  @include from($desktop-large) {
    margin: 30px 0;
    font-size: $font-size-h2;
  }
}

//----------------------------------------------------//
.checkout-step3--table {
  margin-top: 40px;
}

.checkout-step3--column-container {
  position: relative;

  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: 30px;
    margin-bottom: $form-input-margin-bottom;
  }

  &.three-columns {
    margin-bottom: 40px;
    @include from($desktop-large) {
      position: relative;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      margin-bottom: $form-input-margin-bottom;
    }
  }
}

.checkout-step3--grid-helper {
  @include from($desktop-large) {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: 30px;
  }
}

.checkout-step3--input-helper {
  display: none;
  @include from($desktop-large) {
    display: block;
  }
}

.checkout-step3--center-inner {
  .checkout-step--data-message {
    color: $color-text-red;
  }
}

.checkout-step3--section {
  border-bottom: 1px solid $color-background-vertical-line;

  &.last-child {
    border-bottom: none;
  }
}

.checkout-step3--row {
  margin-bottom: 40px;

  @include from($desktop-large) {
    margin-bottom: 20px;
  }
}

.checkout-step3--column {
  margin-bottom: 10px;
}

.checkout-step3--column-voucher {
  margin-bottom: 20px;
  margin-right: 30px;

  @include from($desktop-large) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.checkout-step3--column-check {
  margin-bottom: 20px;
  margin-right: 30px;

  @include from($desktop-large) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.checkout-step3--add-voucher {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

}

.checkout-step3--add-voucher-button {
  position: relative;
  width: 38px;
  height: 38px;
  margin-right: 15px;
  background-color: $color-background-green;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 2px;
    background-color: $color-white;
    content: '';
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 16px;
    background-color: $color-white;
    content: '';
  }
}

.checkout-step3--table-row-label {
  color: $color-text-grey-light;
  font-size: $font-size-medium;
}

.checkout-step3--input-date {
  margin-right: 30px;
  padding: 6px 10px !important;
  font-size: $font-size-lead;
  color: $color-text-lead;
  line-height: 1.4;

  @include from($desktop-large) {
    padding: 12px 10px !important;
    margin-right: 0;
  }
}

.checkout-step3--select {
  margin-right: 30px;

  @include from($desktop-large) {
    margin-right: 0;
  }
}

.checkout-step3--remove {
  position: absolute;
  bottom: 10px;
  right: -6px;
  width: 25px;
  height: 25px;
  padding-left: 20px;
  background: sassvg("trash", $color-background-red) no-repeat center;
  cursor: pointer;

  @include from($desktop-large) {
    position: initial;
    bottom: unset;
    right: unset;
    width: 160px;
    align-self: center;
    margin-top: 15px;
    background: sassvg("trash", $color-background-red) no-repeat center left;
  }

  &-text {
    display: none;

    @include from($desktop-large) {
      display: inline;
      color: $color-text-red;
      font-size: $font-size-medium;
    }
  }
}

.checkout-step3--new-checks {
  position: relative;
}

.checkout-step3--check-remove {
  position: absolute;
  bottom: 8px;
  right: -6px;
  width: 25px;
  height: 25px;
  background: sassvg("trash", $color-background-red) no-repeat center;
  cursor: pointer;

  @include from($desktop-large) {
    bottom: 12px;
    right: -35px;
    padding-left: 20px;
    align-self: center;
  }

  &-text {
    display: none;

    @include from($desktop-large) {
      display: inline;
      color: $color-text-red;
      font-size: $font-size-medium;
    }
  }
}

.checkout-step3--voucher-container {
  position: relative;
}

.checkout-step3--voucher-remove {
  position: absolute;
  bottom: 10px;
  right: -6px;
  width: 25px;
  height: 25px;
  background: sassvg("trash", $color-background-red) no-repeat center;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include from($desktop-large) {
    width: 120px;
    padding-left: 20px;
    bottom: 12px;
    right: 50px;
    background: sassvg("trash", $color-background-red) no-repeat center left;
  }
}

.hidden-field {
  display: none !important;
}

.voucher-error {
  border: 1px solid $color-background-red;
}