@import "../constants/constants";
@import "../base/mixins";

.content-main section,
footer.footer {
  // Critical disables all CE's due to flash
  // The CE's will be activated when main.css is loaded
  opacity: 1;
  // Enable click on hidden elements, e.g. footer
  pointer-events: auto;
}


.download-link-pdf {
  display: inline-flex;
  padding-left: 30px;
  background: sassvg('download', $color-background-accent) no-repeat left;
  background-size: 20px;
}

.lead-blue {
  display: inline-block;
  color: $color-text-accent !important;
  font-size: $font-size-lead !important;
  font-family: $font-head-semi-bold !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.lead-blue-text {
  font-size: $font-size-lead-medium !important;
  color: $color-text-accent !important;
}


.two-columns--right-bottom {
  padding-bottom: 50px;
}

.two-columns-gray--right,
.two-columns-no-header--right,
.two-columns-gray-header--right,
.two-columns--right-bottom {
  .text:first-child {
    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type {
      margin-top: 0;
    }
  }

  // Adds 100% width due to parent's grid setting
  // If no width is set for children, the width will be set to max child item's width
  width: 100%;
}

// Two columns page layout, header images
.two-columns--right-top-image,
.two-columns-gray-header--right-image {
  border-radius: $image-border-radius;
}

.two-columns-gray--headline {
  margin-bottom: 5px !important;
  @include from($tablet) {
    margin-bottom: 45px !important;
  }
}


.button-blue-download {
  position: relative;
  display: inline-block;
  background: sassvg('download', $color-white) no-repeat right 15px top 17.5px, $color-background-accent;
  background-size: 25px 20px;
  text-decoration: none;
  color: $color-white;
  font-family: $font-copy-semi-bold;
  font-size: $font-size-lead;
  padding: 15px 50px 15px 15px;
  margin-top: 5px;

  @include from($tablet) {
    margin-top: 45px;
  }

  @include from($xsmobile) {
    background: sassvg('download', $color-white) no-repeat right 25px top 17.5px, $color-background-accent;
    padding: 15px 65px 15px 35px;
  }

  @include from($wide-screen) {
    background: sassvg('download', $color-white) no-repeat right 35px top 17.5px, $color-background-accent;
    padding: 15px 90px 15px 35px;
  }

  &--small-text {
    font-size: $font-size-medium;
    color: $color-white;
    opacity: 0.7;
    margin-left: 15px;

    @include from($wide-screen) {
      margin-left: 30px;
    }
  }
}


.input-error-text {
  color: $color-input-text-error;
  font-size: $font-size-error-input;
}

.error-text {
  color: $color-input-text-error;
}

.vertical-center-align {
  display: grid;
  align-items: center;
}

.button-blue {
  position: relative;
  display: inline-block;
  background-color: $color-background-accent;
  text-decoration: none;
  padding: 15px 80px 15px 40px;
  color: $color-white;
}

.default-sort-select {
  @include from($desktop) {
    display: inline-block;
    padding: 12px 40px 12px 15px;
    font-size: $font-size-medium;
    color: $color-text-lead;
    background: $color-background-grey-light-accent sassvg("chevron_down", $color-background-grey-light) no-repeat center right 15px;
    cursor: pointer;
    border: none;
  }
}


.text-lead {
  font-size: $font-size-lead;
  color: $color-text-accent;
}

.page-back-arrow {
  display: none;

  @include from($desktop) {
    display: inline-block;
    width: 30px;
    height: 20px;
    background: sassvg("arrow-back", $color-background-accent) no-repeat center left;
    color: $color-text-accent;
    text-decoration: none;
  }

  span {
    padding-left: 25px;
  }

}

select {
  background: $color-white sassvg("chevron_down") no-repeat center right 15px;
}

.desktop {
  display: none;

  @include from($desktop) {
    display: block;
  }
}

.mobile {
  display: block;

  @include from($desktop) {
    display: none;
  }
}

// Accordion content class
.accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-trigger-style {
  padding: 15px 30px 15px 0;
  font-family: $font-head-bold;
  font-size: $font-size-lead;
  color: $color-background-grey;
  border-bottom: 1px solid $color-input-border-grey;
  background: sassvg("chevron_down") no-repeat center right 15px;
  align-items: center;
  cursor: pointer;

  &.accordion-active {
    color: $color-text-accent;
    background: sassvg("chevron_up", $color-text-accent) no-repeat center right 15px;
  }
}

.two-columns--sidenav-mobile {
  font-size: $font-size-header-nav-mobile;
  font-family: $font-copy-bold;
  border: none;
  border-bottom: 1px solid $color-input-border-grey;
  background: sassvg("chevron_down") no-repeat center right 10px;
}

// Extend arrow on hover every anchor tag who have arrow as child element
a:hover {
  .link-with-arrow {
    width: 25px;
  }

  .link-with-arrow-extended {
    width: 50px;
  }

  .back-link-with-arrow {
    width: 30px;
  }
}

.tab-products--splide-arrow:hover {
  .link-with-arrow {
    width: 25px !important;
  }
}

.link-with-arrow-rte {
  display: inline-block;
  background: sassvg("arrow-right", $color-text-accent) no-repeat right center;
  color: $color-text-accent;
  padding-right: $container-margin * 2;
  text-decoration: none;
}

/***** CUSTOM ******/

/* Custom checkbox */
.checkbox {

  /* The container */
  &-container {
    display: inline-block;
    position: relative;
    min-height: 16px;
    padding-left: 25px;
    margin-bottom: 12px;
    line-height: 16px;
    cursor: pointer;
    user-select: none;

    &.checkbox-right {
      padding-left: 0;
      padding-right: 25px;

      .checkbox-checkmark {
        left: unset;
        right: 0;
      }
    }

    /* On mouse-over, add a grey background color */
    //&:hover .checkbox-input ~ .checkbox-checkmark {
    //  background-color: #ccc;
    //}
  }

  /* Hide the browser's default checkbox */
  &-input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkbox-checkmark {
      background-color: $color-background-accent;
      border: none;
    }

    /* Show the checkmark when checked */
    &:checked ~ .checkbox-checkmark:after {
      display: block;
    }
  }

  /* Create a custom checkbox */
  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $color-white;
    border-radius: 2px;
    border: 1px solid $color-border-nav-grey;

    /* Style the checkmark/indicator */
    &:after {
      display: none;
      content: "";
      position: absolute;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

}

/* Custom checkbox color filter */
.color-filter {
  display: block;
  position: relative;
  width: $filter-color-size;
  height: $filter-color-size;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .color-filter-checkmark {
      height: $filter-color-size;
      width: $filter-color-size;
    }

    &:checked ~ .color-filter-checkmark:after {
      display: block;
      background: sassvg("check", $color-background-grey-light) no-repeat center;
      background-position: 7px 9px;
      background-size: 50%;
    }

    // Filter colors uid
    $filter-dark-colors: 2, 3, 14, 15, 21, 24, 27, 34, 78, 103, 147, 189, 262, 380, 419, 582, 733, 749, 1091, 1146, 1174, 1185, 1238, 1553, 1740, 1552, 1676, 1556, 1537, 1736, 1097, 1554, 1680;
    // Adds brighter checkmark on darker colors
    @each $uid in $filter-dark-colors {
      &:checked ~ .color-filter-checkmark.filter-color-#{$uid}:after {
        background: sassvg("check", $color-background-grey-light-accent) no-repeat center;
        background-position: 7px 9px;
        background-size: 50%;
      }
    }
  }

  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $filter-color-size;
    width: $filter-color-size;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
  }

  &-checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: $filter-color-size;
    height: $filter-color-size;
    border-radius: 50%;
    //border: 3px solid $color-border-nav-grey;
  }

  //&:hover input ~ .color-filter-checkmark {
  //  background-color: #ccc;
  //}
}

/* Range slider override */
.noUi {
  &-base {
    top: -1px;
    height: 3px;
  }

  &-target {
    background: $color-border-filter-range;
    border-radius: unset;
    box-shadow: none;
    border: none;
  }

  &-connect {
    background: $color-background-accent;
  }

  &-horizontal {
    height: 1px;
  }

  &-horizontal .noUi-handle {
    top: -11px;
    @include from($desktop-large) {
      top: -8px;
    }
  }

  &-handle {
    height: 23px !important;
    width: 23px !important;
    border: 1px solid $color-background-accent;
    border-radius: 12px;
    background-color: $color-white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.23);
    cursor: pointer;

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }

    @include from($desktop-large) {
      height: 18px !important;
      width: 18px !important;
    }

  }
}

// Autocomplete dropdown styles
.location-markers-autocomplete {
  // Container must be positioned relative because of autocomplete-items ( positioned absolute ):
  position: relative;
  display: inline-block;
  width: 100%;
}


// **** Generic Js classes ****
.autocomplete-items {
  position: absolute;
  border: 1px solid $color-input-border-grey;
  border-top: none;
  z-index: 2;
  // Position the autocomplete items to be the same width as the container:
  top: 100%;
  left: 0;
  right: 0;
}


.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: $color-white;

  strong {
    color: $color-black;
    font-family: $font-copy-semi-bold;
  }
}

.autocomplete-items div:hover {
  // When hovering an item:
  background-color: $color-input-background-grey;
}

.autocomplete-active {
  // When navigating through the items using the arrow keys:
  background-color: $color-background-autocomplete-item-active !important;
  color: $color-white;
}


/****** Popup Style ******/
/* Override default */
// Max popup width
.gm-style-iw-d,
.gm-style-iw,
.gm-style-iw-c {
  max-width: 225px !important;
  max-height: 180px !important;
  border-radius: 3px !important;
}

.gm-style-iw-c {
  position: relative;
  padding: 25px !important;
  box-shadow: unset !important;
  background-color: $color-background-accent !important;

  p {
    margin-bottom: 0;
  }

  button {
    top: -6px !important;
    right: -3px !important;

    img {
      width: 16px !important;
      height: 16px !important;
      filter: invert(100%) sepia(0) saturate(1426%) hue-rotate(157deg) brightness(100%) contrast(101%);
    }
  }

  .gm-ui-hover-effect {
    opacity: 1 !important;
  }

  a {
    text-decoration: none;
  }

  p:first-child {
    margin-top: 0;
    border-left: 1px solid $color-white;
    padding-left: 15px;
  }

  p {
    font-size: $font-size-medium;
    line-height: $line-height;
    margin-bottom: 10px;
    color: $color-white;
  }

  a > p:last-child {
    font-family: $font-copy-bold;
    font-size: $font-size-lead;

    &:after {
      display: inline-block;
      content: '';
      background: sassvg('arrow-right', $color-white) no-repeat center;
      background-size: cover;
      width: 17px;
      height: 13px;
      margin-left: 15px;
    }
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-ui-hover-effect {
    color: $color-white !important;
  }
}

.gm-style .gm-style-iw-t:after {
  // Color white x sign
  background: linear-gradient(45deg, $color-text-accent, $color-text-accent) !important;
  z-index: -1;
}

// Class for floating left opening times in location, class is added from RTE
.opening-times {
  float: right;
}

.pristine-error {
  //position: absolute;
  bottom: -16px;
  left: 0;
  font-size: $font-size-small;
  color: $color-input-text-error;
}

// Class for icon cart, class is added from RTE
.icon-cart {
  display: inline-block;
  margin: 0 3px;
  background: sassvg("cart-icon", $color-background-accent) no-repeat center;
  background-size: cover;
  height: 20px;
  width: 20px;
}

.has-danger textarea,
.has-danger select,
.has-danger input {
  border: 1px solid $color-input-text-error;
}

.powermail_fieldwrap,
.form-group {
  position: relative;
}

.hide-element {
  display: none !important;
}

.hide--from-desktop {
  @include from($desktop) {
    display: none !important;
  }
}

.hide--until-desktop {
  @include until($desktop) {
    display: none !important;
  }
}

// Makes video responsive | div.video-responsive-holder > iframe.video-responsive-player
.video-responsive-holder {
  position: relative;
  margin: auto;
  padding-bottom: 181px;
  height: 0;

  @include from($xsmobile) {
    padding-bottom: 56.25%;
  }
}

.video-responsive-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 181px;
  object-fit: cover;
  cursor: pointer;
}

.sticky-side-nav {
  position: sticky;
  top: 50px;
}

.back-to-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  transition: all 500ms;
  background: sassvg("btt-arrow", $color-white) no-repeat center;
  background-color: $color-background-accent;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);

  @include from($desktop) {
    height: 50px;
    width: 50px;
    bottom: 50px;
    right: 13px;
  }
}

.back-to-top-inverted {
  background: sassvg("btt-arrow", $color-background-accent) no-repeat center !important;
  background-color: $color-white !important;
}

.viber-and-newsletter.hidden {
  bottom: -50px;
}

.viber-and-newsletter {
  //[data-page-uid='53'] & {
  //  display: none !important;
  //}

  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  background: $color-background-accent;
  border-bottom: 2px solid $color-white;

  @include from($desktop-large) {
    display: none;
  }

  &--viber {
    padding: 16px 15px 16px 15px;
    font-size: $font-size-x-small; // reduced font-size for viewport under 375px
    font-weight: 700;
    text-decoration: none;
    color: $color-white;
    background: sassvg("viber", $color-white) no-repeat right 15px center;

    @include from($tablet) {
      background: sassvg("viber", $color-white) no-repeat right 75px center;
    }

    @include from($desktop) {
      background: sassvg("viber", $color-white) no-repeat right 125px center;
    }

    @include from($xsmobile) {
      font-size: $font-size-description;
      padding: 16px 40px 16px 15px;
    }

    @include from($xmobile) {
      text-align: center;
      font-size: $font-size-copy;
    }

  }

  &--newsletter {
    border-left: 2px solid $color-white;
    padding: 16px 15px 16px 15px;
    background: sassvg("newsletter", $color-white) no-repeat right 15px center;
    font-size: $font-size-x-small; // reduced font-size for viewport under 375px
    font-weight: 700;
    text-decoration: none;
    color: $color-white;

    @include from($tablet) {
      background: sassvg("newsletter", $color-white) no-repeat right 75px center;
    }

    @include from($desktop) {
      background: sassvg("newsletter", $color-white) no-repeat right 125px center;
    }

    @include from($xsmobile) {
      font-size: $font-size-description;
      padding: 16px 40px 16px 15px;
    }

    @include from($xmobile) {
      text-align: center;
      font-size: $font-size-copy;
    }
  }
}