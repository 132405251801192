@import "../constants/constants";
@import "../base/mixins";


/************* GRID ***************/
.product-homepage--container {
  @extend .ce-full-width-no-margin;
  margin-bottom: 30px;
  @include until($desktop) {
    background: $color-background-checkout-light-grey;
  }
  @include from($desktop) {
    margin-bottom: 100px;
  }
}

.product-homepage--container-inner {
  @extend .ce-container-inner-width;
  //padding-top: $container-margin;
  @include from($desktop) {
    padding-top: 30px;
  }
}

.product-homepage--container-inner-tabs {
  @extend .ce-full-width-no-margin;
  background-color: $color-background-grey-light-accent;
}

.product-homepage--tabs {
  padding-top: $container-margin/2;
  display: flex;
  text-align: center;
  line-height: 1.2;
  @include from($desktop) {
    display: block;
  }
}

/************** STYLE *************/
.product-homepage--container {
  //***** Overrides hover from product list *****//
  // check also:
  // _p_ambient_detail.scss
  // _p_collection-detail.scss
  .product-list--item,
  .product-list--item-content {
    margin-bottom: 0 !important;
  }

  @include until($desktop) {
    margin-bottom: 20px;
    padding-bottom: 40px;
    .splide__arrows {
      display: none;
    }
    .splide__list__single {
      // Style mobile teaser slider
      background: #fff;
      padding: 5px;
      border-radius: $image-border-radius;
      margin: 0 5px 5px 0;

      .product--sticker {
        width: 22px;
        height: 26px;
        margin-bottom: 2px;

        &.sticker-lowestPriceEverAction {
          width: 38px;
          height: 44px;
        }

        &.sticker-freeShipping {
          width: 112px;
          height: 13px;
        }

        &-grid {
          grid-template-columns: 6px 6px;
          margin-top: 10px;
        }

        &-number {
          font-size: 12px;
        }

        &-sign {
          font-size: 6px;
        }

        &.sticker-discount {
          background-size: 100%;
        }

        &.sticker-new {
          background-size: 98%;
        }

        &.sticker-limit {
          background-size: 98%;
        }

        &.sticker-outlet {
          width: 30px;
          background-size: 98%;
        }
      }

      .product-list--item {
        border-bottom: none;

        &-image-wrap {
          // padding-right: 30px; // Due to smaller icons on mobile
        }

        &-content {
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
        }

        &-data {
          //height: 50px;
          line-height: 120%;
        }

        &-title-link {
          display: inline-block;
          line-height: 120%;
          height: 54px;
        }

        &-title {
          font-size: 14px;
          margin-top: 5px;
          margin-bottom: 0;
          max-height: 30px;
          overflow: hidden;
        }

        &-label {
          font-size: 10px;
        }

        &-price-old {
          font-size: 10px;
          min-height: 8px;
          color: $color-text-cart-description;
        }

        &-price-new {
          font-size: 14px;
        }

        &-dimensions {
          font-size: 7px;
        }

        &-add {
          //padding: 6px 6px 6px 30px;
          //background-size: 16px;
          //font-size: 12px;

          &-container {
            height: 40px;
          }
        }

        &-info {
          margin-top: 10px;

          @include until($tablet) {
            margin-top: 0;
            padding-bottom: 5px;
          }

          &--discount,
          &--deliverytime {
            display: inline-block;
            font-size: 8px;
            line-height: 120%;
          }
        }

      }
    }
  }
}

// Text part which is hidden on smaller resolutions

//.product-list--item--text {
//  .product-homepage--container & {
//    @include until(410px) {
//      display: none;
//    }
//  }
//
//  @include until(390px) {
//    display: none;
//  }
//}

.product-homepage--container {
  .splide__list__single {
    @include until($tablet) {
      padding: 0;
    }

    .product-list--item-add-container {
      gap: 5px;

      @include from(434px) {
        justify-content: unset;
        gap: 20px;
      }

      @include from($desktop) {
        padding-right: $container-margin;
        gap: 5px !important;
      }

      @include from($desktop-large) {
        gap: 30px !important;
      }
    }

    .product-list--item-add {
      font-size: 5px;

      @include from(375px) {
        font-size: 7px;
      }

      @include from(420px) {
        font-size: 9px;
      }

      @include from($xmobile) {
        font-size: 11px;
      }

      @include from(580px) {
        font-size: 12px;
        background-size: 24px;
        padding: 10px 10px 10px 40px;
      }

      @include between($desktop, $wide-screen) {
        font-size: 10px;
        background-size: 20px;
        padding: 10px 10px 10px 35px;
      }
    }

    .product-list--item-delivery {
      font-size: 5px;
      background-size: 16px;
      padding-left: 20px;

      @include from(375px) {
        font-size: 7px;
      }

      @include from(420px) {
        font-size: 9px;
      }

      @include from($xmobile) {
        font-size: 11px;
      }

      @include from(580px) {
        font-size: 12px;
        background-size: 28px;
        padding-left: 32px;
      }

      @include from($tablet) {
        font-size: 12px;
      }

      @include between($desktop, $wide-screen) {
        font-size: 10px;
        background-size: 28px;
      }
    }

    .product-list--item-image-wrap {
      @include until($tablet) {
        margin: -5px -5px 0;
      }
    }
  }
}

.product-homepage--tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 62px;
  margin-right: 5px;
  padding: 0 10px;
  background-color: $color-background-white-transparent;
  border-radius: 2px 2px 0 0;
  color: $color-text-grey-light;
  font-size: $font-size-copy;
  font-family: $font-head-bold;
  cursor: pointer;

  @include from(640px) {
    padding: 0 20px;
    font-size: $font-size-header-nav-mobile;
  }

  @include from($desktop) {
    min-height: 75px;
    padding: 0 40px;
    margin-top: 30px;
    font-size: $font-size-h3;
  }

  &.active-tab {
    background-color: $color-white;
  }
}

.product-homepage--tab-webdiscount {
  &.active-tab {
    color: $color-text-red;
  }
}

.product-homepage--tab-discount {
  &.active-tab {
    color: $color-text-red;
  }
}

.product-homepage--tab-new {
  &.active-tab {
    color: $color-text-green;
  }
}

.product-homepage--tab-outlet {
  &.active-tab {
    color: $color-text-red;
  }
}

//.product-homepage--tab-last-minute {
//  &.active-tab {
//    color: $color-text-red;
//  }
//}

.product-homepage--link-cta {
  @include buttonBlue();
  background-color: $color-background-red;
  font-size: $font-size-medium;
  @include from($desktop) {
    font-size: $font-size-copy;
  }
}

.product-homepage--link-cta-new {
  background-color: $color-background-green;
}

.product-homepage--new-cta-wrap {
  padding-top: 30px;
  @include from($desktop) {
    text-align: center;
  }
}

.product-homepage--last-minute-cta-wrap {
  padding-top: 30px;
  @include from($desktop) {
    text-align: center;
  }
}

.product-homepage--last-minute-expired-offer {
  padding-top: 35px;
  @include from($desktop) {
    text-align: center;
  }
  margin: 0 auto;

  &-headline {
    margin-top: 0;
  }

  &-subheadline {
    margin-top: 25px;
    color: $color-text-lead;
  }

  &-text {
    color: $color-text-grey-light;
    font-family: $font-copy-bold;
  }
}

.product-homepage--last-minute.active-tab {
  .product-homepage--last-minute-expired-offer-wrap {
    display: grid !important;
    justify-content: center;
    align-content: center;
  }
}

.product-homepage--tab-content {
  // Show below desktop
  @include from($desktop) {
    display: none;
  }

  &.active-tab {
    display: block;

  }

  .splide__arrow--prev,
  .splide__arrow--next {
    width: 36px;
    height: 24px;
    opacity: 1;
  }

  .splide__arrow[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .splide__arrow--prev {
    left: -2px;
    background: sassvg("ic-arrow-prev", $color-background-accent) no-repeat center;
    background-size: contain;
  }

  .splide__arrow--next {
    right: 2px;
    background: sassvg("ic-arrow-next", $color-background-accent) no-repeat center;
    background-size: contain;
  }

  //.product-list--item-add {
  //  span {
  //    @include between($desktop, $wide-screen) {
  //      font-size: $font-size-medium;
  //    }
  //  }
  //}

  // Holds same item height despite length oh product headline (1,2 or 3 rows)
  .product-list--item {
    height: 100%;

    &-dimensions {
      font-size: 10px;
      color: $color-text-cart-description;
    }
  }

  .splide__list__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include from($desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

}


// Style mobile teaser slider
.splide-homepage {
  @include until($desktop) {
    margin-left: -$container-margin;
    margin-right: -$container-margin;
  }
}

.product-homepage--headline {
  padding-top: $container-margin;

  @include until($desktop) {
    &--webaction,
    &--outlet,
    &--discount {
      color: $color-text-red;
    }
    &--new {
      color: $color-text-green;
    }
  }
}

.product-homepage--container {
  @include from($desktop) {
    .product-list--item-link img {
      padding-top: 20px;
      padding-right: 20px;
    }

    .product-list--stickers {
      top: 25px;

      @include until($desktop) {
        top: 5px;
        right: 5px;
      }
    }
  }
}