@import "../constants/constants";
@import "../base/mixins";

$felogin-padding-horizontal: 50px;
$footer-height-desktop-large: 335px;


///************* GRID ***************/
// Because of jumping content grid is in critical
.felogin--right-login-proceed {
  @include from($tablet) {
    padding-left: $felogin-padding-horizontal;
  }
}

.felogin {
  .breadcrumb--container {
    display: none;

    @include from($desktop-large) {
      display: inline-block;
    }
  }
}

/******** STYLE *********/
.felogin--link-continue {
  @include buttonBlue;
  background-color: $color-background-accent-secondary;
  padding: 15px 70px 15px 40px;

  .link-with-arrow {
    transform: translateY(-50%);
  }
}

.felogin--submit {
  @extend .default-submit;

  &-logout {
    margin-bottom: 30px;
  }
}

.felogin--username {
  color: $color-text-accent;
}

.felogin--success {
  margin-bottom: 30px;
}

.felogin-label-wrap {
  @extend .default-fieldwrap;
}

.felogin--subheadline {
  color: $color-text-label;
  margin-top: 0;
}


.felogin--left-breadcrumb {
  position: relative;
}

.felogin--back {
  display: inline-block;
  margin: 15px 0;
  padding-left: 25px;
  background: sassvg("arrow-back", $color-background-accent) no-repeat center left;
  color: $color-text-accent;
  text-decoration: none;

  @include from($desktop-large) {
    margin: 0;
    background: sassvg("arrow-back") no-repeat center left;
  }

  &.desktop {
    display: none;
    @extend .two-columns-arrow-position;
  }

  span {
    @include from($desktop-large) {
      display: none;
    }
  }
}

.two-columns-arrow-position {
  @include from($desktop-large) {
    display: inline-block;
    position: absolute;
    left: -30px;
    top: 0;
    width: 20px;
    height: 25px;
    padding-left: 0;
  }
}

.felogin-label {
  @extend .default-label;
}

.felogin--session-timed-out {
  display: block !important;

  .felogin--container-inner {
    @extend .ce-container-inner-width;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include from($desktop) {
      margin-top: 30px !important;
    }
  }
}

.felogin--submit-logout-wrap {
  margin-bottom: 30px;
}

.felogin-error {
  color: $color-text-red;
}

.felogin-welcome {
  color: $color-text-accent;
}

.header--side-login-popup-bottom {
  .felogin-left-flexibile,
  .felogin-right-flexibile,
  .felogin--right-login-proceed,
  .felogin--left {
    display: none;
  }

  .felogin--container-inner {
    width: 100%;
  }

  .felogin--container-grid {
    display: block;
    min-height: unset;
  }

  .felogin-container-flexible {
    width: unset;
  }

  .felogin--right-login-info {
    border: unset;
  }

  .felogin--right {
    padding: 0 !important;
    display: block;
    background-color: unset;
  }

  .felogin--right-login-info {
    padding: 0 20px;
    @include from($desktop) {
      padding: 0;
    }
  }

  .felogin--subheadline {
    position: relative;
    padding-left: 20px;
    margin-left: -15px;
    font-size: $font-size-h4;
    font-family: $font-copy-bold;
    color: $color-text-accent;
    @include leftVerticalLine($color: $color-background-accent);

    &:before {
      top: 3px;
    }
  }
}