@import "../constants/constants";

// Media queries
@mixin until($width) {
  // until ($tablet), exception for mobile query
  @if ($width == 768) {
    @media (max-width: $width - 1) {
      @content;
    }
  }
  @if ($width != 768) {
    @media (max-width: $width) {
      @content;
    }
  }
}

@mixin from($width) {
  // from ($tablet), exception for tablet query
  @if ($width == 768) {
    @media (min-width: $width) {
      @content;
    }
  }
  @if ($width != 768) {
    @media (min-width: $width + 1) {
      @content;
    }
  }
}

@mixin between($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

// from 768px to 1024px
@mixin tablet() {
  @include between($tablet, $desktop) {
    @content;
  }
}

// from 1025px to 1200px
@mixin desktop() {
  @include between($desktop + 1, $desktop-large) {
    @content;
  }
}

// from 1201px to 1366px
@mixin desktopLarge() {
  @include between($desktop-large + 1, $wide-screen) {
    @content;
  }
}

// from 1367px
@mixin widescreen() {
  @include between($wide-screen + 1, $ultra-wide-screen) {
    @content;
  }
}

// from 1440px
@mixin ultraWidescreen() {
  @include from($ultra-wide-screen) {
    @content;
  }
}

// Add greyBackground class on div content-element--container
@mixin greyBackgroundOption($div-container-class:null, $div-inner-class:null) {

  @if $div-container-class and $div-inner-class {

    //.#{$div-container-class}.#{$grey-background-class} {
    .#{$div-container-class}.greyBackground {
      //.#{$div-container-class} {

      @extend .ce-full-width-no-margin;
      background: $color-background-grey-light-accent;

      .#{$div-inner-class} {
        @extend .ce-container-inner-width;
        padding: $ce-padding-vertical 0;
      }

    }

  } @else {
    @error "------------Missing parameter!----------------";
  }

}

// Center background image
@mixin centerBckg() {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin hamburger(
  $class: null,
  $left: null,
  $right: null,
  $el-to-show: null
) {


  // Hamburger
  .#{$class}-trigger {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    @include from($desktop-large) {
      display: none;
    }
  }

  // Format and add Animation for Hamburger (x instead of =)
  .#{$class}-trigger-label {
    position: absolute;
    width: 30px;
    height: 26px;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    @if ($left) {
      left: $left;
    }
    @if ($right) {
      right: $right;
    }

    @include from($tablet) {
      margin-top: 2px;
    }
    @include from($desktop-large) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 20px;
      border-radius: 1px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      background-color: $color-white;
    }

    span:nth-child(1) {
      left: 4px;
      top: 5px;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 11px;
      left: 4px;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 17px;
      left: 4px;
      transform-origin: left center;
    }

  }

  // Move item to the left
  .#{$class}-trigger:checked + .#{$class}-trigger-label {

    span:nth-child(1) {
      transform: rotate(45deg);
      top: 3px;
      left: 7px;
    }

    span:nth-child(2) {
      width: 0;
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 17px;
      left: 7px;
    }
  }

  @if ($el-to-show) {
    .#{$class}-trigger:checked ~ #{$el-to-show} {
      transition: all 0.1s linear;
      display: block;
      opacity: 1;
    }
  }

}

// $direction: up | down
@mixin triangle(
  $direction: 'up',
  $color: #fff,
  $width: 3,
  $height: 6,
  $breakpoint: $desktop
) {
  @if $direction == 'up' {
    &:after {
      display: block;
      position: absolute;
      right: -45px;
      top: 14px;
      content: '';
      width: 0;
      height: 0;
      border-left: #{$width}px solid transparent;
      border-right: #{$width}px solid transparent;
      border-bottom: #{$height}px solid $color;
      border-top: none;
    }
  }
  @if $direction == 'down' {
    &:after {
      display: block;
      position: absolute;
      right: -45px;
      top: 20px;
      content: '';
      width: 0;
      height: 0;
      border-left: #{$width}px solid transparent;
      border-right: #{$width}px solid transparent;
      border-top: #{$height}px solid $color;
      @include from($breakpoint) {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  @if $direction == 'right' {
    &:after {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
      content: '';
      width: 0;
      height: 0;
      border-top: #{$width}px solid transparent;
      border-bottom: #{$width}px solid transparent;
      border-left: #{$height}px solid $color;
      @include from($breakpoint) {
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}

@mixin listStyle(
  $spacingLeftMobile: 30px,
  $lineWidth: 20px
) {
  ul {
    list-style: none;
    margin-left: $spacingLeftMobile;
    @include from($desktop) {
      margin-left: 0;
    }

    li {
      position: relative;
      margin-bottom: 14px;

      &:before {
        display: inline-block;
        left: -$spacingLeftMobile; // Move bullets / lines to the left (outside the box)
        top: 13px;
        width: $lineWidth;
        height: 1px;

        content: "";
        position: absolute;
      }
    }
  }


}

@mixin leadItalicText() {
  margin-bottom: 20px;
  font-style: italic;
}

@mixin unorderedList() {
  ul {
    margin: 15px 0;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        height: 8px;
        width: 8px;
        content: '';
        border-radius: 100%;
        background-color: $color-background-accent-secondary;
      }

      & > ul {
        margin-top: 10px;
        margin-bottom: 30px;

        li {
          padding-left: 20px;

          &:before {
            width: 8px;
            top: -4px;
            left: -13px;
            display: inline-block;
            position: relative;
            height: 1px;
            background-color: $color-text-base;
          }

          ul > li {
            padding-left: 20px;

            &:before {
              left: -12px;
              background-color: $color-text-grey-light;
            }
          }
        }
      }
    }
  }


}

@mixin orderedList() {
  ol {
    margin: 15px 0;
    counter-reset: item; // Reset number to 1 (start point)

    & > li {
      display: grid;
      grid-template-columns: 40px 1fr;
      margin-bottom: 5px;
      counter-increment: item; // CSS property for increment number
      list-style-type: none;

      &::before {
        display: inline-block;
        content: counter(item) '.'; // Formatting number in ordered list
        font-family: $font-copy;
        width: 40px;
      }
    }
  }
}

@mixin cross($element: null, $color: #000) {
  .#{$element} {
    width: 14px;
    height: 14px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: $color;
    }

    &:before {
      left: 50%;
      transform: translateX(-50%) scaleY(1);
      width: 2px;
      height: 100%;
      transition: all 0.1s linear;
      transform-origin: center;
    }

    &:after {
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 100%;
    }
  }

}

@mixin externalLink($position: "left", $color:#000) {

  @if $position == "left" {
    padding-left: 25px;
  }

  @if $position == "right" {
    padding-right: 25px;
  }

  background: sassvg("ic-external-link", $color) no-repeat center #{$position};
}

@mixin selectedProductNav(
  $padding-left: 15px,
  $arrow-color: $color-white,
  $top: 5px
) {
  padding-left: $padding-left;
  color: $color-white;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: -#{$padding-left};
    top: $top;
    width: 16px;
    height: 13px;
    background: sassvg("arrow-right", $arrow-color) no-repeat center;
    transition: left 200ms;
  }
}

@mixin leftVerticalLine(
  $color: $color-background-accent,
  $paddingLeft: $text-decor-vertical-line-indent
) {
  position: relative;
  padding-left: $paddingLeft;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 5px;
    height: 14px;
    background-color: $color;

    @include from($desktop-large) {
      left: -4px;
    }
  }
}

@mixin linkWithArrowExtend(
  $arrowColor: $color-background-accent,
  $fontColor: $color-text-accent,
  $top: 9px,
  $left: 100%
) {

  display: inline-block;
  position: relative;
  //padding-right: 40px;
  color: $fontColor;
  font-family: $font-head-bold;
  text-decoration: none;

  .link-with-arrow {
    display: inline-block;
    position: absolute;
    //left: calc(#{$left} - 40px);
    left: $left;
    top: $top;
    vertical-align: middle;
    width: 20px;
    height: 1px;
    margin-left: 10px;
    transition: all 0.2s;
    background-color: $arrowColor;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 1px;
      right: -1px;
      top: -3px;
      background-color: $arrowColor;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      right: -1px;
      width: 8px;
      height: 1px;
      top: 3px;
      background-color: $arrowColor;
      transform: rotate(-45deg);
    }

  }
}

@mixin linkWithArrowReverseExtend(
  $arrowColor: $color-background-accent,
  $fontColor: $color-text-accent,
  $top: 9px,
  $left: 100%
) {

  position: relative;
  //padding-right: 40px;
  color: $fontColor;
  font-family: $font-head-bold;
  text-decoration: none;

  .link-with-arrow {
    display: inline-block;
    position: absolute;
    //right: calc(#{$left} - 40px);
    right: $left;
    top: $top;
    vertical-align: middle;
    width: 20px;
    height: 1px;
    margin-right: 10px;
    transition: all 0.2s;
    background-color: $arrowColor;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 1px;
      left: -1px;
      top: -3px;
      background-color: $arrowColor;
      transform: rotate(-45deg);
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -1px;
      width: 8px;
      height: 1px;
      top: 3px;
      background-color: $arrowColor;
      transform: rotate(45deg);
    }

  }
}

@mixin linkWithArrowExtendLong(
  $arrowColor: $color-white,
  $fontColor: $color-white,
  $top: 9px,
  $left: 100%
) {

  position: relative;
  color: $fontColor;
  font-family: $font-head-bold;
  text-decoration: none;

  .link-with-arrow-extended {
    display: inline-block;
    position: absolute;
    left: $left;
    top: $top;
    vertical-align: middle;
    width: 45px;
    height: 1px;
    transition: all 0.2s;
    background-color: $arrowColor;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 1px;
      right: -1px;
      top: -3px;
      background-color: $arrowColor;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      right: -1px;
      width: 8px;
      height: 1px;
      top: 3px;
      background-color: $arrowColor;
      transform: rotate(-45deg);
    }

  }

}

@mixin verticalTextLine(
  $colorLine,
  $colorText
) {

  width: 4px;
  height: 80px;
  background-color: $colorLine;

  & + div {
    margin-left: -6px;
    margin-top: 20px;
    text-transform: uppercase;
    // Rotate text upwards
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    color: $colorText;
    font-size: $font-size-small-uppercase;
    font-family: $font-copy-bold;
  }
}

@mixin headlineArticleList(
  $color
) {
  color: $color;
  font-size: $font-size-h3;
  // Extended line after headline in article list discount and new
  & + div {
    display: none;
    @include from($desktop) {
      display: block;
      height: 1px;
      background-color: $color;
    }
  }
  @include from($desktop) {
    font-size: $font-size-h2;
  }
}

@mixin buttonBlue() {
  position: relative;
  display: inline-block;
  background-color: $color-background-accent;
  border-radius: $btn-border-radius;
  text-decoration: none;
  padding: 15px 80px 15px 40px;
  color: $color-white;

  @include linkWithArrowExtend(
          $arrowColor: $color-white,
          $fontColor: $color-white,
          $left: calc(100% - 70px),
          $top: 50%
  );
}

@mixin productsPopupLinkStyle() {
  padding: 2px 0;
  color: $color-text-header-products-nav;
  text-decoration: none;
  transition: all 200ms;

  @include from($desktop-large) {
    &:hover {
      transition: all 200ms;
      color: $color-white;
      text-decoration: underline;
    }
  }
}



@mixin resetUl() {
  margin: 0;

  li {
    &:before {
      display: none;
    }
  }
}

@mixin horizontal-scroll(
  $border-radius: 50px,
  $size-mobile: 8px,
  $size-desktop: 12px
) {
  overflow-x: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: $size-mobile;

    @include from($desktop) {
      height: $size-desktop;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($color-scrollbar-background, 20);
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-scrollbar-background;
  }
}