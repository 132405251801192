@import "../constants/constants";
@import "../base/mixins";

.product-compare--container {
  @extend .ce-full-width-no-margin;
  //@extend .ce-container-inner-width;
}

.product-compare--container-inner {
  @extend .ce-container-inner-width;
  margin-bottom: 50px !important;
}

/******* style *******/

.product-compare--breadcrumb {
  margin: 30px 0;
}

.product-compare--products-container {
  overflow-x: auto;
  margin-left: -$container-margin;
  margin-right: -$container-margin;

  .product--sticker-row {
    //float: left;
    margin: 0;

    &:last-child {
      margin: 0;
    }
  }

  // Custom scroll
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;

    @include from($desktop) {
      height: 12px;
    }
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;

    @include from($desktop) {
      height: 12px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-scroll-handler;
    border-radius: 12px;
    border: 1px solid $color-scroll-rail;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-scroll-rail;
  }
}

.product-compare--image {
  max-width: 130px;
}

.product-compare--table {
  border-collapse: collapse;
  color: $color-text-base;

  tr {
    padding: 10px;
    border-bottom: 1px solid $color-border-nav-white;

    &:last-child {
      border-bottom: none;
    }

    @include from($tablet) {
      padding: 15px 30px;
    }
  }

  th {
    min-width: 90px;
    padding: 10px;
    color: $color-text-base;
    text-transform: capitalize;
    font-family: $font-copy-bold;
    font-size: $font-size-medium;

    @include from($tablet) {
      padding: 15px 30px;
    }
  }

  td {
    padding: 10px;
    border-left: 1px solid $color-border-nav-white;
    font-size: $font-size-copy;

    @include from($tablet) {
      padding: 15px 30px;
    }

    &.product-compare--column-name {
      a {
        font-family: $font-copy-bold;
        text-transform: uppercase;
        color: $color-text-base;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.product-compare--column-image {
      min-width: 180px;

      @include from($desktop-large) {
        min-width: 250px;
      }
    }
  }
}

.product-compare--buy {
  @include buttonBlue();
  padding: 15px 25px;
  font-family: $font-copy;
}

.product-compare--stickers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, auto));
  grid-gap: 7px;
  justify-content: flex-start;
}

.product-compare--price-old {
  font-size: $font-size-medium;
  text-decoration: line-through;
  color: $color-text-lead;
}

.product-compare--price-new {
  color: $color-text-product-price;
  font-family: $font-copy-bold;
  font-size: $font-size-h4;
}

.product-compare--remove {
  padding-top: 15px;

  &-link {
    display: inline-block;
    height: 25px;
    padding-left: 16px;
    background: sassvg('trash', $color-input-text-error) no-repeat center left;
    font-size: $font-size-medium;
    color: $color-text-red;
    line-height: 25px; // height of icon
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}