@import "../constants/constants";
@import "../base/mixins";

@import "../constants/constants";
@import "../base/mixins";

$blog-teaser-item-width: 1000px; // Slide (image) width


/************* GRID ***************/

// CE grid with grey background
@include greyBackgroundOption(
        $div-container-class: "video-teaser-irre--container",
        $div-inner-class: "video-teaser-irre--container-inner"
);

.video-teaser-irre--item-wrap {
  @include from($desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}


/************** STYLE *************/
.video-teaser-irre--headline {
  margin: 10px 0 30px 0;
}

.video-teaser-irre--subheadline {
  font-family: $font-head-bold;
  font-size: $font-size-h4;
  color: $color-text-accent;
}

.video-teaser-irre--item {
  position: relative;
  display: block;
  padding-bottom: 50px;
  background-color: $color-white;
  text-decoration: none;
  color: unset;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @include from($desktop) {
    margin-bottom: 0 !important;
  }
}

.video-teaser-irre--single {
  position: relative;
  &:hover {
    transition: opacity 200ms;

    .video-teaser-irre--item-overlay {
      opacity: 1;
    }
  }
  &--link {
    display: block;
  }
}

.video-teaser-irre--item-title-wrap {
  position: relative;
}
.video-teaser-irre--item-title {
  display: block;
  $video-teaser-title-padding: 70px;
  $video-teaser-title-left-arrow: $video-teaser-title-padding - 10;
  @include linkWithArrowExtendLong(
          $left: calc(100% - #{$video-teaser-title-left-arrow}),
          $top: 15px
  );

  .link-with-arrow-extended {
    top: unset;
    bottom: 13px;
  }

  padding-right: $video-teaser-title-padding;
  z-index: 1;
}

.video-teaser-irre--item-title--container {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 45px;
  margin-top: 0;
  margin-bottom: 0;
}

.video-teaser-irre--item-link {
  @include linkWithArrowExtend(
          $arrowColor: $color-text-accent,
          $top: 11px
  );
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: block;
  color: $color-white;
  line-height: 0;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  @include from($tablet) {
    margin-bottom: 0;
  }
}

.video-teaser-irre--item-image {
  border-radius: 4px;
}

.video-teaser-irre--item-content {
  padding: 20px;
  background-color: $color-white;
}

.video-teaser-irre--item-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  margin-bottom: 15px;
  justify-content: left;
  color: $color-text-cart-description;
  font-size: $font-size-small;
}

.video-teaser-irre--item-tag {
  position: relative;
  padding-left: 20px;

  &:before {
    position: absolute;
    height: 11px;
    width: 11px;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.tag-yellow {
    &:before {
      background-color: $color-background-yellow;
    }
  }

  &.tag-red {
    &:before {
      background-color: $color-background-red;
    }
  }

  &.tag-green {
    &:before {
      background-color: $color-background-green;
    }
  }
}

/*
 * Must be same as padding in splider.js ( let imageTeaserTop )
 * space between slide and edge is min 320px
 */
$blog-teaser-slider-padding: 320px;

/*
 * Horizontal margin of bottom slider
 * for centering with top slider
 */
$blog-teaser-slider-bottom-margin: $blog-teaser-slider-padding * 2;

$blog-teaser-item-breakpoint: 1655px; // 1655px same as breakpoint in splider.js ( let imageTeaserTop )

/******** grid ********/
.video-teaser-irre--container {
  @extend .ce-full-width;
  padding: 0 !important;
}

.video-teaser-irre--slider-top--desktop {
  @include from($desktop) {

    // Added because of arrow positioning
    padding-bottom: 55px;
    margin-bottom: -55px;
  }
}

.video-teaser-irre--header,
.video-teaser-irre--slider-bottom--desktop,
.video-teaser-irre--slider-bottom--mobile {
  position: relative;
  max-width: $blog-teaser-item-width;
  padding: 0 20px;
  margin: auto;
  pointer-events: none;

  @include from($desktop) {
    padding: 0;
  }

  @include between($desktop, $blog-teaser-item-breakpoint) {
    margin: 0 $blog-teaser-slider-padding;
  }

  .splide__list {
    margin: auto !important;
    width: 100%;
    max-width: $blog-teaser-item-width;
  }

}

.video-teaser-irre--header {
  margin-bottom: 30px;

  @include between($desktop, $blog-teaser-item-breakpoint) {
    margin-bottom: 30px;
  }
}

/****** style ********/
.video-teaser-irre--subheadline {
  font-family: $font-copy-bold;
  font-size: $font-size-h4;
  color: $color-text-accent;
}

.video-teaser-irre--headline {
  margin: 10px 0 0 0;
  font-size: $font-size-h4;
}

.video-teaser-irre--slide-arrow--desktop,
.video-teaser-irre--slide-arrow--mobile {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: $color-background-accent;
  transition: background 200ms;
  z-index: 1;

  @include from($desktop) {
    position: unset;
    top: unset;
    transform: unset;
  }

  &-wrap {
    @include from($desktop) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1680px;
      padding-right: 0;
      text-align: right;
    }

    @include between($desktop, $blog-teaser-item-breakpoint) {
      width: calc(100% - #{$blog-teaser-slider-bottom-margin});
    }

    @include between($desktop, $desktop-large) {
      display: flex;
      justify-content: space-between;
      bottom: 50%;
      z-index: 1;
    }
  }

  &.splide__arrow--prev {
    background: $color-white sassvg("arrow-left", $color-text-accent) no-repeat center;

    @include from($desktop) {
      background: $color-background-slider-arrow-grey sassvg("arrow-left", $color-white) no-repeat center;
    }

    @include between($desktop, $desktop-large) {
      margin-left: -20px;
    }

  }

  &.splide__arrow--next {
    margin-left: 20px;
    background: $color-white sassvg("arrow-right", $color-text-accent) no-repeat center;

    @include from($desktop) {
      background: $color-background-slider-arrow-grey sassvg("arrow-right", $color-white) no-repeat center;
    }

    @include between($desktop, $desktop-large) {
      margin-right: -20px;
    }
  }

  &:hover {
    transition: background 200ms;
    background-color: $color-background-grey-light-accent;

    @include from($desktop) {
      background-color: $color-background-accent;
    }
  }
}


.video-teaser-irre--slider-top--desktop,
.video-teaser-irre--slider-top--mobile {

  @include from($desktop) {
    .splide__slide {
      opacity: 0.2;
      transition: opacity 500ms;
    }

    .splide__slide.is-active.is-visible {
      transition: opacity 500ms;
      opacity: 1;
    }

    // next slider (after active) opacity
    .splide__slide.is-active.is-visible + li.splide__slide {
      transition: opacity 500ms;
      opacity: 0.2;
    }

    //.splide__slide.is-visible {
    //  border: 3px solid green;
    //
    //  &+.is-active {
    //    border: 3px solid red;
    //  }
    //}
    //.splide__slide.is-active {
    //  &+.is-visible {
    //    border: 3px solid blue;
    //  }
    //}
  }

  &-group {
    width: 1670px;
    margin-left: 5px;
    margin-right: 5px;
    display: grid;
    @include from($desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    grid-column-gap: 10px;
  }


  // on mobile resolutions
  // there is blink during slide sweep
  // Changing opacity to 0 fixes that bug
  //
  // important!
  // Turning off pagination during slider init
  // won't fix that bug
  .splide__pagination {
    opacity: 0;
    visibility: hidden;
  }
}

.video-teaser-irre--slide-content {
  padding-top: 20px;

  @include from($desktop) {
    padding-right: 180px;
  }

  @include between($desktop, $desktop-large) {
    padding-right: 0;
  }
}

.video-teaser-irre--slide-headline {
  margin-top: 0;
  color: $color-text-headline;
  pointer-events: all;

  &-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $color-text-headline;
    }
  }
}

.video-teaser-irre--slide-link {

  pointer-events: all;
  @include linkWithArrowExtend();

  &-wrap {
    margin-top: 20px;
  }

}


.video-teaser-irre--item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 200ms;
  opacity: 0.2;
  pointer-events: none;
  background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.75) 100%
  );
}

